<div class="container-fluid wrapper">
  <div class="row title-row">
    <div class="pageTitle col-sm-12">
      <span class="pageSubTitle">Parent Company </span>
    </div>
  </div>
  <form [formGroup]="parentCompanyEditForm" (ngSubmit)="updateParentCompany()">
    <div class="row">
      <div class="col-sm-9 col-md-9 offset-3">
        <div class="form-group row">
          <label
            for="parentCompanyCode"
            class="col-sm-3 col-form-label col-form-label-sm"
            >Parent Company Code
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              formControlName="parentCompanyCode"
              id="parentCompanyCode"
              placeholder="Parent Company Code "
              appInfoPromptBox
              [page]="'parentCompany'"
              [component]="'edit'"
              [field]="'parentCompanyCode'"
            />
          </div>
        </div>

        <div class="form-group row">
          <label
            for="parentCompanyName"
            class="col-sm-3 col-form-label col-form-label-sm"
            >Parent Company Name
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              formControlName="parentCompanyName"
              id="parentCompanyName"
              placeholder="Parent Company Name"
              appInfoPromptBox
              [page]="'parentCompany'"
              [component]="'edit'"
              [field]="'parentCompanyName'"
            />
          </div>
        </div>

        <div class="form-group row">
          <label
            for="parentCompanyAddress"
            class="col-sm-3 col-form-label col-form-label-sm"
            >Parent Company Address
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              formControlName="parentCompanyAddress"
              id="parentCompanyAddress"
              placeholder="Parent Company Address"
              appInfoPromptBox
              [page]="'parentCompany'"
              [component]="'edit'"
              [field]="'parentCompanyAddress'"
            />
          </div>
        </div>

        <div class="form-group row">
          <label
            for="parentCompanyCity"
            class="col-sm-3 col-form-label col-form-label-sm"
            >Parent Company City
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              class="form-control"
              formControlName="parentCompanyCity"
              id="parentCompanyCity"
              placeholder="Parent Company City"
              appInfoPromptBox
              [page]="'parentCompany'"
              [component]="'edit'"
              [field]="'parentCompanyCity'"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div
        class="d-flex justify-content-center w-100 mt-3"
      >
        <!-- <div
          class="alert-warning no-changes-found-warning"
          *ngIf="initialValues == currentFormValues()"
        >
          <span>No changes found...</span>
        </div> -->
        <button
          appButtonLoader
          [isLoading]="isLoading"
          id="btnSubmitProject"
          type="submit"
          class="btn btn-primary col-sm-2 actionBtn"
          [disabled]="true"
        >
          Save
        </button>
        <button
          id="btnReset"
          type="button"
          routerLink="/company"
          class="btn btn-secondary col-sm-2 actionBtn"
        >
          Close
        </button>
      </div>
    </div>
  </form>
</div>
