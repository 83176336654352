import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstateService {

  constructor(private http: HttpClient) { }

    getAllEstates(companyCode,apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/estate/" +companyCode+"/"+ apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/estate/", data);
    }

    update(estateCode,data)
    {
        return this.http.put(environment.apiUrl + "/estate/" + estateCode, data);
    }

    delete(estateCode)
    {
        if (!estateCode) return;
        return this.http.delete(environment.apiUrl + "/estate/" + estateCode);
    }
    
    // getMatureTimberStockbyEstateReport(estateCode,divisionCode)
    // {
    //     return this.http.get(environment.apiUrl + "/generatePDF/downloadMTSReport/"+estateCode+"/"+divisionCode);
    // }

}