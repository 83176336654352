<div class="modal-header">
  <h5 class="modal-title pull-left">Add Field</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form class="p-3" [formGroup]="fieldAddForm" (ngSubmit)="createNewField()">
  <div class="modal-body-add-field">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['companyCode'].invalid &&
            (fieldAddForm.controls['companyCode'].dirty ||
              fieldAddForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['companyCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="estateCode"
          optionTextField="estateCode"
          [items]="estateCodeList"
          formControlName="estateCode"
          placeholder="Estate Code"
          (ngModelChange)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['estateCode'].invalid &&
            (fieldAddForm.controls['estateCode'].dirty ||
              fieldAddForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['estateCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="divisionCode"
          optionTextField="divisionCode"
          [items]="divisionCodeList"
          formControlName="divisionCode"
          placeholder="Division Code"
          (select)="divisionCodeChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'divisionCode'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['divisionCode'].invalid &&
            (fieldAddForm.controls['divisionCode'].dirty ||
              fieldAddForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['divisionCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="50"
          class="form-control"
          formControlName="fieldCode"
          placeholder="Field Code"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'fieldCode'"
        />
        <div
          *ngIf="
            fieldAddForm.controls['fieldCode'].invalid &&
            (fieldAddForm.controls['fieldCode'].dirty ||
              fieldAddForm.controls['fieldCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['fieldCode'].errors.required">
            This field is required.
          </div>
          <div *ngIf="fieldAddForm.controls['fieldCode'].errors.maxlength">
            Maximum length would be 200 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldExtent" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          appDecimal="2"
          class="form-control"
          formControlName="fieldExtent"
          placeholder="Field Extent"
          appInfoPromptBox
          [page]="'company'"
          [component]="'add'"
          [field]="'fieldExtent'"
        />
        <div
          *ngIf="
            fieldAddForm.controls['fieldExtent'].invalid &&
            (fieldAddForm.controls['fieldExtent'].dirty ||
              fieldAddForm.controls['fieldExtent'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['fieldExtent'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="maturityStatus"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Maturity Status
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="maturityStatusList"
          formControlName="maturityStatus"
          placeholder="Maturity Status"
          (select)="maturityStatusChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'maturityStatus'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['maturityStatus'].invalid &&
            (fieldAddForm.controls['maturityStatus'].dirty ||
              fieldAddForm.controls['maturityStatus'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['maturityStatus'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldType" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Type ( PC / IC )
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="fieldTypeList"
          formControlName="fieldType"
          placeholder="Field Type ( PC / IC )"
          (select)="fieldTypeChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'fieldType'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['fieldType'].invalid &&
            (fieldAddForm.controls['fieldType'].dirty ||
              fieldAddForm.controls['fieldType'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['fieldType'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="fieldMainCrop"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Field Main Crop
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="fieldMainCropList"
          formControlName="fieldMainCrop"
          placeholder="Field Main Crop"
          (select)="fieldMainCropChanged($event)"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'fieldMainCrop'"
        ></ngx-select>
        <div
          *ngIf="
            fieldAddForm.controls['fieldMainCrop'].invalid &&
            (fieldAddForm.controls['fieldMainCrop'].dirty ||
              fieldAddForm.controls['fieldMainCrop'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['fieldMainCrop'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="gpsCoordinates"
        class="col-sm-4 col-form-label col-form-label-sm"
        >GPS Coordinates</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="gpsCoordinates"
          placeholder="GPS Coordinate"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'gpsCoordinates'"
        />
        <div
          *ngIf="
            fieldAddForm.controls['gpsCoordinates'].invalid &&
            (fieldAddForm.controls['gpsCoordinates'].dirty ||
              fieldAddForm.controls['gpsCoordinates'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="fieldAddForm.controls['gpsCoordinates'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="gpsCoordinatesLong"
        class="col-sm-4 col-form-label col-form-label-sm"
        >GPS Coordinates Pin -Longitude</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="gpsCoordinatesLong"
          placeholder="GPS Coordinates Pin -Longitude"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'gpsCoordinatesLong'"
        />
        <div
          *ngIf="
            fieldAddForm.controls['gpsCoordinatesLong'].invalid &&
            (fieldAddForm.controls['gpsCoordinatesLong'].dirty ||
              fieldAddForm.controls['gpsCoordinatesLong'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="fieldAddForm.controls['gpsCoordinatesLong'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="gpsCoordinatesLati"
        class="col-sm-4 col-form-label col-form-label-sm"
        >GPS Coordinates Pin -Latitude</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="gpsCoordinatesLati"
          placeholder="GPS Coordinates Pin -Latitude"
          appInfoPromptBox
          [page]="'field'"
          [component]="'add'"
          [field]="'gpsCoordinatesLati'"
        />
        <div
          *ngIf="
            fieldAddForm.controls['gpsCoordinatesLati'].invalid &&
            (fieldAddForm.controls['gpsCoordinatesLati'].dirty ||
              fieldAddForm.controls['gpsCoordinatesLati'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="fieldAddForm.controls['gpsCoordinatesLati'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
