import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TreeDetailsService {

  constructor(private http: HttpClient) { }

    getAllTreeDetails(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/tree/" + apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/tree/", data);
    }

    update(treeID,data)
    {
        return this.http.put(environment.apiUrl + "/tree/" + treeID, data);
    }

    delete(treeID)
    {
        if (!treeID) return;
        return this.http.delete(environment.apiUrl + "/tree/" + treeID);
    }

}