import { formatDate } from '@angular/common';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ImmatureFieldsService } from 'src/app/services/immature-fields.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-immature-fields-edit',
  templateUrl: './immature-fields-edit.component.html',
  styleUrls: ['./immature-fields-edit.component.scss']
})
export class ImmatureFieldsEditComponent {

  onChange() {
    console.log("Changed Date")
  }
  
    roleData:any[] = [];
    refData : any;
    userGroupData:any[] = [];
    initialState:any;
    param:any;

    sqldate: string;
    sqldate2: string;
    sqldate3: string;
    sqldate4: string;
    sqldate5: string;
    
    dtNSurveyObj:Date;
    dtPlantingObj:Date;
    dt1stThiningOutObj:Date;
    dt2ndThiningOutObj:Date;
    dt3rdThiningOutObj:Date;
  
    event: EventEmitter<any> = new EventEmitter();
    ismatch:Boolean = true;
    loadingBtn : Boolean = false;
    disableButton:Boolean = false;
  
    immatureFieldsEditForm:any;
    companyCodeList: any[]=[];
    estateCodeList: any[]=[];
    divisionCodeList: any[]=[];
    fieldCodeList: any[]=[];
    specieCodeList: any[]=[];
    plantingDistanceList: any[]=[];
    slopeList: any[]=[];
    yearOfPlantingList: any[]=[];
    terrainList: any[]=[];
    soilTypeList: any[]=[];
    soilDepthList: any[]=[];
    confirmationOfCensesList: any[] =[
      {"id":1,"value":"Yes"},
      {"id":2,"value":"No"}
    ];
  
    constructor(
        public bsModalRef : BsModalRef,
        private notificationService : ToastrService,
        private immatureFieldsService: ImmatureFieldsService,
        private refDataService : RefdataService,
      private urlService: UrlService
    ) { 
      
  
    }
  
    ngOnInit(): void {
      this.initFormValues();

      this.generateYearOfPlantingList(1900, 2024);

      var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

      this.refDataService.getMatureFieldRefData().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.terrainList = this.refData.terrain;
      this.soilTypeList = this.refData.soiltypes;
      this.soilDepthList = this.refData.soildepth;
      this.slopeList = this.refData.slope;

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("RefData Completed");
      });

      this.refDataService.getSpecieCodeList().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);

        this.refData.forEach(item => {
          this.specieCodeList.push({
            id: item.speciesCode, 
            value: `${item.speciesCode} - ${item.description}`
          });
        });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Specie Codes Completed");
      });

      this.refDataService.getDistance().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);
  
        this.refData.forEach(item => {
          this.plantingDistanceList.push(item.distance);
        });
  
      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Planting Distane Completed");
      });

      this.estateCodeChanged(this.param.estateCode);
      this.divisionCodeChanged(this.param.divisionCode);

      let hostName = this.urlService.getHostName(window.location);
          // console.log(hostName);
        this.companyCodeList.push(hostName);
        this.immatureFieldsEditForm.controls["companyCode"].patchValue(hostName);
        this.immatureFieldsEditForm.controls["companyCode"].disable();

    }
  
  
   initFormValues(){

    this.sqldate = this.param.dateOfSurvey;
    this.sqldate2 = this.param.dateOfPlanting;
    this.sqldate3 = this.param.thiningOutDate1st;
    this.sqldate4 = this.param.thiningOutDate2nd;
    this.sqldate5 = this.param.thiningOutDate3rd;

    this.dtNSurveyObj = new Date(this.param.dateOfSurvey);
    this.dtPlantingObj = new Date(this.param.dateOfPlanting);
    this.dt1stThiningOutObj = new Date(this.param.thiningOutDate1st);
    this.dt2ndThiningOutObj = new Date(this.param.thiningOutDate2nd);
    this.dt3rdThiningOutObj = new Date(this.param.thiningOutDate3rd);
    
    this.immatureFieldsEditForm = new UntypedFormGroup(
        {
          immatureFieldCode: new UntypedFormControl(this.param.immatureFieldCode),
          companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(10)]),
          estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required, Validators.maxLength(10)]),
          divisionCode: new UntypedFormControl(this.param.divisionCode,[Validators.required, Validators.maxLength(10)]),
          fieldCode: new UntypedFormControl(this.param.fieldCode,[Validators.required, Validators.maxLength(10)]),
          specieCode: new UntypedFormControl(this.param.specieCode,[Validators.required, Validators.maxLength(10)]),
          fieldExtent: new UntypedFormControl(this.param.fieldExtent,[Validators.maxLength(10)]),
          yearofPlanting: new UntypedFormControl(this.param.yearofPlanting,[Validators.maxLength(10)]),
          initialPlants: new UntypedFormControl(this.param.initialPlants,[Validators.maxLength(10)]),
          noPresentPlants: new UntypedFormControl(this.param.noPresentPlants,[Validators.maxLength(10)]),
          terrain: new UntypedFormControl(this.param.terrain,[Validators.maxLength(10)]),
          distanceFromMainRoad: new UntypedFormControl(this.param.distanceFromMainRoad,[Validators.maxLength(10)]),
          slope: new UntypedFormControl(this.param.slope,[Validators.maxLength(10)]),
          soilType: new UntypedFormControl(this.param.soilType,[Validators.maxLength(10)]),
          soilDepth: new UntypedFormControl(this.param.soilDepth,[Validators.maxLength(10)]),
          elevationInMeters: new UntypedFormControl(this.param.elevationInMeters,[Validators.maxLength(8)]),
          surveyPlanNo: new UntypedFormControl(this.param.surveyPlanNo,[Validators.maxLength(10)]),
          dateOfSurvey: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate)),
          surveyPlanJPGTag: new UntypedFormControl(this.param.surveyPlanJPGTag,[Validators.maxLength(10)]),
          fundedBy: new UntypedFormControl(this.param.fundedBy,[Validators.maxLength(10)]),
          plantedByName1: new UntypedFormControl(this.param.plantedByName1,[Validators.maxLength(10)]),
          plantedByName2: new UntypedFormControl(this.param.plantedByName2,[Validators.maxLength(10)]),
          plantedByName3: new UntypedFormControl(this.param.plantedByName3,[Validators.maxLength(10)]),
          plantingDistance: new UntypedFormControl(this.param.plantingDistance,[Validators.maxLength(10)]),
          dateOfPlanting: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate2)),
          comments: new UntypedFormControl(this.param.comments,[Validators.maxLength(10)]),
          noPlantsConfirmed: new UntypedFormControl(this.param.noPlantsConfirmed,[Validators.maxLength(10)]),
          confirmationOfCenses: new UntypedFormControl(this.param.confirmationOfCenses,[Validators.maxLength(10)]),
          thiningOutDate1st: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate3)),
          thiningOutDate2nd: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate4)),
          thiningOutDate3rd: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate5)),
          thiningCut1st: new UntypedFormControl(this.param.thiningCut1st,[Validators.maxLength(10)]),
          thiningCut2nd: new UntypedFormControl(this.param.thiningCut2nd,[Validators.maxLength(10)]),
          thiningCut3rd: new UntypedFormControl(this.param.thiningCut3rd,[Validators.maxLength(10)]),
          thiningRemaining1st: new UntypedFormControl(this.param.thiningRemaining1st,[Validators.maxLength(10)]),
          thiningRemaining2nd: new UntypedFormControl(this.param.thiningRemaining2nd,[Validators.maxLength(10)]),
          thiningRemaining3rd: new UntypedFormControl(this.param.thiningRemaining3rd,[Validators.maxLength(10)])
        }
      );
        
      validateForm(this.immatureFieldsEditForm);
  
    }

    convertToNgbDateStruct(dateString: string): NgbDateStruct | null {
      if (!dateString) {
        return null;
      }
      const parts = dateString.split('-');
      return {
        year: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        day: parseInt(parts[2], 10)
      };
    }
  
    companyCodeChanged(event){
    }
  
    estateCodeChanged(estateCode: any) {
      console.log('Estate Code changed:', estateCode);
    
      this.refDataService.getDivisionsByEstateCode(estateCode)
          .subscribe(
            (response) => {
              var divisions: any = response;
              this.divisionCodeList = [];
              divisions.forEach(division => {
                this.divisionCodeList.push(division.divisionCode);
              });
              console.log('Response:', divisions);
            },
            (error) => {
              // Handle error if needed
              console.error('Error:', error);
            }
          );
    }
  
    fieldCodeChanged($event) {
    }
  
    divisionCodeChanged(divisionCode : any) {
      console.log('Division Code changed:', divisionCode);
    
      this.refDataService.getFieldsByDivisionCode(divisionCode)
          .subscribe(
            (response) => {
              var fields: any = response;
              this.fieldCodeList = [];
              fields.forEach(fields => {
                this.fieldCodeList.push(fields.fieldCode);
              });
              console.log('Response:', fields);
            },
            (error) => {
              // Handle error if needed
              console.error('Error:', error);
            }
          );
    }
    
    specieCodeChanged($event) {
    }
    
    yearOfPlantingChanged($event) {
    }
    
    terrainChanged($event) {
    }
    
    soilTypeChanged($event) {
    }
    
    soilDepthChanged($event) {
    }
    
    surveyPlanJPGTagChanged($event) {
    }

    plantingDistanceChanged($event){
    }
    
    confirmationOfCensesChanged($event) {
    }

    slopeChanged($event){
    }

    generateYearOfPlantingList(startYear: number, endYear: number): void {
      for (let year = startYear; year <= endYear; year++) {
        this.yearOfPlantingList.push({ id: year - startYear + 1, value: year.toString() });
      }
    }

    myformatDate(date: NgbDateStruct): string {
      if (!date) {
        return '';
      }
      const year = date.year;
      const month = date.month < 10 ? `0${date.month}` : date.month;
      const day = date.day < 10 ? `0${date.day}` : date.day;
      return `${year}-${month}-${day}`;
    }
  
    updateImmatureField(event) {
      if (this.immatureFieldsEditForm.valid) {
  
        this.loadingBtn = true;
        let immatureFieldCode = this.param.immatureFieldCode;
        let submitData = this.immatureFieldsEditForm.getRawValue();

        submitData.dateOfSurvey = this.myformatDate( this.immatureFieldsEditForm.controls["dateOfSurvey"].value);
        submitData.dateOfPlanting = this.myformatDate( this.immatureFieldsEditForm.controls["dateOfPlanting"].value);
        submitData.thiningOutDate1st = this.myformatDate( this.immatureFieldsEditForm.controls["thiningOutDate1st"].value);
        submitData.thiningOutDate2nd = this.myformatDate( this.immatureFieldsEditForm.controls["thiningOutDate2nd"].value);
        submitData.thiningOutDate3rd = this.myformatDate( this.immatureFieldsEditForm.controls["thiningOutDate3rd"].value);
        
        this.immatureFieldsService.update(immatureFieldCode, submitData).subscribe(res => {
          if (res) {
            this.loadingBtn = false;
            this.notificationService.success('Immature Field has been updated', 'Success');
            this.bsModalRef.hide();
              this.event.emit();
          } else {
            this.loadingBtn = false;
            this.notificationService.error('Immature Field has not been updated', 'Error');
          }
          
        }, (error => {
          this.loadingBtn = false;
          this.notificationService.error('Immature Field has not been updated', 'Error');
          
        }), () => {
          this.loadingBtn = false;
        });
      } else {
        validateForm(this.immatureFieldsEditForm);
      }
    }
  
}
