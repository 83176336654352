import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FieldService } from 'src/app/services/field.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-field-edit',
  templateUrl: './field-edit.component.html',
  styleUrls: ['./field-edit.component.scss']
})
export class FieldEditComponent {
  
  onChange() {
    console.log("Changed Date")
  }
  
    roleData:any[] = [];
    userGroupData:any[] = [];
    initialState:any;
    param:any;
    refData : any;
  
    event: EventEmitter<any> = new EventEmitter();
    ismatch:Boolean = true;
    loadingBtn : Boolean = false;
    disableButton:Boolean = false;
  
    fieldEditForm:any;
    companyCodeList: any[]=[];  
    estateCodeList: any[]=[];  
    maturityStatusList: any[]=[];  
    fieldMainCropList: any[]=[];  
    fieldTypeList: any[]=[];  
    divisionCodeList: any[]=[];

    constructor(
        public bsModalRef : BsModalRef,
        private notificationService : ToastrService,
        private fieldService: FieldService,
        private refDataService: RefdataService,
        private urlService: UrlService
    ) { 
      
  
    }
  
    ngOnInit(): void {
        this.initFormValues();

        var companyCode = this.urlService.getHostName(window.location);
        this.refDataService.getEstateList(companyCode).subscribe((data)=>{
          this.refData = data;
          this.refData.forEach(estate => {
            this.estateCodeList.push(estate.estateCode);
          });

        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Estates Completed");
        });

        this.estateCodeChanged(this.param.estateCode);

        this.fieldTypeChanged(this.param.fieldType);

      this.refDataService.getFieldRefData().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);

        this.maturityStatusList = this.refData.matStatus;
        this.fieldTypeList = this.refData.fdType;

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Divisions Completed");
      });

      this.refDataService.getCropTypes().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);
  
        this.refData.forEach(item => {
          this.fieldMainCropList.push({
            id: item.typeNumber, 
            value: item.cropType 
          });
        });
  
      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Crop Types Completed");
      });
    
        let hostName = this.urlService.getHostName(window.location);
          // console.log(hostName);
        this.companyCodeList.push(hostName);
        this.fieldEditForm.controls["companyCode"].patchValue(hostName);
        this.fieldEditForm.controls["companyCode"].disable();

    }
  
  
   initFormValues(){
      this.fieldEditForm = new UntypedFormGroup(
          {
            companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(10)]),
            estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required, Validators.maxLength(10)]),
            divisionCode: new UntypedFormControl(this.param.divisionCode,[Validators.required, Validators.maxLength(10)]),
            fieldCode: new UntypedFormControl(this.param.fieldCode,[Validators.required, Validators.maxLength(10)]),
            fieldExtent: new UntypedFormControl(this.param.fieldExtent,[Validators.maxLength(10)]),
            maturityStatus: new UntypedFormControl(this.param.maturityStatus,[Validators.maxLength(10)]),
            fieldType: new UntypedFormControl(this.param.fieldType,[Validators.maxLength(10)]),
            fieldMainCrop: new UntypedFormControl(this.param.fieldMainCrop,[Validators.maxLength(50)]),
            gpsCoordinates: new UntypedFormControl(this.param.gpsCoordinates,[Validators.maxLength(1000)]),
            gpsCoordinatesLong: new UntypedFormControl(this.param.gpsCoordinatesLong,[Validators.maxLength(15)]),
            gpsCoordinatesLati: new UntypedFormControl(this.param.gpsCoordinatesLati,[Validators.maxLength(15)])
          }
      );
        
      validateForm(this.fieldEditForm);
  
    }
    
    divisionCodeChanged($event) {
    }

    companyCodeChanged(event){
    }
    
    estateCodeChanged(estateCode : any) {
      this.refDataService.getDivisionsByEstateCode(estateCode)
      .subscribe(
        (response) => {
          var divisions: any = response;
          this.divisionCodeList = [];
          divisions.forEach(division => {
            this.divisionCodeList.push(division.divisionCode);
          });
          console.log('Response:', divisions);
        },
        (error) => {
          // Handle error if needed
          console.error('Error:', error);
        }
      );
    }
    
    fieldMainCropChanged($event) {
    }
    
    fieldTypeChanged(pureCrop) {
      // console.log("Crop Type:",pureCrop);
      if (pureCrop == 1) { // Replace 'pureCropId' with the actual value of the pure crop ID
        this.fieldEditForm.controls['fieldMainCrop'].setValue(6); // Set maturity status to ID 5
        this.fieldEditForm.controls["fieldMainCrop"].disable();
    
      } else {
        // Optionally reset the maturity status if needed
        this.fieldEditForm.controls['fieldMainCrop'].reset();
        this.fieldEditForm.controls['fieldMainCrop'].enable();
        console.log("Maturity Status reset");
      }
    }
    
    maturityStatusChanged($event) {
    }
  
    updateField(event) {
      if (this.fieldEditForm.valid) {
  
        this.loadingBtn = true;
        let fieldCode = this.param.fieldCode;
        let submitData = this.fieldEditForm.getRawValue();
        
        this.fieldService.update(fieldCode, submitData).subscribe(res => {
          if (res) {
            this.loadingBtn = false;
            this.notificationService.success('Field has been updated', 'Success');
            this.bsModalRef.hide();
              this.event.emit();
          } else {
            this.loadingBtn = false;
            this.notificationService.error('Field has not been updated', 'Error');
          }
          
        }, (error => {
          this.loadingBtn = false;
          this.notificationService.error('Field has not been updated', 'Error');
          
        }), () => {
          this.loadingBtn = false;
        });
      } else {
        validateForm(this.fieldEditForm);
      }
    }
  
  
}
