import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CorporateRoutingModule } from './corporate-routing.module';
import { ParentCompanyComponent } from './parent-company/parent-company.component';
import { CompanyComponent } from './company/company.component';
import { EstateComponent } from './estate/estate.component';
import { DivisionComponent } from './division/division.component';
import { FieldComponent } from './field/field.component';
import { NgxSelectComponent, NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CompanyAddComponent } from './company/components/company-add/company-add.component';
import { CompanyEditComponent } from './company/components/company-edit/company-edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { ReferenceDataRoutingModule } from '../reference-data/reference-data-routing.module';
import { NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EstateAddComponent } from './estate/components/estate-add/estate-add.component';
import { EstateEditComponent } from './estate/components/estate-edit/estate-edit.component';
import { DivisionAddComponent } from './division/components/division-add/division-add.component';
import { DivisionEditComponent } from './division/components/division-edit/division-edit.component';
import { FieldAddComponent } from './field/components/field-add/field-add.component';
import { FieldEditComponent } from './field/components/field-edit/field-edit.component';
import { MtsReportComponent } from './estate/components/mts-report/mts-report.component';


@NgModule({
  declarations: [
    ParentCompanyComponent,
    CompanyComponent,
    EstateComponent,
    DivisionComponent,
    FieldComponent,
    CompanyAddComponent,
    CompanyEditComponent,
    EstateAddComponent,
    EstateEditComponent,
    DivisionAddComponent,
    DivisionEditComponent,
    FieldAddComponent,
    FieldEditComponent,
    MtsReportComponent,
  ],
  imports: [
    CommonModule,
    NgxSelectModule,
    NgbDatepicker,
    NgbDatepickerModule,
    ReferenceDataRoutingModule,
    NgxPaginationModule,
    ModalModule,
    TreeTableModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CorporateRoutingModule
  ]
})
export class CorporateModule { }
