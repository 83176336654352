<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Tree Details</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  class="p-3"
  [formGroup]="treeDetailsEditForm"
  (ngSubmit)="updateTreeDetail(event)"
>
  <div class="modal-body-edit-tree-details">
    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="estateCodeList"
          placeholder="Estate Code"
          formControlName="estateCode"
          (select)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            treeDetailsEditForm.controls['estateCode'].invalid &&
            (treeDetailsEditForm.controls['estateCode'].dirty ||
              treeDetailsEditForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="treeDetailsEditForm.controls['estateCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="divisionCodeList"
          placeholder="Division Code"
          formControlName="divisionCode"
          (select)="divisionCodeChanged($event)"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'divisionCode'"
        ></ngx-select>
        <div
          *ngIf="
            treeDetailsEditForm.controls['divisionCode'].invalid &&
            (treeDetailsEditForm.controls['divisionCode'].dirty ||
              treeDetailsEditForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="treeDetailsEditForm.controls['divisionCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="fieldCodeList"
          placeholder="Field Code"
          formControlName="fieldCode"
          (select)="fieldCodeChanged($event)"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'fieldCode'"
        ></ngx-select>
        <div
          *ngIf="
            treeDetailsEditForm.controls['fieldCode'].invalid &&
            (treeDetailsEditForm.controls['fieldCode'].dirty ||
              treeDetailsEditForm.controls['fieldCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="treeDetailsEditForm.controls['fieldCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Specie Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="specieCodeList"
          placeholder="Specie Code"
          formControlName="specieCode"
          (select)="specieCodeChanged($event)"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'specieCode'"
        ></ngx-select>
        <div
          *ngIf="
            treeDetailsEditForm.controls['specieCode'].invalid &&
            (treeDetailsEditForm.controls['specieCode'].dirty ||
              treeDetailsEditForm.controls['specieCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="treeDetailsEditForm.controls['specieCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="treeNo" class="col-sm-4 col-form-label col-form-label-sm"
        >Tree Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="11"
          appDecimal="2"
          class="form-control"
          formControlName="treeNo"
          placeholder="Tree Number"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'treeNo'"
        />
        <div
          *ngIf="
            treeDetailsEditForm.controls['treeNo'].invalid &&
            (treeDetailsEditForm.controls['treeNo'].dirty ||
              treeDetailsEditForm.controls['treeNo'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="treeDetailsEditForm.controls['treeNo'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="remarks" class="col-sm-4 col-form-label col-form-label-sm"
        >Remarks
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="remarksList"
          placeholder="Remarks"
          formControlName="remarks"
          (select)="remarksChanged($event)"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'remarks'"
        ></ngx-select>
        <div
          *ngIf="
            treeDetailsEditForm.controls['remarks'].invalid &&
            (treeDetailsEditForm.controls['remarks'].dirty ||
              treeDetailsEditForm.controls['remarks'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="treeDetailsEditForm.controls['remarks'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="girthBrestHeight"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Girth Brest Height in cm.</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="girthBrestHeight"
          placeholder="Girth Brest Height in cm."
          appInfoPromptBox
          [page]="'matured-fields'"
          [component]="'edit'"
          [field]="'girthBrestHeight'"
        />
        <div
          *ngIf="
            treeDetailsEditForm.controls['girthBrestHeight'].invalid &&
            (treeDetailsEditForm.controls['girthBrestHeight'].dirty ||
              treeDetailsEditForm.controls['girthBrestHeight'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              treeDetailsEditForm.controls['girthBrestHeight'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="height" class="col-sm-4 col-form-label col-form-label-sm"
        >Height in Feet</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="height"
          placeholder="Height in Feet"
          appInfoPromptBox
          [page]="'tree-details'"
          [component]="'edit'"
          [field]="'height'"
        />
        <div
          *ngIf="
            treeDetailsEditForm.controls['height'].invalid &&
            (treeDetailsEditForm.controls['height'].dirty ||
              treeDetailsEditForm.controls['height'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="treeDetailsEditForm.controls['height'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfMeasure"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Measure</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            id="dateOfMeasure"
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d1"
            formControlName="dateOfMeasure"
            ngbDatepicker
            #d1="ngbDatepicker"
            [value]="dtMeasureObj"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="gpsLocation"
        class="col-sm-4 col-form-label col-form-label-sm"
        >GPS Location</label
      >
      <div class="col-sm-8">
        <input
        type="text"
        maxlength="25"
        class="form-control"
        formControlName="gpsLocationLong"
        placeholder="Longitude"
        style="width: 48%; display: inline-block; margin-right: 4%;"
      />
      <input
        type="text"
        maxlength="25"
        class="form-control"
        formControlName="gpsLocationLati"
        placeholder="Latitude"
        style="width: 48%; display: inline-block;"
      />
        <div
          *ngIf="
            treeDetailsEditForm.controls['gpsLocation'].invalid &&
            (treeDetailsEditForm.controls['gpsLocation'].dirty ||
              treeDetailsEditForm.controls['gpsLocation'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="treeDetailsEditForm.controls['gpsLocation'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
