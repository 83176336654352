import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

    getAllCompany(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/company/" + apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/company/", data);
    }

    update(companyCode,data)
    {
        return this.http.put(environment.apiUrl + "/company/" + companyCode, data);
    }

    delete(companyCode)
    {
        if (!companyCode) return;
        return this.http.delete(environment.apiUrl + "/company/" + companyCode);
    }

}