<div class="modal-header">
    <h5 class="modal-title pull-left">Add Forestry Management Plan Detail</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingMasterPlanDetailsAddForm"
    (ngSubmit)="createNewHarvestingMasterPlanDetail()"
    enctype="multipart/form-data"
  >
  <div class="modal-body-add-master-plan-detail">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['companyCode'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['companyCode'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['companyCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="estateCodeList"
          placeholder="Estate Code"
          formControlName="estateCode"
          (select)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['estateCode'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['estateCode'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['estateCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="divisionCodeList"
          placeholder="Division Code"
          formControlName="divisionCode"
          (select)="divisionCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'divisionCode'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['divisionCode'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['divisionCode'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['divisionCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="fieldCodeList"
          placeholder="Field Code"
          formControlName="fieldCode"
          (select)="fieldCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'fieldCode'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['fieldCode'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['fieldCode'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['fieldCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['fieldCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="crop" class="col-sm-4 col-form-label col-form-label-sm"
        >Crop</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="crop"
          placeholder="Crop"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'crop'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['crop'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['crop'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['crop'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="harvestingMasterPlanDetailsAddForm.controls['crop'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Specie Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="specieCodeList"
          placeholder="Specie Code"
          formControlName="specieCode"
          (select)="specieCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'specieCode'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['specieCode'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['specieCode'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['specieCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['specieCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="trees" class="col-sm-4 col-form-label col-form-label-sm"
        >Trees</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="trees"
          placeholder="Trees"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'trees'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['trees'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['trees'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['trees'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['trees'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="gbHeight" class="col-sm-4 col-form-label col-form-label-sm"
        >GB Height</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="gbHeight"
          placeholder="GB Height"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'gbHeight'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['gbHeight'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['gbHeight'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['gbHeight'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['gbHeight'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="volumeCDM" class="col-sm-4 col-form-label col-form-label-sm"
        >Volume CDM</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="volumeCDM"
          placeholder="Volume CDM"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'volumeCDM'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['volumeCDM'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['volumeCDM'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['volumeCDM'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['volumeCDM'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="mv" class="col-sm-4 col-form-label col-form-label-sm"
        >M:V</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="mv"
          placeholder="M:V"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'mv'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['mv'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['mv'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['mv'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['mv'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="age" class="col-sm-4 col-form-label col-form-label-sm"
        >Age</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="age"
          placeholder="Age"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'age'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['age'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['age'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['age'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['age'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="extHectare" class="col-sm-4 col-form-label col-form-label-sm"
        >Exact Hectare</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="extHectare"
          placeholder="Exact Hectare"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'extHectare'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['extHectare'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['extHectare'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['extHectare'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="harvestingMasterPlanDetailsAddForm.controls['extHectare'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="year"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Year
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="harvestingYearList"
          placeholder="Year"
          formControlName="year"
          (select)="yearList($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'year'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['year'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['year'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['year'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              harvestingMasterPlanDetailsAddForm.controls['year'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="operation" class="col-sm-4 col-form-label col-form-label-sm"
        >Operation</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="15"
          class="form-control"
          formControlName="operation"
          placeholder="Operation"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'operation'"
        />
        <div
          *ngIf="
            harvestingMasterPlanDetailsAddForm.controls['operation'].invalid &&
            (harvestingMasterPlanDetailsAddForm.controls['operation'].dirty ||
              harvestingMasterPlanDetailsAddForm.controls['operation'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="harvestingMasterPlanDetailsAddForm.controls['operation'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>