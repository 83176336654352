<div class="modal-header">
  <h5 class="modal-title pull-left">Add immature Field</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  class="p-3"
  [formGroup]="immatureFieldsAddForm"
  (ngSubmit)="createNewImmatureField()"
>
  <div class="modal-body-add-immature-field">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['companyCode'].invalid &&
            (immatureFieldsAddForm.controls['companyCode'].dirty ||
              immatureFieldsAddForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['companyCode'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="estateCodeList"
          placeholder="Estate Code"
          formControlName="estateCode"
          (select)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['estateCode'].invalid &&
            (immatureFieldsAddForm.controls['estateCode'].dirty ||
              immatureFieldsAddForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['estateCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="divisionCodeList"
          placeholder="Division Code"
          formControlName="divisionCode"
          (select)="divisionCodeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'divisionCode'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['divisionCode'].invalid &&
            (immatureFieldsAddForm.controls['divisionCode'].dirty ||
              immatureFieldsAddForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['divisionCode'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="fieldCodeList"
          placeholder="Field Code"
          formControlName="fieldCode"
          (select)="fieldCodeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'fieldCode'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['fieldCode'].invalid &&
            (immatureFieldsAddForm.controls['fieldCode'].dirty ||
              immatureFieldsAddForm.controls['fieldCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['fieldCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Specie Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="specieCodeList"
          placeholder="Specie Code"
          formControlName="specieCode"
          (select)="specieCodeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'specieCode'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['specieCode'].invalid &&
            (immatureFieldsAddForm.controls['specieCode'].dirty ||
              immatureFieldsAddForm.controls['specieCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['specieCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="immatureFieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Immature Field Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          class="form-control"
          formControlName="immatureFieldCode"
          placeholder="Immature Field Code"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'immatureFieldCode'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['immatureFieldCode'].invalid &&
            (immatureFieldsAddForm.controls['immatureFieldCode'].dirty ||
              immatureFieldsAddForm.controls['immatureFieldCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="immatureFieldsAddForm.controls['immatureFieldCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="fieldExtent" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="fieldExtent"
          placeholder="Field Extent"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'fieldExtent'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['fieldExtent'].invalid &&
            (immatureFieldsAddForm.controls['fieldExtent'].dirty ||
              immatureFieldsAddForm.controls['fieldExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['fieldExtent'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="yearofPlanting"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Year of Planting
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="yearOfPlantingList"
          placeholder="Year of Planting"
          formControlName="yearofPlanting"
          (select)="yearOfPlantingChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'yearofPlanting'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['yearofPlanting'].invalid &&
            (immatureFieldsAddForm.controls['yearofPlanting'].dirty ||
              immatureFieldsAddForm.controls['yearofPlanting'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['yearofPlanting'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="initialPlants"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Initial Plants</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="initialPlants"
          placeholder="Initial Plants"
          appInfoPromptBox
          [page]="'matured-fields'"
          [component]="'add'"
          [field]="'initialPlants'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['initialPlants'].invalid &&
            (immatureFieldsAddForm.controls['initialPlants'].dirty ||
              immatureFieldsAddForm.controls['initialPlants'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['initialPlants'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="noPresentPlants"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants at present</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="noPresentPlants"
          placeholder="No of plants at present"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'noPresentPlants'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['noPresentPlants'].invalid &&
            (immatureFieldsAddForm.controls['noPresentPlants'].dirty ||
              immatureFieldsAddForm.controls['noPresentPlants'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['noPresentPlants'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="terrain" class="col-sm-4 col-form-label col-form-label-sm"
        >Terrain
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="terrainList"
          placeholder="Terrain"
          formControlName="terrain"
          (select)="terrainChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'terrain'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['terrain'].invalid &&
            (immatureFieldsAddForm.controls['terrain'].dirty ||
              immatureFieldsAddForm.controls['terrain'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['terrain'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="distanceFromMainRoad"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Distance from Main road</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="distanceFromMainRoad"
          placeholder="Distance from Main road"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'distanceFromMainRoad'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['distanceFromMainRoad'].invalid &&
            (immatureFieldsAddForm.controls['distanceFromMainRoad'].dirty ||
              immatureFieldsAddForm.controls['distanceFromMainRoad'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['distanceFromMainRoad'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="slope" class="col-sm-4 col-form-label col-form-label-sm"
        >Slope
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="slopeList"
          placeholder="slope"
          formControlName="slope"
          (select)="slopeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'slope'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['slope'].invalid &&
            (immatureFieldsAddForm.controls['slope'].dirty ||
              immatureFieldsAddForm.controls['slope'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="immatureFieldsAddForm.controls['terrain'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="soilType" class="col-sm-4 col-form-label col-form-label-sm"
        >Soil Type
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="soilTypeList"
          placeholder="Soil Type"
          formControlName="soilType"
          (select)="soilTypeChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'soilType'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['soilType'].invalid &&
            (immatureFieldsAddForm.controls['soilType'].dirty ||
              immatureFieldsAddForm.controls['soilType'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['soilType'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="soilDepth" class="col-sm-4 col-form-label col-form-label-sm"
        >Soil Depth
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="soilDepthList"
          placeholder="Soil Depth"
          formControlName="soilDepth"
          (select)="soilDepthChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'soilDepth'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['soilDepth'].invalid &&
            (immatureFieldsAddForm.controls['soilDepth'].dirty ||
              immatureFieldsAddForm.controls['soilDepth'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['soilDepth'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="elevationInMeters"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Elevation in Meters</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="elevationInMeters"
          placeholder="Elevation in Meters"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'elevationInMeters'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['elevationInMeters'].invalid &&
            (immatureFieldsAddForm.controls['elevationInMeters'].dirty ||
              immatureFieldsAddForm.controls['elevationInMeters'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['elevationInMeters'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="surveyPlanNo"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Survey Plan No.</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="surveyPlanNo"
          placeholder="Survey Plan No."
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'surveyPlanNo'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['surveyPlanNo'].invalid &&
            (immatureFieldsAddForm.controls['surveyPlanNo'].dirty ||
              immatureFieldsAddForm.controls['surveyPlanNo'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['surveyPlanNo'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfSurvey"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Surveye</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d1"
            formControlName="dateOfSurvey"
            ngbDatepicker
            #d1="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="surveyPlanJPGTag"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Survey Plan Image <Tfoot></Tfoot>
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          placeholder="Survey Plan Image"
          formControlName="surveyPlanJPGTag"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'surveyPlanJPGTag'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['surveyPlanJPGTag'].invalid &&
            (immatureFieldsAddForm.controls['surveyPlanJPGTag'].dirty ||
              immatureFieldsAddForm.controls['surveyPlanJPGTag'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['surveyPlanJPGTag'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fundedBy" class="col-sm-4 col-form-label col-form-label-sm"
        >Funded by</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="fundedBy"
          placeholder="Funded by"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'fundedBy'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['fundedBy'].invalid &&
            (immatureFieldsAddForm.controls['fundedBy'].dirty ||
              immatureFieldsAddForm.controls['fundedBy'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['fundedBy'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName1"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 1</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName1"
          placeholder="Planted by - Name 1"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'plantedByName1'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['plantedByName1'].invalid &&
            (immatureFieldsAddForm.controls['plantedByName1'].dirty ||
              immatureFieldsAddForm.controls['plantedByName1'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['plantedByName1'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName2"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 2</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName2"
          placeholder="Planted by - Name 2"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'plantedByName2'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['plantedByName2'].invalid &&
            (immatureFieldsAddForm.controls['plantedByName2'].dirty ||
              immatureFieldsAddForm.controls['plantedByName2'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['plantedByName2'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName3"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 3</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName3"
          placeholder="Planted by - Name 3"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'plantedByName3'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['plantedByName3'].invalid &&
            (immatureFieldsAddForm.controls['plantedByName3'].dirty ||
              immatureFieldsAddForm.controls['plantedByName3'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['plantedByName3'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantingDistance"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planting Distance
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="plantingDistanceList"
          placeholder="Planting Distance"
          formControlName="plantingDistance"
          (select)="plantingDistanceChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'plantingDistance'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['plantingDistance'].invalid &&
            (immatureFieldsAddForm.controls['plantingDistance'].dirty ||
              immatureFieldsAddForm.controls['plantingDistance'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['plantingDistance'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfPlanting"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Planting</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d2"
            formControlName="dateOfPlanting"
            ngbDatepicker
            #d2="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d2.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="comments" class="col-sm-4 col-form-label col-form-label-sm"
        >Comments / Remarks</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="200"
          class="form-control"
          formControlName="comments"
          placeholder="Comments / Remarks"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'comments'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['comments'].invalid &&
            (immatureFieldsAddForm.controls['comments'].dirty ||
              immatureFieldsAddForm.controls['comments'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="immatureFieldsAddForm.controls['comments'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="noPlantsConfirmed"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants confirmed</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="noPlantsConfirmed"
          placeholder="No of plants confirmed"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'noPlantsConfirmed'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['noPlantsConfirmed'].invalid &&
            (immatureFieldsAddForm.controls['noPlantsConfirmed'].dirty ||
              immatureFieldsAddForm.controls['noPlantsConfirmed'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['noPlantsConfirmed'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="confirmationOfCenses"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Confirmation of Censes
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="confirmationOfCensesList"
          placeholder="Confirmation of Censes"
          formControlName="confirmationOfCenses"
          (select)="confirmationOfCensesChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'confirmationOfCenses'"
        ></ngx-select>
        <div
          *ngIf="
            immatureFieldsAddForm.controls['confirmationOfCenses'].invalid &&
            (immatureFieldsAddForm.controls['confirmationOfCenses'].dirty ||
              immatureFieldsAddForm.controls['confirmationOfCenses'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['confirmationOfCenses'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningOutDate1st"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        1st Thining-out due date</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d3"
            formControlName="thiningOutDate1st"
            ngbDatepicker
            #d3="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d3.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningOutDate2nd"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        2nd Thining-out due date</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d4"
            formControlName="thiningOutDate2nd"
            ngbDatepicker
            #d4="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d4.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningOutDate3rd"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        3rd Thining-out due date</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d5"
            formControlName="thiningOutDate3rd"
            ngbDatepicker
            #d5="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d5.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningCut1st"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants removed from 1st Thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningCut1st"
          placeholder="No of plants removed from 1st Thining-out"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'thiningCut1st'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningCut1st'].invalid &&
            (immatureFieldsAddForm.controls['thiningCut1st'].dirty ||
              immatureFieldsAddForm.controls['thiningCut1st'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningCut1st'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningCut2nd"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants removed from 2nd Thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningCut2nd"
          placeholder="No of plants removed from 2nd Thining-out"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'thiningCut2nd'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningCut2nd'].invalid &&
            (immatureFieldsAddForm.controls['thiningCut2nd'].dirty ||
              immatureFieldsAddForm.controls['thiningCut2nd'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningCut2nd'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningCut3rd"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants removed from 3rd Thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningCut3rd"
          placeholder="No of plants removed from 3rd Thining-out"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'thiningCut3rd'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningCut3rd'].invalid &&
            (immatureFieldsAddForm.controls['thiningCut3rd'].dirty ||
              immatureFieldsAddForm.controls['thiningCut3rd'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningCut3rd'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningRemaining1st"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Remaining plants after 1st thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningRemaining1st"
          placeholder="Remaining plants after 1st thining-out"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'thiningRemaining1st'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningRemaining1st'].invalid &&
            (immatureFieldsAddForm.controls['thiningRemaining1st'].dirty ||
              immatureFieldsAddForm.controls['thiningRemaining1st'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningRemaining1st'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningRemaining2nd"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Remaining plants after 2nd thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningRemaining2nd"
          placeholder="Remaining plants after 2nd thining-out"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'thiningRemaining2nd'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningRemaining2nd'].invalid &&
            (immatureFieldsAddForm.controls['thiningRemaining2nd'].dirty ||
              immatureFieldsAddForm.controls['thiningRemaining2nd'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningRemaining2nd'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="thiningRemaining3rd"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Remaining plants after 3rd thining-out</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          appDecimal="2"
          class="form-control"
          formControlName="thiningRemaining3rd"
          placeholder="Remaining plants after 3rd thining-out"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'thiningRemaining3rd'"
        />
        <div
          *ngIf="
            immatureFieldsAddForm.controls['thiningRemaining3rd'].invalid &&
            (immatureFieldsAddForm.controls['thiningRemaining3rd'].dirty ||
              immatureFieldsAddForm.controls['thiningRemaining3rd'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              immatureFieldsAddForm.controls['thiningRemaining3rd'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
