<div class="modal-header">
    <h5 class="modal-title pull-left">Add Harvesting Plan Record</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingPlanAddForm"
    (ngSubmit)="createNewHarvestingPlan()"
  >
    <div class="modal-body-add-mature-field">
      <div class="form-group row">
        <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Company Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="companyCodeList"
            formControlName="companyCode"
            placeholder="Company Code"
            (select)="companyCodeChanged($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'companyCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanAddForm.controls['companyCode'].invalid &&
              (harvestingPlanAddForm.controls['companyCode'].dirty ||
                harvestingPlanAddForm.controls['companyCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingPlanAddForm.controls['companyCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="divisionCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Division Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="divisionByCompanyCodeList"
            formControlName="divisionCode"
            placeholder="Division Code"
            (select)="divisionCodeChanged($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'divisionCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanAddForm.controls['divisionCode'].invalid &&
              (harvestingPlanAddForm.controls['divisionCode'].dirty ||
                harvestingPlanAddForm.controls['divisionCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingPlanAddForm.controls['divisionCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label
          for="planYear"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Plan Year
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="planYearList"
            placeholder="Plan Year"
            formControlName="planYear"
            (select)="planYearsList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'planYear'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanAddForm.controls['planYear'].invalid &&
              (harvestingPlanAddForm.controls['planYear'].dirty ||
                harvestingPlanAddForm.controls['planYear'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanAddForm.controls['planYear'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="planMonth"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Plan Month
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="planMonthList"
            placeholder="Plan Month"
            formControlName="planMonth"
            (select)="planMonthsList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'planMonth'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanAddForm.controls['planMonth'].invalid &&
              (harvestingPlanAddForm.controls['planMonth'].dirty ||
                harvestingPlanAddForm.controls['planMonth'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanAddForm.controls['planMonth'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label col-form-label-sm"
          >Description</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="100"
            class="form-control"
            formControlName="description"
            placeholder="Description"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'description'"
          />
          <div
            *ngIf="
              harvestingPlanAddForm.controls['description'].invalid &&
              (harvestingPlanAddForm.controls['description'].dirty ||
                harvestingPlanAddForm.controls['description'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingPlanAddForm.controls['description'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="harvestingType"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Harvesting Type
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="harvestigType"
          placeholder="Harvesting Type"
          formControlName="harvestingType"
          (select)="harvestigTypeChanged($event)"
          appInfoPromptBox
          [page]="'harvesting-plan'"
          [component]="'add'"
          [field]="'harvestingType'"
        ></ngx-select>
        <div
          *ngIf="
            harvestingPlanAddForm.controls['harvestingType'].invalid &&
            (harvestingPlanAddForm.controls['harvestingType'].dirty ||
              harvestingPlanAddForm.controls['harvestingType'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              harvestingPlanAddForm.controls['harvestingType'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
  