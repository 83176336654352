import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DivisionService } from 'src/app/services/division.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-division-add',
  templateUrl: './division-add.component.html',
  styleUrls: ['./division-add.component.scss']
})
export class DivisionAddComponent {

  param:any;
  refData : any;
  model1: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  managersList: any[]=[];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  divisionAddForm = new UntypedFormGroup({
    companyCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    divisionCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    divisionName: new UntypedFormControl('',[Validators.required, Validators.maxLength(100)]),
    seedingExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    vpExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    revenueExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    immatureTeaExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    immatureRubberExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    fuelwoodExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    forestryExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    totalExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    managerStakeholderID: new UntypedFormControl('',[Validators.maxLength(10)]),
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private divisionService : DivisionService,
      private refDataService : RefdataService,
      private urlService: UrlService
  ) { 
    

  }

companyCodeChanged(event){
}

estateCodeChanged(estateCode: any) {
  // console.log('Estate Code changed:', estateCode);
  // console.log('Company Code:', this.companyCodeList[0]);

  this.refDataService.getManagerByEstate(this.companyCodeList[0],estateCode)
      .subscribe(
        (response) => {
          var managers: any = response;
          // console.log("Managers:",managers);
          
          this.managersList = [];
          managers.forEach(managers => {
            this.managersList.push(managers.managerId);
          });
          // console.log('Response:', managers);
        },
        (error) => {
          // Handle error if needed
          console.error('Error:', error);
        }
      );
}

managersChanged($event) {
}


  onChange(){
    console.log(this.divisionAddForm.getRawValue());
  }

  ngOnInit(): void {

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
    });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
      console.log(hostName);
    this.companyCodeList.push(hostName);
    this.divisionAddForm.controls["companyCode"].patchValue(hostName);
    this.divisionAddForm.controls["companyCode"].disable();

    

    this.divisionAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }


  createNewDivision(){
    let formData = this.divisionAddForm.getRawValue();
    this.loadingBtn = true;
    this.divisionService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Division has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Division has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Division already exist', 'Error');
      }
      else {
        this.notification.error('Division has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }


}
