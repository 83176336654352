import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DivisionService } from 'src/app/services/division.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-division-edit',
  templateUrl: './division-edit.component.html',
  styleUrls: ['./division-edit.component.scss']
})
export class DivisionEditComponent {

  
  onChange() {
    console.log("Changed Date")
  }

  roleData:any[] = [];
  userGroupData:any[] = [];
  initialState:any;
  param:any;
  refData:any;

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;

  divisionEditForm:any;
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  managersList:any[]=[];

  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private divisionService: DivisionService,
      private urlService: UrlService, 
      private refDataService: RefdataService
  ) { 
    

  }

  ngOnInit(): void {
    this.initFormValues();

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
    });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
      console.log(hostName);
    this.companyCodeList.push(hostName);
    this.divisionEditForm.controls["companyCode"].patchValue(hostName);
    this.divisionEditForm.controls["companyCode"].disable();

    this.estateCodeChanged(this.param.estateCode);
  }


 initFormValues(){
    this.divisionEditForm = new UntypedFormGroup(
        {
          companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(10)]),
          estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required, Validators.maxLength(10)]),
          divisionCode: new UntypedFormControl(this.param.divisionCode,[Validators.required, Validators.maxLength(10)]),
          divisionName: new UntypedFormControl(this.param.divisionName,[Validators.required, Validators.maxLength(100)]),
          seedingExtent: new UntypedFormControl(this.param.seedingExtent,[Validators.maxLength(10)]),
          vpExtent: new UntypedFormControl(this.param.vpExtent,[Validators.maxLength(10)]),
          revenueExtent: new UntypedFormControl(this.param.revenueExtent,[Validators.maxLength(10)]),
          immatureTeaExtent: new UntypedFormControl(this.param.immatureTeaExtent,[Validators.maxLength(10)]),
          immatureRubberExtent: new UntypedFormControl(this.param.immatureRubberExtent,[Validators.maxLength(10)]),
          fuelwoodExtent: new UntypedFormControl(this.param.fuelwoodExtent,[Validators.maxLength(10)]),
          forestryExtent: new UntypedFormControl(this.param.forestryExtent,[Validators.maxLength(10)]),
          totalExtent: new UntypedFormControl(this.param.totalExtent,[Validators.maxLength(10)]),
          managerStakeholderID: new UntypedFormControl(this.param.managerStakeholderID,[Validators.maxLength(10)])
        }
    );
      
    validateForm(this.divisionEditForm);

  }

  companyCodeChanged(event){
  }
  
  estateCodeChanged(estateCode: any) {
    // console.log('Estate Code changed:', estateCode);
    // console.log('Company Code:', this.companyCodeList[0]);
  
    this.refDataService.getManagerByEstate(this.companyCodeList[0],estateCode)
        .subscribe(
          (response) => {
            var managers: any = response;
            // console.log("Managers:",managers);
            
            this.managersList = [];
            managers.forEach(managers => {
              this.managersList.push(managers.managerId);
            });
            // console.log('Response:', managers);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  managersChanged($event) {
  }
  


  updateDivision(event) {
    if (this.divisionEditForm.valid) {

      this.loadingBtn = true;
      let divisionCode = this.param.divisionCode;
      let submitData = this.divisionEditForm.getRawValue();
      
      this.divisionService.update(divisionCode, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Division has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Division has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Division has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.divisionEditForm);
    }
  }


}
