import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from './components/icon/icon.component';
import {DecimalDirective} from '../directives/decimal.directive';
import { ButtonLoaderDirective } from '../directives/button-loader.directive';
import {MinMaxDirective} from '../directives/min-max.directive';
import {MandatoryFieldDirective} from "../directives/mandatory-field.directive";
import {PreventDoubleSubmitDirective} from '../directives/prevent-double-submit.directive';
import {DivDisableDirective} from "../directives/div-disable.directive";
import {ActionCommentComponent} from './components/action-comment/action-comment.component';
import {NgxSelectModule} from "ngx-select-ex";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {InfoPromptBoxDirective} from "../directives/info-prompt-box.directive";
import {ThousandSeparatorDirective} from "../directives/thousand-separator.directive";
import {AddEditTagComponent} from './components/add-edit-tag/add-edit-tag.component';
import {FreezeFunctionCommentsDialogComponent} from './components/freeze-function-comments-dialog/freeze-function-comments-dialog.component';
import {TreeTableModule} from "./components/tree-table/tree-table.module";
import { VersionModalComponent } from './components/version-modal/version-modal.component';
import { AuthenticationFailedModalComponent } from './components/authentication-failed-modal/authentication-failed-modal.component';
import { CommonErrorModalComponent } from './components/common-error-modal/common-error-modal.component';
import { HarvestingAddModalComponent } from './components/harvesting-modal/harvesting-add-modal/harvesting-add-modal.component';


@NgModule({
    declarations: [IconComponent, DecimalDirective,ButtonLoaderDirective, PreventDoubleSubmitDirective, MinMaxDirective, MandatoryFieldDirective, DivDisableDirective, InfoPromptBoxDirective, ActionCommentComponent, ToggleSwitchComponent, ThousandSeparatorDirective, AddEditTagComponent, FreezeFunctionCommentsDialogComponent, VersionModalComponent, AuthenticationFailedModalComponent, CommonErrorModalComponent, HarvestingAddModalComponent],
    exports: [IconComponent, PreventDoubleSubmitDirective, DecimalDirective,ButtonLoaderDirective, MinMaxDirective, MandatoryFieldDirective, DivDisableDirective, InfoPromptBoxDirective, ActionCommentComponent, ThousandSeparatorDirective],
    imports: [
        CommonModule,
        NgxSelectModule,
        ReactiveFormsModule,
        FormsModule,
        TreeTableModule
    ]
})
export class SharedModule {
}
