import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingPlanDetailsService } from 'src/app/services/harvesting-plan-details.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-harvesting-list-edit',
  templateUrl: './harvesting-list-edit.component.html',
  styleUrls: ['./harvesting-list-edit.component.scss']
})
export class HarvestingListEditComponent {

  harvestingMasterPlanDetailsEditForm:any=[];
  param:any;
  rowData:any;
  refData : any;
  exportUrl:any;
  masterPlanID:any;
  showFormRow:Boolean = false;
  harvestingPlanDetails:any;
  projectToolLength:Number=0;
  loadingBtn:boolean = false;
  disableButton:Boolean = false;
  event: EventEmitter<any> = new EventEmitter();

  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  specieCodeList: any[]=[];
  harvestingYearList: any[]=[];
  filterData = [];

  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private refDataService : RefdataService,
      private urlService : UrlService,
      private harvestingPlanService:HarvestingPlanDetailsService
  ) { }

  ngOnInit(): void {

    console.log("Edit Parameters:", this.rowData);

    this.initFormValues();
    this.masterPlanID = this.param.masterPlanID;

    var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      // console.log(this.refData);

      this.refData.forEach(item => {
        this.specieCodeList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Specie Codes Completed");
    });

    this.estateCodeChanged(this.param.estateCode);
      this.divisionCodeChanged(this.param.divisionCode);
      
    this.generateHarvestingYearList(this.rowData.masterPlanCodeStart, this.rowData.masterPlanCodeEnd);

    // console.log("Data From the DB: ",this.param);

    let hostName = this.urlService.getHostName(window.location);
          // console.log(hostName);
        this.companyCodeList.push(hostName);
        // console.log("Company Code List: ",this.companyCodeList);
        
        // this.harvestingMasterPlanDetailsEditForm.controls["companyCode"].patchValue(hostName);
        // this.harvestingMasterPlanDetailsEditForm.controls["companyCode"].disable();
    

    
    // this.exportUrl = environment.apiUrl + "/projects/"+this.projectNumber+"/tools/export";
  }

  initFormValues(){
    this.harvestingMasterPlanDetailsEditForm = new UntypedFormGroup({
      masterPlanID: new UntypedFormControl(this.param.masterPlanID),
      harvestingPlanID: new UntypedFormControl(this.param.harvestingPlanID),
      companyCode: new UntypedFormControl({ value: this.param.companyCode, disabled: true }),
      estateCode: new UntypedFormControl({ value: this.param.estateCode, disabled: true }),
      divisionCode: new UntypedFormControl({ value: this.param.divisionCode, disabled: true }),
      fieldCode: new UntypedFormControl({ value: this.param.fieldCode, disabled: true }),
      crop: new UntypedFormControl(this.param.crop),
      specieCode: new UntypedFormControl(this.param.specieCode),
      trees: new UntypedFormControl(this.param.trees),
      gbHeight: new UntypedFormControl(this.param.gbHeight),
      volumeCDM: new UntypedFormControl(this.param.volumeCDM),
      mv: new UntypedFormControl(this.param.mv),
      age: new UntypedFormControl(this.param.age),
      extHectare: new UntypedFormControl(this.param.extHectare),
      year: new UntypedFormControl(this.param.year),
      operation: new UntypedFormControl(this.param.operation),
    });
  }

  public currentFormValues()
  {
    return JSON.stringify(this.harvestingMasterPlanDetailsEditForm.getRawValue());
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            // console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            // console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  yearList($event) {
  }

  generateHarvestingYearList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.harvestingYearList.push({ id: year, value: year.toString() });
    }
  }


  updateHarvestingPlanDetails() {
    this.loadingBtn = true;
    let submitData = this.harvestingMasterPlanDetailsEditForm.getRawValue();

    // Edit Record
    this.harvestingPlanService.update(this.masterPlanID, submitData['harvestingPlanID'], submitData).subscribe(res => {
      if (res) {
        this.notificationService.success('Forestry Management Plan Dtail has been Updated', 'Success');
        this.bsModalRef.hide();
        this.event.emit();
        this.showFormRow = true;
      } else {
        this.notificationService.error('Forestry Management Plan Dtail has not been Updated', 'Error');
      }
      this.loadingBtn = false;
    });
  }
  

}
