import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TreeDetailsComponent } from './tree-details/tree-details.component';
import { MatureFieldsComponent } from './mature-fields/mature-fields.component';

const routes: Routes = [
  { path: 'tree-details', component: TreeDetailsComponent },
  { path: 'mature-fields', component: MatureFieldsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MatureStockRoutingModule { }
