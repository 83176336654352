import { formatDate } from '@angular/common';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EstateService } from 'src/app/services/estate.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-estate-add',
  templateUrl: './estate-add.component.html',
  styleUrls: ['./estate-add.component.scss']
})
export class EstateAddComponent {

  model1: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  refData : any;
  companyCodeList: any[]=[];
  postOfficeList: any[]=[];
  nameOfRevenueDistrictList: any[]=[];
  divisionalSecretaryList: any[]=[];
  gramaNiladhariAreaList: any[]=[];
  policeDivisionList: any[]=[];
  policeAreaList: any[]=[];
  pradeshiyaSabaAreaList: any[]=[];


  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  estateAddForm = new UntypedFormGroup({
    companyCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    estateName: new UntypedFormControl('',[Validators.required, Validators.maxLength(50)]),
    address: new UntypedFormControl('',[Validators.maxLength(50)]),
    city: new UntypedFormControl('',[Validators.maxLength(50)]),
    telephoneNumber: new UntypedFormControl('',[Validators.maxLength(25)]),
    emailAddress: new UntypedFormControl('',[Validators.maxLength(50), Validators.email]),
    postOffice: new UntypedFormControl('',[Validators.maxLength(50)]),
    nameOfMainTown: new UntypedFormControl('',[Validators.maxLength(50)]),
    distanceFromMainTown: new UntypedFormControl('',[Validators.maxLength(10)]),
    minimumElevation: new UntypedFormControl('',[Validators.max(2),Validators.min(0)]),
    maximumElevation: new UntypedFormControl('',[Validators.maxLength(4)]),
    nameOfRevenueDistrict: new UntypedFormControl('',[Validators.maxLength(50)]),
    divisionalSecretary: new UntypedFormControl('',[Validators.maxLength(50)]),
    gramaNiladhariArea: new UntypedFormControl('',[Validators.maxLength(50)]),
    policeDivision: new UntypedFormControl('',[Validators.maxLength(50)]),
    policeArea: new UntypedFormControl('',[Validators.maxLength(50)]),
    pradeshiyaSabaArea: new UntypedFormControl('',[Validators.maxLength(50)]),
    nameOfForestOfficer1: new UntypedFormControl('',[Validators.maxLength(50)]),
    nameOfForestOfficer2: new UntypedFormControl('',[Validators.maxLength(50)]),
    dateOfNationalization: new UntypedFormControl(''),
    dateOfPrivatization: new UntypedFormControl(''),
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private estateService: EstateService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) { 
    

  }

  companyCodeChanged(event){
  }

  pradeshiyaSabaAreaChanged($event) {
  }

  policeAreaChanged($event) {
  }

  policeDivisionChanged($event) {
  }

  gramaNiladhariAreaChanged($event) {
  }

  divisionalSecretaryChanged($event) {
  }

  nameOfRevenueDistrictChanged($event) {
  }

  postOfficeChanged($event) {
  }


  ngOnInit(): void {

    this.refDataService.getEstateRefData().subscribe((data)=>{
      this.refData = data;
      
      this.postOfficeList = this.refData.postOffice;
      this.nameOfRevenueDistrictList = this.refData.revDistricts;
      this.divisionalSecretaryList = this.refData.divSecretary;
      this.gramaNiladhariAreaList = this.refData.gnArea;
      this.policeDivisionList = this.refData.policeDivision;
      this.policeAreaList = this.refData.policeArea;
      this.pradeshiyaSabaAreaList = this.refData.psArea;

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.estateAddForm.controls["companyCode"].patchValue(hostName);
      this.estateAddForm.controls["companyCode"].disable();
      

    this.estateAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });

  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }


  createNewEstate(){
    let formData = this.estateAddForm.getRawValue();
    console.log(formData);
    

    formData.dateOfNationalization = this.myformatDate( this.estateAddForm.controls["dateOfNationalization"].value);
    formData.dateOfPrivatization = this.myformatDate( this.estateAddForm.controls["dateOfPrivatization"].value);

    this.loadingBtn = true;
    this.estateService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Estate has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Estate has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Estate already exist', 'Error');
      }
      else {
        this.notification.error('Estate has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }



}
