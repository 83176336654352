import { Component } from '@angular/core';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CompanyAddComponent } from './components/company-add/company-add.component';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyEditComponent } from './components/company-edit/company-edit.component';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  company:any = [];
  rowData:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'companyCode',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:4,
      right:2
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.companyService.getAllCompany("distinct-values", filterData, params)},
    columns:[
      {
        title:'Parent Compnay Code',
        attribute:'parentCompanyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Compnay Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Name of Company',
        attribute: 'companyName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200
      },
      {
        title:'Address',
        attribute: 'address',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200
      },
      {
        title:'City',
        attribute:'city',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Country',
        attribute:'country',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Telephone Number',
        attribute:'telephoneNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Fax Number',
        attribute:'faxNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Email Address',
        attribute:'emailAddress',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Web Address',
        attribute:'webAddress',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Date of Nationalization',
        attribute:'dateOfNationalization',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Date of Incorporation',
        attribute:'dateOfIncorporation',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Business Registration Number',
        attribute:'businessRegistrationNumber',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editCompany(row)},
        width:32
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteCompany(row)},
        width:32,
      }
    ]
  };

  constructor(
    private companyService : CompanyService,
    private activatedRoute : ActivatedRoute,
    private modalService : BsModalService,
    private notificationService : ToastrService

) {
  activatedRoute.queryParams.subscribe(params => {
    if(params['page']) this.currentPage = +params['page'];
    if(params['column']) this.sortColumn = params['column'];
    if(params['sortOrder']) this.sortOrder = params['sortOrder'];
  });
}

ngOnInit(): void {
  this.getAllCompany();
}


loadAddModal() {
  const initialState = {};
    let modalRef = this.modalService.show(CompanyAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllCompany();
    });
}

getAllCompany(){
  this.loading = true;
  let params = {};
  if(this.currentPage) params['page'] = this.currentPage-1;
  if(this.pageSize) params['pageSize'] = this.pageSize;
  if(this.sortColumn) params['column'] = this.sortColumn;
  if(this.sortOrder) params['sortOrder'] = this.sortOrder;
  this.companyService.getAllCompany('filtered-data', this.filterData, params).subscribe(res=>{
    if(res && res['page']){
      this.pageCount = res['page']['totalPages'];
      this.company  = res['page']['content'];
      this.loading = false;
    }
    if(res && res['tablePropertyMap']){
      let columns = this.settings.columns;
      let needFillColumnAndTable = false;
      for(let column of columns){
        if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
      }
      if(!needFillColumnAndTable) return;

      let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
      for(let i=0; i<columns.length; i++){
        let data = tablePropertyMap[columns[i].attribute];
        if(!data) continue;
        columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
        columns[i].filter['table'] = data['table'];
      }
    }
    this.settings['isNative'] = false;
  });
}

filterOrSortChange(event){
  if(!event){
    this.currentPage = 1;
    this.company = [];
    return;
  }
  if(!event['sortChanged']) this.currentPage = 1;
  this.sortColumn = event['sortColumn'];
  this.sortOrder = event['sortOrder'];
  this.filterData = event['filterData'];

  this.getAllCompany();
}

editCompany(data){
  const initialState = {
    param:data
  };
  let modalRef = this.modalService.show(CompanyEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
  modalRef.content.event.subscribe(res => {
    this.getAllCompany();
  });
}

deleteCompany(data){
    
  const initialState = {
    title: 'Are you sure that you want to Delete the Selected Company?',
    okBtnName: 'OK',
    closeBtnName: 'Cancel',
    isCommentVisible: false,
    titleBackground : "bg-warning-emphasized",
  };
  let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
  modalRef.content.event.subscribe(res =>
  {
    if(res?.result?.action == 'ok')
    {
      this.loading = true;
      this.companyService.delete(data.companyCode).subscribe(res=>{
        console.log(res);
        this.loading = false;
        this.notificationService.success('Company has been Deleted successfully!', 'Success');
        this.getAllCompany();
      },error=>{
        console.log(error);
        if(error.status == 200){
          this.notificationService.success('Company has been Deleted successfully!', 'Success');
        }else if(error.status == 412){
          this.notificationService.error('Company Deletion failed due to it\'s being used!', 'Error');
        }else{
          this.notificationService.error('Company Deletion failed!', 'Error');
        }
        
        this.loading = false;
        this.getAllCompany();
      },()=>{
        this.loading = false;
        
      });
      

    }
    else {
      this.loading = false;
      // this.currentSpinnerText = "Loading Project Systems...";
    }; // do nothing
  });
}

changePage(currentPage){
  this.currentPage = currentPage;
  this.getAllCompany();
}

changePageSize(pageSize){
  this.pageSize = pageSize;
  this.getAllCompany();
}

}
