import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MatureFieldsService } from 'src/app/services/mature-fields.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-mature-fields-edit',
  templateUrl: './mature-fields-edit.component.html',
  styleUrls: ['./mature-fields-edit.component.scss']
})
export class MatureFieldsEditComponent {

    roleData:any[] = [];
    refData : any;
    userGroupData:any[] = [];
    initialState:any;
    param:any;


    sqldate: string;
    sqldate2: string;
    
    dtNSurveyObj:Date;
    dtPlantingObj:Date;
  
    event: EventEmitter<any> = new EventEmitter();
    ismatch:Boolean = true;
    loadingBtn : Boolean = false;
    disableButton:Boolean = false;
  
    imageUrl: string | ArrayBuffer | null = null;
    matureFieldsEditForm:any;
    companyCodeList: any[]=[];
    estateCodeList: any[]=[];
    divisionCodeList: any[]=[];
    fieldCodeList: any[]=[];
    specieCodeList: any[]=[];
    immatureFieldCodeList: any[]=[];
    slopeList: any[]=[];
    surveyPlanExists:Boolean = false;
    selectedFile: File | null = null;

    yearOfPlantingList: any[]=[];
    terrainList: any[]=[];
    soilTypeList: any[]=[];
    plantingDistanceList: any[]=[];
    soilDepthList: any[]=[];
    confirmationOfCensesList: any[] =[
      {"id":1,"value":"Yes"},
      {"id":2,"value":"No"}
    ];
  
    constructor(
        public bsModalRef : BsModalRef,
        private notificationService : ToastrService,
        private matureFieldsService: MatureFieldsService,
        private refDataService : RefdataService,
        private urlService: UrlService,
      private modalService : BsModalService
    ) { 
      
    }
  
    ngOnInit(): void {
      this.initFormValues();

      this.generateYearOfPlantingList(1900, 2024);

      var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

      const fieldCode = this.param.matureFieldCode; 
      this.matureFieldsService.getFieldMapImage(fieldCode).subscribe(
      (blob: Blob) => {
        // Create a URL for the blob (image)
        const reader = new FileReader();
        reader.onloadend = () => {
          this.imageUrl = reader.result as string;
        };
        reader.readAsDataURL(blob);
        this.surveyPlanExists = true;
      },
      error => {
        console.error('Error fetching image:', error);
      }
    );

      this.refDataService.getMatureFieldRefData().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.terrainList = this.refData.terrain;
      this.soilTypeList = this.refData.soiltypes;
      this.soilDepthList = this.refData.soildepth;
      this.slopeList = this.refData.slope;

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("RefData Completed");
      });

      this.refDataService.getSpecieCodeList().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);

        this.refData.forEach(item => {
          this.specieCodeList.push({
            id: item.speciesCode, 
            value: `${item.speciesCode} - ${item.description}`
          });
        });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Specie Codes Completed");
      });

      this.refDataService.getDistance().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);
  
        this.refData.forEach(item => {
          this.plantingDistanceList.push(item.distance);
        });
  
      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Planting Distane Completed");
      });

      this.refDataService.getAllImmatureCodes().subscribe((data)=>{
        this.refData = data;
        console.log(this.refData);
  
        this.refData.forEach(item => {
          this.immatureFieldCodeList.push({
            id: item.immatureFieldCode, 
            value: item.fieldCode 
          });
        });
  
      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Immature Fields Completed");
      });

      console.log("Data From the DB: ",this.param);

      this.estateCodeChanged(this.param.estateCode);
      this.divisionCodeChanged(this.param.divisionCode);

      let hostName = this.urlService.getHostName(window.location);
          // console.log(hostName);
        this.companyCodeList.push(hostName);
        this.matureFieldsEditForm.controls["companyCode"].patchValue(hostName);
        this.matureFieldsEditForm.controls["companyCode"].disable();
    }
  
  
   initFormValues(){

    this.sqldate = this.param.dateOfSurvey;
    this.sqldate2 = this.param.dateOfPlanting;

    this.dtNSurveyObj = new Date(this.param.dateOfSurvey);
    this.dtPlantingObj = new Date(this.param.dateOfPlanting);

    // this.imageFileRt = this.matureFieldsService.getFieldMapImage;
    // console.log("This is Image:", this.imageFileRt);
    

    this.matureFieldsEditForm = new UntypedFormGroup(
        {
          matureFieldCode: new UntypedFormControl(this.param.matureFieldCode),
          companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(15)]),
          estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required, Validators.maxLength(15)]),
          divisionCode: new UntypedFormControl(this.param.divisionCode,[Validators.required, Validators.maxLength(15)]),
          fieldCode: new UntypedFormControl(this.param.fieldCode,[Validators.required, Validators.maxLength(15)]),
          specieCode: new UntypedFormControl(this.param.specieCode,[Validators.required]),
          blockNo: new UntypedFormControl(this.param.blockNo,[Validators.required,Validators.maxLength(15)]),
          immatureFieldCode: new UntypedFormControl(this.param.immatureFieldCode,[Validators.maxLength(15)]),
          fieldExtent: new UntypedFormControl(this.param.fieldExtent,[Validators.maxLength(10)]),
          yearofPlanting: new UntypedFormControl(this.param.yearofPlanting,[Validators.maxLength(10)]),
          initialPlants: new UntypedFormControl(this.param.initialPlants,[Validators.maxLength(10)]),
          noPresentPlants: new UntypedFormControl(this.param.noPresentPlants,[Validators.maxLength(10)]),
          terrain: new UntypedFormControl(this.param.terrain,[Validators.maxLength(10)]),
          distanceFromMainRoad: new UntypedFormControl(this.param.distanceFromMainRoad,[Validators.maxLength(10)]),
          slope: new UntypedFormControl(this.param.slope,[Validators.maxLength(10)]),
          soilType: new UntypedFormControl(this.param.soilType,[Validators.maxLength(10)]),
          soilDepth: new UntypedFormControl(this.param.soilDepth,[Validators.maxLength(10)]),
          elevationInMeters: new UntypedFormControl(this.param.elevationInMeters,[Validators.maxLength(8)]),
          surveyPlanNo: new UntypedFormControl(this.param.surveyPlanNo,[Validators.maxLength(10)]),
          dateOfSurvey: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate)),
          surveyPlanJPGTag: new UntypedFormControl(''),
          fundedBy: new UntypedFormControl(this.param.fundedBy,[Validators.maxLength(10)]),
          plantedByName1: new UntypedFormControl(this.param.plantedByName1,[Validators.maxLength(10)]),
          plantedByName2: new UntypedFormControl(this.param.plantedByName2,[Validators.maxLength(10)]),
          plantedByName3: new UntypedFormControl(this.param.plantedByName3,[Validators.maxLength(10)]),
          plantingDistance: new UntypedFormControl(this.param.plantingDistance,[Validators.maxLength(10)]),
          dateOfPlanting: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate2)),
          comments: new UntypedFormControl(this.param.comments,[Validators.maxLength(10)]),
          noPlantsConfirmed: new UntypedFormControl(this.param.noPlantsConfirmed,[Validators.maxLength(10)]),
          confirmationOfCenses: new UntypedFormControl(this.param.confirmationOfCenses,[Validators.maxLength(10)])
        }
      );
        
      validateForm(this.matureFieldsEditForm);
  
    }

    convertToNgbDateStruct(dateString: string): NgbDateStruct | null {
      if (!dateString) {
        return null;
      }
      const parts = dateString.split('-');
      return {
        year: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        day: parseInt(parts[2], 10)
      };
    }
  
    companyCodeChanged(event){
    }
  
    estateCodeChanged(estateCode: any) {
      console.log('Estate Code changed:', estateCode);
    
      this.refDataService.getDivisionsByEstateCode(estateCode)
          .subscribe(
            (response) => {
              var divisions: any = response;
              this.divisionCodeList = [];
              divisions.forEach(division => {
                this.divisionCodeList.push(division.divisionCode);
              });
              console.log('Response:', divisions);
            },
            (error) => {
              // Handle error if needed
              console.error('Error:', error);
            }
          );
    }
  
    fieldCodeChanged($event) {
    }
  
    divisionCodeChanged(divisionCode : any) {
      console.log('Division Code changed:', divisionCode);
    
      this.refDataService.getFieldsByDivisionCode(divisionCode)
          .subscribe(
            (response) => {
              var fields: any = response;
              this.fieldCodeList = [];
              fields.forEach(fields => {
                this.fieldCodeList.push(fields.fieldCode);
              });
              console.log('Response:', fields);
            },
            (error) => {
              // Handle error if needed
              console.error('Error:', error);
            }
          );
    }
  
    specieCodeChanged($event) {
    }

    previousImmatureFieldCodeChanged($event){
    }
  
    yearOfPlantingChanged($event) {
    }
  
    terrainChanged($event) {
    }
  
    soilTypeChanged($event) {
    }
  
    soilDepthChanged($event) {
    }

    plantingDistanceChanged($event){
    }
  
    confirmationOfCensesChanged($event) {
    }

    slopeChanged($event){
    }

    onFileSelected(event: any) {
      this.selectedFile = event.target.files[0] as File;
      console.log("Selected File:",this.selectedFile);
      
    }

    generateYearOfPlantingList(startYear: number, endYear: number): void {
      for (let year = startYear; year <= endYear; year++) {
        this.yearOfPlantingList.push({ id: year - startYear + 1, value: year.toString() });
      }
    }

    myformatDate(date: NgbDateStruct): string {
      if (!date) {
        return '';
      }
      const year = date.year;
      const month = date.month < 10 ? `0${date.month}` : date.month;
      const day = date.day < 10 ? `0${date.day}` : date.day;
      return `${year}-${month}-${day}`;
    }

    deleteImage(event: MouseEvent): void {
      event.preventDefault();
      const initialState = {
        title: 'Are you sure that you want to delete the image?',
        okBtnName: 'OK',
        closeBtnName: 'Cancel',
        isCommentVisible: false,
        titleBackground: "bg-warning-emphasized",
      };
    
      let modalRef = this.modalService.show(ConfirmationBoxComponent, { class: 'modal-md', initialState, ignoreBackdropClick: true });
    
      const modalSub = modalRef.content.event.subscribe((res: any) => {
        if (res?.result?.action === 'ok') {
          if (!this.param.matureFieldCode) {
            this.notificationService.error('Invalid mature field code!', 'Error');
            this.surveyPlanExists = false;
            return;
          }
    
          this.matureFieldsService.deleteFieldMapImage(this.param.matureFieldCode).subscribe(
            (res: any) => {
              console.log(res);
              this.surveyPlanExists = false;
              this.notificationService.success('Mature Field Image has been deleted successfully!', 'Success');
            },
            (error: any) => {
              console.log(error);
              if (error.status === 200) {
                this.notificationService.success('Mature Field Image has been deleted successfully!', 'Success');
              } else if (error.status === 412) {
                this.notificationService.error('Mature Field Image deletion failed due to it being used!', 'Error');
              } else {
                this.notificationService.error('Mature Field Image deletion failed!', 'Error');
              }
              this.surveyPlanExists = false;
            },
            () => {
              this.surveyPlanExists = false;
            }
          );
        } else {
          this.surveyPlanExists = true;
        }
        modalSub.unsubscribe();
      });
    }

    updateMatureField(event) {
      // if (this.matureFieldsEditForm.valid) {
  
        this.loadingBtn = true;
        let matureFieldCode = this.param.matureFieldCode;
        let submitData = this.matureFieldsEditForm.getRawValue();

        submitData.dateOfSurvey = this.myformatDate( this.matureFieldsEditForm.controls["dateOfSurvey"].value);
        submitData.dateOfPlanting = this.myformatDate( this.matureFieldsEditForm.controls["dateOfPlanting"].value);

        const uploadData = new FormData();
        uploadData.append('matureFieldCode', submitData.matureFieldCode);
        uploadData.append('companyCode', submitData.companyCode);
        uploadData.append('estateCode', submitData.estateCode);
        uploadData.append('divisionCode', submitData.divisionCode);
        uploadData.append('fieldCode', submitData.fieldCode);
        uploadData.append('specieCode', submitData.specieCode);
        uploadData.append('blockNo', submitData.blockNo);

        // uploadData.append('immatureFieldCode', submitData.immatureFieldCode);
        if (submitData.immatureFieldCode) {
          uploadData.append('immatureFieldCode', submitData.immatureFieldCode);
        }

        // uploadData.append('fieldExtent', submitData.fieldExtent);
        if (submitData.fieldExtent) {
          uploadData.append('fieldExtent', submitData.fieldExtent);
        }

        // uploadData.append('yearofPlanting', submitData.yearofPlanting);
        if (submitData.yearofPlanting) {
          uploadData.append('yearofPlanting', submitData.yearofPlanting);
        }

        // uploadData.append('initialPlants', submitData.initialPlants);
        if (submitData.initialPlants) {
          uploadData.append('initialPlants', submitData.initialPlants);
        }

        // uploadData.append('noPresentPlants', submitData.noPresentPlants);
        if (submitData.noPresentPlants) {
          uploadData.append('noPresentPlants', submitData.noPresentPlants);
        }

        // uploadData.append('terrain', submitData.terrain);
        if (submitData.terrain) {
          uploadData.append('terrain', submitData.terrain);
        }

        // uploadData.append('distanceFromMainRoad', submitData.distanceFromMainRoad);
        if (submitData.distanceFromMainRoad) {
          uploadData.append('distanceFromMainRoad', submitData.distanceFromMainRoad);
        }

        // uploadData.append('slope', submitData.slope);
        if (submitData.slope) {
          uploadData.append('slope', submitData.slope);
        }

        // uploadData.append('soilType', submitData.soilType);
        if (submitData.soilType) {
          uploadData.append('soilType', submitData.soilType);
        }

        // uploadData.append('soilDepth', submitData.soilDepth);
        if (submitData.soilDepth) {
          uploadData.append('soilDepth', submitData.soilDepth);
        }

        // uploadData.append('elevationInMeters', submitData.elevationInMeters);
        if (submitData.elevationInMeters) {
          uploadData.append('elevationInMeters', submitData.elevationInMeters);
        }

        uploadData.append('surveyPlanNo', submitData.surveyPlanNo);
        // if (submitData.surveyPlanNo) {
        //   uploadData.append('surveyPlanNo', submitData.surveyPlanNo);
        // }

        // uploadData.append('dateOfSurvey', submitData.dateOfSurvey);
        if (submitData.dateOfSurvey) {
          uploadData.append('dateOfSurvey', submitData.dateOfSurvey);
        }

        uploadData.append('fundedBy', submitData.fundedBy);
        uploadData.append('plantedByName1', submitData.plantedByName1);
        uploadData.append('plantedByName2', submitData.plantedByName2);
        uploadData.append('plantedByName3', submitData.plantedByName3);

        // uploadData.append('plantingDistance', submitData.plantingDistance);
        if (submitData.plantingDistance) {
          uploadData.append('plantingDistance', submitData.plantingDistance);
        }

        // uploadData.append('dateOfPlanting', submitData.dateOfPlanting);
        if (submitData.dateOfPlanting) {
          uploadData.append('dateOfPlanting', submitData.dateOfPlanting);
        }

        uploadData.append('comments', submitData.comments);

        // uploadData.append('noPlantsConfirmed', submitData.noPlantsConfirmed);
        if (submitData.noPlantsConfirmed) {
          uploadData.append('noPlantsConfirmed', submitData.noPlantsConfirmed);
        }

        // uploadData.append('confirmationOfCenses', submitData.confirmationOfCenses);
        if (submitData.confirmationOfCenses) {
          uploadData.append('confirmationOfCenses', submitData.confirmationOfCenses);
        }

        // uploadData.append('surveyPlanJPGTag', this.selectedFile);
        if (this.selectedFile && !this.surveyPlanExists) {
        uploadData.append('surveyPlanJPGTag', this.selectedFile);
        }
        //<<-------------------------------------------------------------------------------------------->>
        
        this.matureFieldsService.update(matureFieldCode, uploadData).subscribe(res => {
          if (res) {
            this.loadingBtn = false;
            this.notificationService.success('Mature Field has been updated', 'Success');
            this.bsModalRef.hide();
              this.event.emit();
          } else {
            this.loadingBtn = false;
            this.notificationService.error('Mature Field has not been updated', 'Error');
          }
          
        }, (error => {
          this.loadingBtn = false;
          this.notificationService.error('Mature Field has not been updated', 'Error');
          
        }), () => {
          this.loadingBtn = false;
        });
      // } else {
      //   validateForm(this.matureFieldsEditForm);
      // }
    }
  
}
