<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Mature Field</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  class="p-3"
  [formGroup]="matureFieldsEditForm"
  (ngSubmit)="updateMatureField(event)"
>
  <div class="modal-body-edit-mature-field">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['companyCode'].invalid &&
            (matureFieldsEditForm.controls['companyCode'].dirty ||
              matureFieldsEditForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['companyCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="estateCodeList"
          placeholder="Estate Code"
          formControlName="estateCode"
          (select)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['estateCode'].invalid &&
            (matureFieldsEditForm.controls['estateCode'].dirty ||
              matureFieldsEditForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['estateCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="divisionCodeList"
          placeholder="Division Code"
          formControlName="divisionCode"
          (select)="divisionCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'divisionCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['divisionCode'].invalid &&
            (matureFieldsEditForm.controls['divisionCode'].dirty ||
              matureFieldsEditForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['divisionCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="fieldCodeList"
          placeholder="Field Code"
          formControlName="fieldCode"
          (select)="fieldCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'fieldCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['fieldCode'].invalid &&
            (matureFieldsEditForm.controls['fieldCode'].dirty ||
              matureFieldsEditForm.controls['fieldCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['fieldCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Specie Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="specieCodeList"
          placeholder="Specie Code"
          formControlName="specieCode"
          (select)="specieCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'specieCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['specieCode'].invalid &&
            (matureFieldsEditForm.controls['specieCode'].dirty ||
              matureFieldsEditForm.controls['specieCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['specieCode'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="matureFieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Mature Field Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          class="form-control"
          formControlName="matureFieldCode"
          placeholder="Mature Field Code"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'matureFieldCode'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['matureFieldCode'].invalid &&
            (matureFieldsEditForm.controls['matureFieldCode'].dirty ||
              matureFieldsEditForm.controls['matureFieldCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['matureFieldCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div> -->

    <div class="form-group row">
      <label for="blockNo" class="col-sm-4 col-form-label col-form-label-sm"
        >Block Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="15"
          class="form-control"
          formControlName="blockNo"
          placeholder="Block Number"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'blockNo'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['blockNo'].invalid &&
            (matureFieldsEditForm.controls['blockNo'].dirty ||
              matureFieldsEditForm.controls['blockNo'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['blockNo'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="immatureFieldCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Previous Immature Field Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="immatureFieldCodeList"
          placeholder="Previous Immature Field Code"
          formControlName="immatureFieldCode"
          (select)="previousImmatureFieldCodeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'immatureFieldCode'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['immatureFieldCode'].invalid &&
            (matureFieldsEditForm.controls['immatureFieldCode'].dirty ||
              matureFieldsEditForm.controls['immatureFieldCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['immatureFieldCode'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fieldExtent" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="fieldExtent"
          placeholder="Field Extent"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'fieldExtent'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['fieldExtent'].invalid &&
            (matureFieldsEditForm.controls['fieldExtent'].dirty ||
              matureFieldsEditForm.controls['fieldExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['fieldExtent'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="yearofPlanting"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Year of Planting
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="yearOfPlantingList"
          placeholder="Year of Planting"
          formControlName="yearofPlanting"
          (select)="yearOfPlantingChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'yearofPlanting'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['yearofPlanting'].invalid &&
            (matureFieldsEditForm.controls['yearofPlanting'].dirty ||
              matureFieldsEditForm.controls['yearofPlanting'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['yearofPlanting'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="initialPlants"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Initial Plants</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="initialPlants"
          placeholder="Initial Plants"
          appInfoPromptBox
          [page]="'matured-fields'"
          [component]="'add'"
          [field]="'initialPlants'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['initialPlants'].invalid &&
            (matureFieldsEditForm.controls['initialPlants'].dirty ||
              matureFieldsEditForm.controls['initialPlants'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['initialPlants'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="noPresentPlants"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants at present</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="noPresentPlants"
          placeholder="No of plants at present"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'noPresentPlants'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['noPresentPlants'].invalid &&
            (matureFieldsEditForm.controls['noPresentPlants'].dirty ||
              matureFieldsEditForm.controls['noPresentPlants'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['noPresentPlants'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="terrain" class="col-sm-4 col-form-label col-form-label-sm"
        >Terrain
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="terrainList"
          placeholder="Terrain"
          formControlName="terrain"
          (select)="terrainChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'terrain'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['terrain'].invalid &&
            (matureFieldsEditForm.controls['terrain'].dirty ||
              matureFieldsEditForm.controls['terrain'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['terrain'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="distanceFromMainRoad"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Distance from Main road</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="distanceFromMainRoad"
          placeholder="Distance from Main road"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'distanceFromMainRoad'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['distanceFromMainRoad'].invalid &&
            (matureFieldsEditForm.controls['distanceFromMainRoad'].dirty ||
              matureFieldsEditForm.controls['distanceFromMainRoad'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['distanceFromMainRoad'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="slope" class="col-sm-4 col-form-label col-form-label-sm"
        >Slope
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="slopeList"
          placeholder="slope"
          formControlName="slope"
          (select)="slopeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'slope'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['slope'].invalid &&
            (matureFieldsEditForm.controls['slope'].dirty ||
              matureFieldsEditForm.controls['slope'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['terrain'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="soilType" class="col-sm-4 col-form-label col-form-label-sm"
        >Soil Type
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="soilTypeList"
          placeholder="Soil Type"
          formControlName="soilType"
          (select)="soilTypeChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'soilType'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['soilType'].invalid &&
            (matureFieldsEditForm.controls['soilType'].dirty ||
              matureFieldsEditForm.controls['soilType'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['soilType'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="soilDepth" class="col-sm-4 col-form-label col-form-label-sm"
        >Soil Depth
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="soilDepthList"
          placeholder="Soil Depth"
          formControlName="soilDepth"
          (select)="soilDepthChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'soilDepth'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['soilDepth'].invalid &&
            (matureFieldsEditForm.controls['soilDepth'].dirty ||
              matureFieldsEditForm.controls['soilDepth'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['soilDepth'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="elevationInMeters"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Elevation in Meters</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="elevationInMeters"
          placeholder="Elevation in Meters"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'elevationInMeters'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['elevationInMeters'].invalid &&
            (matureFieldsEditForm.controls['elevationInMeters'].dirty ||
              matureFieldsEditForm.controls['elevationInMeters'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['elevationInMeters'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="surveyPlanNo"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Survey Plan Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="surveyPlanNo"
          placeholder="Survey Plan Number"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'surveyPlanNo'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['surveyPlanNo'].invalid &&
            (matureFieldsEditForm.controls['surveyPlanNo'].dirty ||
              matureFieldsEditForm.controls['surveyPlanNo'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="matureFieldsEditForm.controls['surveyPlanNo'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfSurvey"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Survey</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d1"
            formControlName="dateOfSurvey"
            ngbDatepicker
            #d1="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <!-- <div class="imageFile" *ngIf="imageUrl" style="position: relative;">
      <button class="btn btn-danger btn-sm delete-button" style="position: absolute; top : 5px; right:5px;" (click)="deleteImage()">
        <img src="/assets/icon/delete.png" alt="Delete">
      </button>
      <img [src]="imageUrl" alt="Survey Plan Image Rt">
    </div> -->
    <div *ngIf="surveyPlanExists" class="form-group row">
      <label for="imageFile" class="col-sm-4 col-form-label col-form-label-sm" > 
        Survey Plan Image
      </label>
      <div class="col-sm-8">
        <div class="imageFile" *ngIf="imageUrl">
          <button class="btn btn-danger btn-sm delete-button" (click)="deleteImage($event)">
            <img src="/assets/icon/delete.png" alt="Delete">
          </button>
          <img [src]="imageUrl" alt="Survey Plan Image">
        </div>
      </div>
    </div>
    

    <div *ngIf="!surveyPlanExists" class="form-group row">
      <label for="" class="col-sm-4 col-form-label col-form-label-sm">
        Survey Plan Image
      </label>
      <div class="col-sm-8">
        <input
          type="file"
          class="form-control"
          id="surveyPlanJPGTag"
          formControlName="surveyPlanJPGTag"
          accept="image/*"
          appMandatoryField
          appInfoPromptBox
          (change)="onFileSelected($event)"

        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['surveyPlanJPGTag'].invalid &&
            (matureFieldsEditForm.controls['surveyPlanJPGTag'].dirty ||
              matureFieldsEditForm.controls['surveyPlanJPGTag'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['surveyPlanJPGTag'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="fundedBy" class="col-sm-4 col-form-label col-form-label-sm"
        >Funded by</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="fundedBy"
          placeholder="Funded by"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'fundedBy'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['fundedBy'].invalid &&
            (matureFieldsEditForm.controls['fundedBy'].dirty ||
              matureFieldsEditForm.controls['fundedBy'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['fundedBy'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName1"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 1</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName1"
          placeholder="Planted by - Name 1"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'plantedByName1'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['plantedByName1'].invalid &&
            (matureFieldsEditForm.controls['plantedByName1'].dirty ||
              matureFieldsEditForm.controls['plantedByName1'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['plantedByName1'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName2"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 2</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName2"
          placeholder="Planted by - Name 2"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'plantedByName2'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['plantedByName2'].invalid &&
            (matureFieldsEditForm.controls['plantedByName2'].dirty ||
              matureFieldsEditForm.controls['plantedByName2'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['plantedByName2'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantedByName3"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planted by - Name 3</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="plantedByName3"
          placeholder="Planted by - Name 3"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'plantedByName3'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['plantedByName3'].invalid &&
            (matureFieldsEditForm.controls['plantedByName3'].dirty ||
              matureFieldsEditForm.controls['plantedByName3'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['plantedByName3'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="plantingDistance"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Planting Distance
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="plantingDistanceList"
          placeholder="Planting Distance"
          formControlName="plantingDistance"
          (select)="plantingDistanceChanged($event)"
          appInfoPromptBox
          [page]="'immature-field'"
          [component]="'add'"
          [field]="'plantingDistance'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['plantingDistance'].invalid &&
            (matureFieldsEditForm.controls['plantingDistance'].dirty ||
              matureFieldsEditForm.controls['plantingDistance'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['plantingDistance'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfPlanting"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Planting</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d2"
            formControlName="dateOfPlanting"
            ngbDatepicker
            #d2="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d2.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="comments" class="col-sm-4 col-form-label col-form-label-sm"
        >Comments / Remarks</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="200"
          class="form-control"
          formControlName="comments"
          placeholder="Comments / Remarks"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'comments'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['comments'].invalid &&
            (matureFieldsEditForm.controls['comments'].dirty ||
              matureFieldsEditForm.controls['comments'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="matureFieldsEditForm.controls['comments'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="noPlantsConfirmed"
        class="col-sm-4 col-form-label col-form-label-sm"
        >No of plants confirmed</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="noPlantsConfirmed"
          placeholder="No of plants confirmed"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'noPlantsConfirmed'"
        />
        <div
          *ngIf="
            matureFieldsEditForm.controls['noPlantsConfirmed'].invalid &&
            (matureFieldsEditForm.controls['noPlantsConfirmed'].dirty ||
              matureFieldsEditForm.controls['noPlantsConfirmed'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['noPlantsConfirmed'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="confirmationOfCenses"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Confirmation of Censes
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="confirmationOfCensesList"
          placeholder="Cofirmation of Censes"
          formControlName="confirmationOfCenses"
          (select)="confirmationOfCensesChanged($event)"
          appInfoPromptBox
          [page]="'mature-field'"
          [component]="'add'"
          [field]="'confirmationOfCenses'"
        ></ngx-select>
        <div
          *ngIf="
            matureFieldsEditForm.controls['confirmationOfCenses'].invalid &&
            (matureFieldsEditForm.controls['confirmationOfCenses'].dirty ||
              matureFieldsEditForm.controls['confirmationOfCenses'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              matureFieldsEditForm.controls['confirmationOfCenses'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
