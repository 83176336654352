import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HarvestingPlanDetailsService {

  constructor(private http: HttpClient) { }

    // getAllHarvestingPlan(apiType = 'filtered-data', data = [], params = null)
    // {
    //     return this.http.post(environment.apiUrl + "/harvesting-detail-plan/" + apiType, data, {params: params});
    // }

    // getHarvestingPlanDetailsByMasterPlanID(masterPlanID, apiType = 'filtered-data', data = [], params = null)
    // {
    //     return this.http.get(environment.apiUrl + "/harvesting-detail-plan/" + masterPlanID + "/detail-list/" )
    // }

    getHarvestingPlanDetailsByHarvestingPlanID(masterPlanID,harvestingPlanID)
    {
        return this.http.get(environment.apiUrl + "/harvesting-plan-details/" + masterPlanID + "/detail-list/" + harvestingPlanID + "/")
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan-details/" , data);
    }

    update(masterPlanID,harvestingPlanID,data)
    {
        return this.http.put(environment.apiUrl + "/harvesting-plan-details/" + masterPlanID + "/detail-list/" + harvestingPlanID + "/", data);
    }

    getHarvestingPlanDetailsByMasterPlanID(masterPlanID, apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan-details/" + masterPlanID + "/detail-list/" + apiType, data, {params: params});
    }

    delete(harvestingPlanID)
    {
        if (!harvestingPlanID) return;
        return this.http.delete(environment.apiUrl + "/harvesting-plan-details/" + harvestingPlanID);
    }

    //---------------------------------------------------------------------------------------------------------------------------------------------------------


    // getAllHarvestingPlanDetails(apiType = 'filtered-data', data = [], params = null)
    // {
    //     return this.http.post(environment.apiUrl + "/harvesting-plan-details/" + apiType, data, {params: params});
    // }

    // harvestingPlanDetailCreate(data)
    // {
    //     return this.http.post(environment.apiUrl + "/harvesting-plan-details/", data);
    // }

    // harvestingPlanDetailsUpdate(harvestingPlanID,data)
    // {
    //   return this.http.put(environment.apiUrl + "/harvesting-plan-details/" + harvestingPlanID, data);
    // }

    // delete(harvestingPlanID)
    // {
    //     if (!harvestingPlanID) return;
    //     return this.http.delete(environment.apiUrl + "/harvesting-plan-details/" + harvestingPlanID);
    // }


}