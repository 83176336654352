import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-harvesting-plan-edit',
  templateUrl: './harvesting-plan-edit.component.html',
  styleUrls: ['./harvesting-plan-edit.component.scss']
})
export class HarvestingPlanEditComponent {

  param:any;
  refData : any;
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  planYearList: any[]=[];
  harvestingPlanEditForm:any=[];
  harvestingMonthsList: any[]=[];
  divisionByCompanyCodeList: any[]=[];
  filterData = [];
  harvestigType: any[]=[
    { id: 1, value: 'Harvest' },
    { id: 2, value: 'Thinning-Out' }
  ];
  workFlowStatusList: any[] = [
    { id: 1, value: 'Step One' },
    { id: 2, value: 'Step Two' },
    { id: 3, value: 'Step Three' }
  ];
  planMonthList: any[] = [
    { id: 1, value: 'January' },
    { id: 2, value: 'February' },
    { id: 3, value: 'March' },
    { id: 4, value: 'April' },
    { id: 5, value: 'May' },
    { id: 6, value: 'June' },
    { id: 7, value: 'July' },
    { id: 8, value: 'August' },
    { id: 9, value: 'September' },
    { id: 10, value: 'October' },
    { id: 11, value: 'November' },
    { id: 12, value: 'December' }
  ];
  statusList: any[] = [
    { id: 1, value: 'Active' },
    { id: 2, value: 'Inactive' }
  ];

  showFormRow:Boolean = false;
  disableButton:Boolean = false;
  event: EventEmitter<any> = new EventEmitter();
  loadingBtn:boolean = false;


  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private refDataService : RefdataService,
      private urlService : UrlService,
      private harvestingPlanService:HarvestingPlanService,
      private harvestingMasterPlanService:HarvestingMasterPlanService
  ) { }

  ngOnInit(): void {

    this.initFormValues();

    var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

    this.estateCodeChanged(this.param.estateCode);
    this.divisionCodeChanged(this.param.divisionCode);
      
    this.generateHarvestingYearList(1980, 2050);

    console.log("Data From the DB: ",this.param);

    let hostName = this.urlService.getHostName(window.location);
      console.log(hostName);
    this.companyCodeList.push(hostName);
    console.log("Company Code List: ",this.companyCodeList);
        
    this.harvestingPlanEditForm.controls["companyCode"].patchValue(hostName);
    this.harvestingPlanEditForm.controls["companyCode"].disable();

    this.refDataService.getDivisionsByCompanyCode(companyCode).subscribe((data)=>{
      this.refData = data;
      console.log("Div Codes ",this.refData);

      this.refData.forEach(item => {
        this.divisionByCompanyCodeList.push(item.divisionCode);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Div Codes Completed");
    });

    // this.refDataService.getAllWorkFlowStatus().subscribe((data)=>{
    //   this.refData = data;
    //   console.log("Work-Flow",this.refData);

    //   this.refData.forEach(item => {
    //     this.workFlowStatusList.push({
    //       id: item.harvestingWorkFlowId, 
    //       value: item.workFlowStatus
    //     });
    //   });

    // },error=>{
    //   console.log("Error Occurred");
    //   console.log(error)
    // },()=>{
    //   console.log("Specie Codes Completed");
    // });
    
  }

  initFormValues(){
    this.harvestingPlanEditForm = new UntypedFormGroup({
      companyCode: new UntypedFormControl(this.param.companyCode),
      divisionCode: new UntypedFormControl(this.param.divisionCode),
      hpShortCode: new UntypedFormControl(this.param.hpShortCode),
      planYear: new UntypedFormControl(this.param.planYear),
      planMonth: new UntypedFormControl(this.param.planMonth),
      description: new UntypedFormControl(this.param.description),
      status: new UntypedFormControl(this.param.status),
      workFlowStatus: new UntypedFormControl(this.param.workFlowStatus),
      harvestingType: new UntypedFormControl(this.param.harvestingType)
    });
  }

  public currentFormValues()
  {
    return JSON.stringify(this.harvestingPlanEditForm.getRawValue());
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  planYearsList($event) {
  }

  planMonthsList($event) {
  }

  selectWorkFlowStatusList($event) {
  }

  selectStatusList($event) {
  }

  harvestigTypeChanged($event) {
  }

  generateHarvestingYearList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.planYearList.push({ id: year, value: year.toString() });
    }
  }

  updateHarvestingPlanRecord(event) {
    if (this.harvestingPlanEditForm.valid) {
      let harvestingPlanID = this.param.harvestingPlanId;
      console.log("Harvesting Plan ID",harvestingPlanID);
      
      this.loadingBtn = true;
      let submitData = this.harvestingPlanEditForm.getRawValue();
      // submitData.status = 1;
      // submitData.workFlowStatus = 1;
      console.log("submit Data",submitData);
      
      this.harvestingPlanService.harvestingPlanDetailsUpdate(harvestingPlanID, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Harvesting Plan has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Harvesting Plan has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Harvesting Plan has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.harvestingPlanEditForm);
    }
  }

}