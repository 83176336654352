import { formatDate } from '@angular/common';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MatureFieldsService } from 'src/app/services/mature-fields.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-mature-fields-add',
  templateUrl: './mature-fields-add.component.html',
  styleUrls: ['./mature-fields-add.component.scss']
})
export class MatureFieldsAddComponent {

  param:any;
  refData : any;
  model1: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  specieCodeList: any[]=[];
  immatureFieldCodeList: any[]=[];
  selectedFile: File | null = null;
  yearOfPlantingList: any[]=[];
  terrainList: any[]=[];
  soilTypeList: any[]=[];
  soilDepthList: any[]=[];
  slopeList: any[]=[];
  plantingDistanceList: any[]=[];
  confirmationOfCensesList: any[] =[
    {"id":1,"value":"Yes"},
    {"id":2,"value":"No"}
  ];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  matureFieldsAddForm = new UntypedFormGroup({
    matureFieldCode: new UntypedFormControl(''),
    companyCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    divisionCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    fieldCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    specieCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    blockNo: new UntypedFormControl('',[Validators.required,Validators.maxLength(15)]),
    immatureFieldCode: new UntypedFormControl('',[Validators.maxLength(15)]),
    fieldExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    yearofPlanting: new UntypedFormControl('',[Validators.maxLength(10)]),
    initialPlants: new UntypedFormControl('',[Validators.maxLength(10)]),
    noPresentPlants: new UntypedFormControl('',[Validators.maxLength(10)]),
    terrain: new UntypedFormControl('',[Validators.maxLength(10)]),
    distanceFromMainRoad: new UntypedFormControl('',[Validators.maxLength(10)]),
    slope: new UntypedFormControl('',[Validators.maxLength(10)]),
    soilType: new UntypedFormControl('',[Validators.maxLength(10)]),
    soilDepth: new UntypedFormControl('',[Validators.maxLength(10)]),
    elevationInMeters: new UntypedFormControl('',[Validators.maxLength(8)]),
    surveyPlanNo: new UntypedFormControl('',[Validators.maxLength(10)]),
    dateOfSurvey: new UntypedFormControl(''),
    surveyPlanJPGTag: new UntypedFormControl(''),
    fundedBy: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName1: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName2: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName3: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantingDistance: new UntypedFormControl('',[Validators.maxLength(10)]),
    dateOfPlanting: new UntypedFormControl(''),
    comments: new UntypedFormControl('',[Validators.maxLength(10)]),
    noPlantsConfirmed: new UntypedFormControl('',[Validators.maxLength(10)]),
    confirmationOfCenses: new UntypedFormControl('',[Validators.maxLength(10)])
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private matureFieldsService : MatureFieldsService,
      private refDataService : RefdataService,
      private urlService: UrlService
  ) { 
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  previousImmatureFieldCodeChanged($event){
  }

  yearOfPlantingChanged($event) {
  }

  terrainChanged($event) {
  }

  soilTypeChanged($event) {
  }

  soilDepthChanged($event) {
  }

  slopeChanged($event) {
  }

  plantingDistanceChanged($event){
  }

  confirmationOfCensesChanged($event) {
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0] as File;
    console.log("Selected File:",this.selectedFile);
    
  }

  generateYearOfPlantingList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.yearOfPlantingList.push({ id: year - startYear + 1, value: year.toString() });
    }
  }

  ngOnInit(): void {

    this.generateYearOfPlantingList(1900, 2024);

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Estates Completed");
    });

    this.refDataService.getMatureFieldRefData().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.terrainList = this.refData.terrain;
      this.soilTypeList = this.refData.soiltypes;
      this.soilDepthList = this.refData.soildepth;
      this.slopeList = this.refData.slope;

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("RefData Completed");
    });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.specieCodeList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Specie Codes Completed");
    });

    this.refDataService.getDistance().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.plantingDistanceList.push(item.distance);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Planting Distane Completed");
    });

    this.refDataService.getAllImmatureCodes().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.immatureFieldCodeList.push({
          id: item.immatureFieldCode, 
          value: item.fieldCode 
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Immature Fields Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.matureFieldsAddForm.controls["companyCode"].patchValue(hostName);
      this.matureFieldsAddForm.controls["companyCode"].disable();

    this.matureFieldsAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }

  createNewMatureField(){

    // if (!this.selectedFile) {
    //   console.log('No file selected');
    //   return;
    // }

    console.log('Selected file:', this.selectedFile);

    let formData = this.matureFieldsAddForm.getRawValue();

    // console.log("Form Company Code:",formData.companyCode);
    
    formData.dateOfSurvey = this.myformatDate( this.matureFieldsAddForm.controls["dateOfSurvey"].value);
    formData.dateOfPlanting = this.myformatDate( this.matureFieldsAddForm.controls["dateOfPlanting"].value);

    const uploadData = new FormData();
    uploadData.append('companyCode', formData.companyCode);
    uploadData.append('estateCode', formData.estateCode);
    uploadData.append('divisionCode', formData.divisionCode);
    uploadData.append('fieldCode', formData.fieldCode);
    uploadData.append('specieCode', formData.specieCode);
    uploadData.append('blockNo', formData.blockNo);
    if (formData.immatureFieldCode) {
      uploadData.append('immatureFieldCode', formData.immatureFieldCode);
    }

    // uploadData.append('fieldExtent', formData.fieldExtent);
    if (formData.fieldExtent) {
      uploadData.append('fieldExtent', formData.fieldExtent);
    }

    // uploadData.append('yearofPlanting', formData.yearofPlanting);
    if (formData.yearofPlanting) {
      uploadData.append('yearofPlanting', formData.yearofPlanting);
    }

    // uploadData.append('initialPlants', formData.initialPlants);
    if (formData.initialPlants) {
      uploadData.append('initialPlants', formData.initialPlants);
    }

    // uploadData.append('noPresentPlants', formData.noPresentPlants);
    if (formData.noPresentPlants) {
      uploadData.append('noPresentPlants', formData.noPresentPlants);
    }

    // uploadData.append('terrain', formData.terrain);
    if (formData.terrain) {
      uploadData.append('terrain', formData.terrain);
    }

    // uploadData.append('distanceFromMainRoad', formData.distanceFromMainRoad);
    if (formData.distanceFromMainRoad) {
      uploadData.append('distanceFromMainRoad', formData.distanceFromMainRoad);
    }

    // uploadData.append('slope', formData.slope);
    if (formData.slope) {
      uploadData.append('slope', formData.slope);
    }

    // uploadData.append('soilType', formData.soilType);
    if (formData.soilType) {
      uploadData.append('soilType', formData.soilType);
    }

    // uploadData.append('soilDepth', formData.soilDepth);
    if (formData.soilDepth) {
      uploadData.append('soilDepth', formData.soilDepth);
    }

    // uploadData.append('elevationInMeters', formData.elevationInMeters);
    if (formData.elevationInMeters) {
      uploadData.append('elevationInMeters', formData.elevationInMeters);
    }

    uploadData.append('surveyPlanNo', formData.surveyPlanNo);
    // if (formData.surveyPlanNo) {
    //   uploadData.append('surveyPlanNo', formData.surveyPlanNo);
    // }

    // uploadData.append('dateOfSurvey', formData.dateOfSurvey);
    if (formData.dateOfSurvey) {
      uploadData.append('dateOfSurvey', formData.dateOfSurvey);
    }

    uploadData.append('fundedBy', formData.fundedBy);
    uploadData.append('plantedByName1', formData.plantedByName1);
    uploadData.append('plantedByName2', formData.plantedByName2);
    uploadData.append('plantedByName3', formData.plantedByName3);

    // uploadData.append('plantingDistance', formData.plantingDistance);
    if (formData.plantingDistance) {
      uploadData.append('plantingDistance', formData.plantingDistance);
    }

    // uploadData.append('dateOfPlanting', formData.dateOfPlanting);
    if (formData.dateOfPlanting) {
      uploadData.append('dateOfPlanting', formData.dateOfPlanting);
    }

    uploadData.append('comments', formData.comments);

    // uploadData.append('noPlantsConfirmed', formData.noPlantsConfirmed);
    if (formData.noPlantsConfirmed) {
      uploadData.append('noPlantsConfirmed', formData.noPlantsConfirmed);
    }

    // uploadData.append('confirmationOfCenses', formData.confirmationOfCenses);
    if (formData.confirmationOfCenses) {
      uploadData.append('confirmationOfCenses', formData.confirmationOfCenses);
    }

    if (this.selectedFile) {
      uploadData.append('surveyPlanJPGTag', this.selectedFile);
    }

    this.loadingBtn = true;
    this.matureFieldsService.create(uploadData).subscribe(res=>{
      if(res){
        this.notification.success('Mature Field has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Mature Field has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Mature Field already exist', 'Error');
      }
      else {
        this.notification.error('Mature Field has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }

}
