import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EstateService } from 'src/app/services/estate.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-estate-edit',
  templateUrl: './estate-edit.component.html',
  styleUrls: ['./estate-edit.component.scss']
})
export class EstateEditComponent {

  param: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  initialState:any;
  refData:any;
  sqldate: string;
  sqldate2: string;

  dtNationalizationObj:Date;
  dtPrivatizationObj: Date;

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;

  estateEditForm:any;
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  postOfficeList: any[]=[];
  nameOfRevenueDistrictList: any[]=[];
  divisionalSecretaryList: any[]=[];
  gramaNiladhariAreaList: any[]=[];
  policeDivisionList: any[]=[];
  policeAreaList: any[]=[];
  pradeshiyaSabaAreaList: any[]=[];

  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private estateService: EstateService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) { 
    

  }

  ngOnInit(): void {
    this.initFormValues();

    this.refDataService.getEstateRefData().subscribe((data)=>{
      this.refData = data;

      this.postOfficeList = this.refData.postOffice;
      this.nameOfRevenueDistrictList = this.refData.revDistricts;
      this.divisionalSecretaryList = this.refData.divSecretary;
      this.gramaNiladhariAreaList = this.refData.gnArea;
      this.policeDivisionList = this.refData.policeDivision;
      this.policeAreaList = this.refData.policeArea;
      this.pradeshiyaSabaAreaList = this.refData.psArea;
      // console.log(this.refData.companies);
    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
      console.log(hostName);
      this.companyCodeList.push(hostName);
      this.estateEditForm.controls["companyCode"].disable();

  }


 initFormValues(){
    this.sqldate = this.param.dateOfNationalization;
    this.sqldate2 = this.param.dateOfPrivatization;

    this.dtNationalizationObj = new Date(this.param.dateOfNationalization);
    this.dtPrivatizationObj = new Date(this.param.dateOfPrivatization);

    this.estateEditForm = new UntypedFormGroup(
        {
          companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(10)]),
          estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required]),
          estateName: new UntypedFormControl(this.param.estateName,[Validators.required, Validators.maxLength(50)]),
          address: new UntypedFormControl(this.param.address,[Validators.required, Validators.maxLength(50)]),
          city: new UntypedFormControl(this.param.city,[Validators.maxLength(50)]),
          telephoneNumber: new UntypedFormControl(this.param.telephoneNumber,[Validators.maxLength(25)]),
          emailAddress: new UntypedFormControl(this.param.emailAddress,[Validators.maxLength(50), Validators.email]),
          postOffice: new UntypedFormControl(this.param.postOffice,[Validators.maxLength(50)]),
          nameOfMainTown: new UntypedFormControl(this.param.nameOfMainTown,[Validators.maxLength(50)]),
          distanceFromMainTown: new UntypedFormControl(this.param.distanceFromMainTown,[Validators.maxLength(10)]),
          minimumElevation: new UntypedFormControl(this.param.minimumElevation,[Validators.maxLength(4)]),
          maximumElevation: new UntypedFormControl(this.param.maximumElevation,[Validators.maxLength(4)]),
          nameOfRevenueDistrict: new UntypedFormControl(this.param.nameOfRevenueDistrict,[Validators.maxLength(50)]),
          divisionalSecretary: new UntypedFormControl(this.param.divisionalSecretary,[Validators.maxLength(50)]),
          gramaNiladhariArea: new UntypedFormControl(this.param.gramaNiladhariArea,[Validators.maxLength(50)]),
          policeDivision: new UntypedFormControl(this.param.policeDivision,[Validators.maxLength(50)]),
          policeArea: new UntypedFormControl(this.param.policeArea,[Validators.maxLength(50)]),
          pradeshiyaSabaArea: new UntypedFormControl(this.param.pradeshiyaSabaArea,[Validators.maxLength(50)]),
          nameOfForestOfficer1: new UntypedFormControl(this.param.nameOfForestOfficer1,[Validators.maxLength(50)]),
          nameOfForestOfficer2: new UntypedFormControl(this.param.nameOfForestOfficer2,[Validators.maxLength(50)]),
          dateOfNationalization: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate)),
          dateOfPrivatization: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate2))
        }
    );
      
    validateForm(this.estateEditForm);

  }

  convertToNgbDateStruct(dateString: string): NgbDateStruct | null {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('-');
    return {
      year: parseInt(parts[0], 10),
      month: parseInt(parts[1], 10),
      day: parseInt(parts[2], 10)
    };
  }

  companyCodeChanged(event){
  }
  
  pradeshiyaSabaAreaChanged($event) {
  }
  
  policeAreaChanged($event) {
  }
  
  policeDivisionChanged($event) {
  }
  
  gramaNiladhariAreaChanged($event) {
  }
  
  divisionalSecretaryChanged($event) {
  }
  
  nameOfRevenueDistrictChanged($event) {
  }
  
  postOfficeChanged($event) {
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }


  updateEstate(event) {
    if (this.estateEditForm.valid) {

      this.loadingBtn = true;
      let estateCode = this.param.estateCode;
      let submitData = this.estateEditForm.getRawValue();

      submitData.dateOfPrivatization = this.myformatDate( this.estateEditForm.controls["dateOfPrivatization"].value);
      submitData.dateOfNationalization = this.myformatDate( this.estateEditForm.controls["dateOfNationalization"].value);
      
      this.estateService.update(estateCode, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Estate has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Estate has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Estate has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.estateEditForm);
    }
  }



}
