<div class="modal-header">
  <h5 class="modal-title pull-left">Edit Company</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  class="p-3"
  [formGroup]="companyEditForm"
  (ngSubmit)="updateCompany(event)"
>
  <div class="modal-body-edit-company">
    <div class="form-group row">
      <label
        for="parentCompanyCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Parent Company Name
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="parentCompanyCode"
          optionTextField="parentCompanyName"
          [items]="parentCompanyList"
          formControlName="parentCompanyCode"
          placeholder="Parent Company Name"
          (select)="parentCompanyCodeChanged($event)"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'parentCompanyCode'"
        ></ngx-select>
        <div
          *ngIf="
            companyEditForm.controls['parentCompanyCode'].invalid &&
            (companyEditForm.controls['parentCompanyCode'].dirty ||
              companyEditForm.controls['parentCompanyCode'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              companyEditForm.controls['parentCompanyCode'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="CompanyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="5"
          class="form-control"
          formControlName="companyCode"
          placeholder="Company Code"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'companyCode'"
        />
        <div
          *ngIf="
            companyEditForm.controls['companyCode'].invalid &&
            (companyEditForm.controls['companyCode'].dirty ||
              companyEditForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['companyCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="companyName" class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Company</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="50"
          class="form-control"
          formControlName="companyName"
          placeholder="Name of Company"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'companyName'"
        />
        <div
          *ngIf="
            companyEditForm.controls['companyName'].invalid &&
            (companyEditForm.controls['companyName'].dirty ||
              companyEditForm.controls['companyName'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['companyName'].errors.required">
            This field is required.
          </div>
          <div *ngIf="companyEditForm.controls['companyName'].errors.maxlength">
            Maximum length would be 200 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="address" class="col-sm-4 col-form-label col-form-label-sm"
        >Address</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="address"
          placeholder="Address"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'address'"
        />
        <div
          *ngIf="
            companyEditForm.controls['address'].invalid &&
            (companyEditForm.controls['address'].dirty ||
              companyEditForm.controls['address'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['address'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="city" class="col-sm-4 col-form-label col-form-label-sm"
        >City</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="city"
          placeholder="City"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'city'"
        />
        <div
          *ngIf="
            companyEditForm.controls['city'].invalid &&
            (companyEditForm.controls['city'].dirty ||
              companyEditForm.controls['city'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['city'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="country" class="col-sm-4 col-form-label col-form-label-sm"
        >Country
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          placeholder="Country"
          [items]="countryList"
          formControlName="country"
          (select)="countryChanged($event)"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'country'"
        ></ngx-select>
        <div
          *ngIf="
            companyEditForm.controls['country'].invalid &&
            (companyEditForm.controls['country'].dirty ||
              companyEditForm.controls['country'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['country'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="telephoneNumber"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Telephone Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="telephoneNumber"
          placeholder="Telephone Number"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'telephoneNumber'"
        />
        <div
          *ngIf="
            companyEditForm.controls['telephoneNumber'].invalid &&
            (companyEditForm.controls['telephoneNumber'].dirty ||
              companyEditForm.controls['telephoneNumber'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="companyEditForm.controls['telephoneNumber'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="faxNumber" class="col-sm-4 col-form-label col-form-label-sm"
        >Fax Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="faxNumber"
          placeholder="Fax Number"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'faxNumber'"
        />
        <div
          *ngIf="
            companyEditForm.controls['faxNumber'].invalid &&
            (companyEditForm.controls['faxNumber'].dirty ||
              companyEditForm.controls['faxNumber'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['faxNumber'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="emailAddress"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Email Address</label
      >
      <div class="col-sm-8">
        <input
          type="email"
          maxlength="50"
          class="form-control"
          formControlName="emailAddress"
          placeholder="Email Address"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'emailAddress'"
        />
        <div
          *ngIf="
            companyEditForm.controls['emailAddress'].invalid &&
            (companyEditForm.controls['emailAddress'].dirty ||
              companyEditForm.controls['emailAddress'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['emailAddress'].errors.required">
            This field is required.
          </div>
          <div *ngIf="companyEditForm.controls['emailAddress'].errors.email">
            This must be an Email Address.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="webAddress" class="col-sm-4 col-form-label col-form-label-sm"
        >Web Address</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="webAddress"
          placeholder="Web Address"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'webAddress'"
        />
        <div
          *ngIf="
            companyEditForm.controls['webAddress'].invalid &&
            (companyEditForm.controls['webAddress'].dirty ||
              companyEditForm.controls['webAddress'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="companyEditForm.controls['webAddress'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfNationalization"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Nationalization</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d1"
            formControlName="dateOfNationalization"
            ngbDatepicker
            #d1="ngbDatepicker"
            [value]="dtNationalizationObj"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfIncorporation"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Incorporation</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            id="dateOfIncorporation"
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d2"
            formControlName="dateOfIncorporation"
            ngbDatepicker
            #d2="ngbDatepicker"
            [value]="dtIncorporationObj"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d2.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="businessRegistrationNumber"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Business Registration Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="businessRegistrationNumber"
          placeholder="Business Registration Number"
          appInfoPromptBox
          [page]="'company'"
          [component]="'edit'"
          [field]="'businessRegistrationNumber'"
        />
        <div
          *ngIf="
            companyEditForm.controls['businessRegistrationNumber'].invalid &&
            (companyEditForm.controls['businessRegistrationNumber'].dirty ||
              companyEditForm.controls['businessRegistrationNumber'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              companyEditForm.controls['businessRegistrationNumber'].errors
                .required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
