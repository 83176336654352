import { Component, EventEmitter, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { validateForm } from 'src/app/shared/helper';
import { ToastrService } from 'ngx-toastr';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';

@Component({
  selector: 'app-mature-fields-trees-harvest',
  templateUrl: './mature-fields-trees-harvest.component.html',
  styleUrls: ['./mature-fields-trees-harvest.component.scss']
})
export class MatureFieldsTreesHarvestComponent {

  event: EventEmitter<any> = new EventEmitter();
  param:any;
  disableButton:Boolean = false;
  loading:Boolean=false;
  loadingBtn:Boolean=false;

  companyCode:any;
  treeData:any;
  
  harvestingPlanList: any[]=[];
  treeList: any[]=[];
  apiURL:any;
  rowData:any;
  refData:any;
  harvestingType : Number = 1;

  addToHarvestingPlanForm = new UntypedFormGroup({
    harvestingPlanId : new UntypedFormControl('')
  });

  constructor(
      public bsModalRef:BsModalRef,
      public modalService: BsModalService,
      public harvestingPlanService: HarvestingPlanService,
      private notificationService: ToastrService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) {
  }

  ngOnInit(): void {
    this.rowData = this.param;
      this.treeList = this.treeData;
      console.log("Parameters:",this.rowData);
      console.log("Trees:",this.treeList);
   
      // this.treeStatus = 1;
      // this.availableQuantity = 1;
      // this.harvestingQuantity = 1;
      // this.harvestingType = 1;

      //-----------------------------------------------------------------------------------------------Need to get the harvesting plans by division code 

      this.refDataService.getAllHarvestingPlanByHarvestingType(this.harvestingType).subscribe((data)=>{
        this.refData = data;
        this.refData.forEach(hpCodes => {
          this.harvestingPlanList.push({
            id: hpCodes.harvestingPlanId,
            value: hpCodes.hpShortCode
          });
        });
  
        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Harvesting Plan ID Completed");
        });

      let hostName = this.urlService.getHostName(window.location);
      this.companyCode = hostName;
      console.log("HostName:",this.companyCode);
      
    this.initFormValues();
   
  }

  initFormValues(){
    validateForm(this.addToHarvestingPlanForm);
  }

  harvestingPlanChanged($event){
  }

  addToHarvestingPlan(){

    this.loadingBtn = true;
    this.loading=true;

    let formData = this.addToHarvestingPlanForm.getRawValue();
    let harvestingPlanID = formData.harvestingPlanId;
      //console.log("Harvesting Plan ID::",harvestingPlanID);
      this.harvestingPlanService.addMatureTreesToHarvestingPlan(this.companyCode,harvestingPlanID,this.treeList).subscribe(res=>{
        // console.log("Res Mssage:",res);
      if(res){
        this.notificationService.success('Trees Successfully Added to the Harvesting Plan','Success');
        this.event.emit()
        this.bsModalRef.hide();
      }else{
        this.notificationService.error('Trees has not been Added','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notificationService.error('This Trees already exist', 'Error');
      }
      else {
        this.notificationService.error('Trees has NOT been Added','Error');
      }
      this.loadingBtn = false;
    });
  }
}
