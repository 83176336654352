import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatureFieldsService {

  constructor(private http: HttpClient) { }

    getAllMatureFields(companyName,apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/maturefields/" +companyName+"/"+ apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/maturefields/", data);
    }

    update(matureFieldCode,uploadData)
    {
        return this.http.put(environment.apiUrl + "/maturefields/" + matureFieldCode, uploadData);
    }

    delete(matureFieldCode)
    {
        if (!matureFieldCode) return;
        return this.http.delete(environment.apiUrl + "/maturefields/" + matureFieldCode);
    }

    getFieldMapImage(fieldCode: string): Observable<Blob> 
    {
        return this.http.get(environment.apiUrl + '/maturefields/image/' + fieldCode, { responseType: 'blob' });
    }

    deleteFieldMapImage(matureFieldCode)
    {
        if (!matureFieldCode) return;
        return this.http.delete(environment.apiUrl + "/maturefields/image/" + matureFieldCode);
    }

    getAllTreesByMatureField(estateCode, divisionCode, fieldCode, specieCode, apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/tree/" + estateCode + "/" + divisionCode + "/" + fieldCode + "/" + specieCode + "/details-list/" + apiType, data, {params: params});
    }

    getMasterPlanDataByMasterPlanID(estateCode, divisionCode, fieldCode)
    {
        return this.http.get(environment.apiUrl + "/maturefields/" + estateCode + "/" + divisionCode + "/" + fieldCode + "/" )
    }

}