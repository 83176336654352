import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports/reports.component';
import { MtReportsComponent } from './reports/components/mt-reports/mt-reports.component';


@NgModule({
  declarations: [
    ReportsComponent,
    MtReportsComponent
  ],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule,
    NgxPaginationModule,
    ModalModule,
    TreeTableModule
  ],
  bootstrap:[ReportsComponent],
})
export class ReportsModule { }