<div class="modal-header">
    <h5 class="modal-title pull-left">Add Forestry Management Plan</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingMasterPlanAddForm"
    (ngSubmit)="createNewHarvestingMasterPlan()"
    enctype="multipart/form-data"
  >
    <div class="modal-body-add-master-plan">
      <div class="form-group row">
        <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Company Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="companyCodeList"
            formControlName="companyCode"
            placeholder="Company Code"
            (select)="companyCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan'"
            [component]="'add'"
            [field]="'companyCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanAddForm.controls['companyCode'].invalid &&
              (harvestingMasterPlanAddForm.controls['companyCode'].dirty ||
                harvestingMasterPlanAddForm.controls['companyCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanAddForm.controls['companyCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label
          for="masterPlanCode"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Forestry Management Plan Code</label
        >
        <div class="col-sm-8">
          <ngx-select
            optionValueField="id"
            optionTextField="value"
            [items]="startYearList"
            formControlName="masterPlanCodeStart"
            placeholder="Select Start Year"
            (select)="startYear($event)"
            style="width: 45%; display: inline-block; margin-right: 4%;"
          ></ngx-select>
          <span style="display: inline-block; vertical-align: middle; margin-right: 4%;">-</span>
          <ngx-select
            optionValueField="id"
            optionTextField="value"
            [items]="endYearList"
            formControlName="masterPlanCodeEnd"
            placeholder="Select End Year"
            (select)="endYear($event)"
            style="width: 45%; display: inline-block;"
        ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanAddForm.controls['masterPlanCode'].invalid &&
              (harvestingMasterPlanAddForm.controls['masterPlanCode'].dirty ||
                harvestingMasterPlanAddForm.controls['masterPlanCode'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingMasterPlanAddForm.controls['masterPlanCode'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label col-form-label-sm"
          >Description</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="100"
            class="form-control"
            formControlName="description"
            placeholder="Description"
            appInfoPromptBox
            [page]="'master-plan'"
            [component]="'add'"
            [field]="'description'"
          />
          <div
            *ngIf="
              harvestingMasterPlanAddForm.controls['description'].invalid &&
              (harvestingMasterPlanAddForm.controls['description'].dirty ||
                harvestingMasterPlanAddForm.controls['description'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingMasterPlanAddForm.controls['description'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="" class="col-sm-4 col-form-label col-form-label-sm">
          Upload Harvesting Plan
        </label>
        <div class="col-sm-8">
          <input
            type="file"
            class="form-control"
            id="harvestingPlan"
            formControlName="harvestingPlan"
            accept = ".xls, .xlsx"
            appMandatoryField
            appInfoPromptBox
            (change)="onFileSelected($event)"
          />
        </div>
      </div>
  
    </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
