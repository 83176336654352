<div class="modal-header">
    <h5 class="modal-title pull-left">Edit Harvesting Plan Item</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingPlanItemsEditForm"
    (ngSubmit)="updateHarvestingPlanDetails()"
  >
  <div class="modal-body-add-">    
    <div class="form-group row">
    <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
    </label>
    <div class="col-sm-8">
        <ngx-select
        appMandatoryField
        optionValueField="id"
        optionTextField="value"
        [items]="companyCodeList"
        formControlName="companyCode"
        placeholder="Company Code"
        (select)="companyCodeChanged($event)"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'companyCode'"
        ></ngx-select>
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['companyCode'].invalid &&
            (harvestingPlanItemsEditForm.controls['companyCode'].dirty ||
            harvestingPlanItemsEditForm.controls['companyCode'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['companyCode'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
    </label>
    <div class="col-sm-8">
        <ngx-select
        appMandatoryField
        optionValueField="id"
        optionTextField="value"
        [items]="estateCodeList"
        placeholder="Estate Code"
        formControlName="estateCode"
        (select)="estateCodeChanged($event)"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'estateCode'"
        ></ngx-select>
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['estateCode'].invalid &&
            (harvestingPlanItemsEditForm.controls['estateCode'].dirty ||
            harvestingPlanItemsEditForm.controls['estateCode'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['estateCode'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code
    </label>
    <div class="col-sm-8">
        <ngx-select
        appMandatoryField
        optionValueField="id"
        optionTextField="value"
        [items]="divisionCodeList"
        placeholder="Division Code"
        formControlName="divisionCode"
        (select)="divisionCodeChanged($event)"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'divisionCode'"
        ></ngx-select>
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['divisionCode'].invalid &&
            (harvestingPlanItemsEditForm.controls['divisionCode'].dirty ||
            harvestingPlanItemsEditForm.controls['divisionCode'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['divisionCode'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Field Code
    </label>
    <div class="col-sm-8">
        <ngx-select
        appMandatoryField
        optionValueField="id"
        optionTextField="value"
        [items]="fieldCodeList"
        placeholder="Field Code"
        formControlName="fieldCode"
        (select)="fieldCodeChanged($event)"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'fieldCode'"
        ></ngx-select>
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['fieldCode'].invalid &&
            (harvestingPlanItemsEditForm.controls['fieldCode'].dirty ||
            harvestingPlanItemsEditForm.controls['fieldCode'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['fieldCode'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="treeStatus" class="col-sm-4 col-form-label col-form-label-sm"
        >Tree Status
    </label>
    <div class="col-sm-8">
        <ngx-select
        appMandatoryField
        optionValueField="id"
        optionTextField="value"
        [items]="treeStatusList"
        placeholder="Tree Status"
        formControlName="treeStatus"
        (select)="treeStatusChanged($event)"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'treeStatus'"
        ></ngx-select>
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['treeStatus'].invalid &&
            (harvestingPlanItemsEditForm.controls['treeStatus'].dirty ||
            harvestingPlanItemsEditForm.controls['treeStatus'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['treeStatus'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="availableQuantity" class="col-sm-4 col-form-label col-form-label-sm"
        >Available Quantity</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="availableQuantity"
        placeholder="Available Quantity"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'availableQuantity'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['availableQuantity'].invalid &&
            (harvestingPlanItemsEditForm.controls['availableQuantity'].dirty ||
            harvestingPlanItemsEditForm.controls['availableQuantity'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['availableQuantity'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="harvestingQuantity" class="col-sm-4 col-form-label col-form-label-sm"
        >Harvesting Quantity</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="harvestingQuantity"
        placeholder="Harvesting Quantity"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'harvestingQuantity'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['harvestingQuantity'].invalid &&
            (harvestingPlanItemsEditForm.controls['harvestingQuantity'].dirty ||
            harvestingPlanItemsEditForm.controls['harvestingQuantity'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['harvestingQuantity'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="harvestingType" class="col-sm-4 col-form-label col-form-label-sm"
        >Harvesting Type</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="harvestingType"
        placeholder="Harvesting Type"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'harvestingType'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['harvestingType'].invalid &&
            (harvestingPlanItemsEditForm.controls['harvestingType'].dirty ||
            harvestingPlanItemsEditForm.controls['harvestingType'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['harvestingType'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="approvalStatus" class="col-sm-4 col-form-label col-form-label-sm"
        >Approval Status</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="approvalStatus"
        placeholder="Approval Status"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'approvalStatus'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['approvalStatus'].invalid &&
            (harvestingPlanItemsEditForm.controls['approvalStatus'].dirty ||
            harvestingPlanItemsEditForm.controls['approvalStatus'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['approvalStatus'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="approvingEntity" class="col-sm-4 col-form-label col-form-label-sm"
        >Approving Entity</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="approvingEntity"
        placeholder="Approving Entity"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'approvingEntity'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['approvingEntity'].invalid &&
            (harvestingPlanItemsEditForm.controls['approvingEntity'].dirty ||
            harvestingPlanItemsEditForm.controls['approvingEntity'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['approvingEntity'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="remarks" class="col-sm-4 col-form-label col-form-label-sm"
        >Remarks</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        maxlength="200"
        class="form-control"
        formControlName="remarks"
        placeholder="Remarks"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'remarks'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['remarks'].invalid &&
            (harvestingPlanItemsEditForm.controls['remarks'].dirty ||
            harvestingPlanItemsEditForm.controls['remarks'].touched)
        "
        class="ng-validator"
        >
        <div *ngIf="harvestingPlanItemsEditForm.controls['remarks'].errors.required">
            This field is required.
        </div>
        </div>
    </div>
    </div>

    <div class="form-group row">
    <label for="approvedQuantity" class="col-sm-4 col-form-label col-form-label-sm"
        >Approved Quantity</label
    >
    <div class="col-sm-8">
        <input
        type="text"
        appMandatoryField
        maxlength="10"
        appDecimal="2"
        class="form-control"
        formControlName="approvedQuantity"
        placeholder="Approved Quantity"
        appInfoPromptBox
        [page]="'add-harvesting-plan'"
        [component]="'add'"
        [field]="'approvedQuantity'"
        />
        <div
        *ngIf="
            harvestingPlanItemsEditForm.controls['approvedQuantity'].invalid &&
            (harvestingPlanItemsEditForm.controls['approvedQuantity'].dirty ||
            harvestingPlanItemsEditForm.controls['approvedQuantity'].touched)
        "
        class="ng-validator"
        >
        <div
            *ngIf="harvestingPlanItemsEditForm.controls['approvedQuantity'].errors.required"
        >
            This field is required.
        </div>
        </div>
    </div>
    </div>

</div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
  