import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ParentCompanyService } from 'src/app/services/parent-company.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-parent-company',
  templateUrl: './parent-company.component.html',
  styleUrls: ['./parent-company.component.scss']
})
export class ParentCompanyComponent {

param:any;
event: EventEmitter<any> = new EventEmitter();
initialValues: any;
parentCompanyEditForm:any;
loadingBtn : Boolean = false;
estateCodeList: any[]= [];
isLoading: boolean;
systemService: any;
notification: any;
router: any;
refData: any;
parentData:any;

parentCompanyList: any[]= [];

ngOnInit(): void {
  this.initFormValues();

  // this.parentCompanyService.getParentCompany().subscribe((data)=>{
  //   this.parentData = data;
  //   console.log(this.parentData);
    
  // },error=>{
  //   console.log("Parent Company Error Occurred");
  //   console.log(error)
  // },()=>{
  //   console.log("Completed");
  // });

  var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
    });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

  this.refDataService.getCompanyRefData(companyCode).subscribe((data)=>{
    this.parentData = data;
    // console.log(data);

    this.parentCompanyList.push(this.parentData.parentCompany);

    this.parentCompanyEditForm.controls["parentCompanyCode"].patchValue(this.parentData.parentCompany.parentCompanyCode);
    this.parentCompanyEditForm.controls["parentCompanyCode"].disable();

    this.parentCompanyEditForm.controls["parentCompanyName"].patchValue(this.parentData.parentCompany.parentCompanyName);
    this.parentCompanyEditForm.controls["parentCompanyName"].disable();

    this.parentCompanyEditForm.controls["parentCompanyAddress"].patchValue(this.parentData.parentCompany.parentCompanyAddress);
    this.parentCompanyEditForm.controls["parentCompanyAddress"].disable();

    this.parentCompanyEditForm.controls["parentCompanyCity"].patchValue(this.parentData.parentCompany.parentCompanyCity);
    this.parentCompanyEditForm.controls["parentCompanyCity"].disable();

    // console.log(this.parentData.parentCompany);
  },error=>{
    console.log("Error Occurred");
    console.log(error)
  },()=>{
    console.log("Completed");
  });

}

initFormValues(){
  this.parentCompanyEditForm = new UntypedFormGroup(
    {
      parentCompanyCode : new UntypedFormControl(''),
      parentCompanyName : new UntypedFormControl(''),
      parentCompanyAddress: new UntypedFormControl(''),
      parentCompanyCity: new UntypedFormControl('')
    }
  );
  validateForm(this.parentCompanyEditForm);
}

  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private parentCompanyService: ParentCompanyService,
      private urlService : UrlService,
      private refDataService : RefdataService
) { 
}

  updateParentCompany() {
    if (this.parentCompanyEditForm.valid) {

      this.loadingBtn = true;
      let parentCompanyCode = this.param.parentCompanyCode;
      let submitData = this.parentCompanyEditForm.getRawValue();
      
      this.parentCompanyService.update(parentCompanyCode, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Parent Company has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Parent Company has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Parent Company has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.parentCompanyEditForm);
    }
  }

}
