<div class="modal-header">
    <div class="row">
      <h5 class="modal-title pull-left"> &nbsp; Trees List &nbsp;</h5> &nbsp;
      <input id="addToHarvestingPlan" type="checkbox" [checked]="(selectedCount==rowCount)" (change)="selectAll($event)" />
    </div>
      <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div *ngIf="infoStatus" class="row">
    <div class="" style="padding-left: 25px; padding-right: 15px; padding-top: 20px; ">
      <table class="table table-bordered table-striped">
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> Forestry Management Plan Code </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{masterPlanData['masterPlanCode']}} </td>
        </tr>
        <tr>
          <th style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> Description </th>
          <td style="padding-top: 3px !important;vertical-align: middle;padding-bottom: 3px !important;"> {{masterPlanData['description']}} </td>
        </tr>
      </table>
    </div>
  </div>
  
  <div class="modal-body">
    <div #dataTree class="data-table">
      <app-tree-table [settings]="settings" [data]="harvestingPlanDetails" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
    </div>
  </div>

  <div class="modal-footer">
    <button
      id="btnAddHarvestingPlan"
      type="button"
      (click)="loadAddModal()"
      class="btn btn-primary btn-sm"
      [disabled]="addToHarvestList.length === 0"
    >
      <fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add To Harvest
    </button>
  </div>