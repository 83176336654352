import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MatureFieldsService } from 'src/app/services/mature-fields.service';
import { MatureFieldsTreesHarvestComponent } from './components/mature-fields-trees-harvest/mature-fields-trees-harvest.component';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';

@Component({
  selector: 'app-mature-fields-trees',
  templateUrl: './mature-fields-trees.component.html',
  styleUrls: ['./mature-fields-trees.component.scss']
})
export class MatureFieldsTreesComponent implements OnInit {
  param:any;
  rowData:any;
  refData : any;
  faPlus = faPlus;
  exportUrl:any;
  estateCode:any;
  divisionCode:any;
  specieCode:any;
  fieldCode:any;
  masterPlanStart:any;
  masterPlanEnd:any;
  masterPlanData:any;
  harvestingPlanDetails:any;
  rows: any;

  harvestingYearList: any[]=[];
  harvestedTrees: any[]=[];
  harvestedTreesObj : any;
  specieCodeList: any[]=[];
  addToHarvestList: any[] = [];
  rowTreeData: any[]=[]
  rowCount : number = 0;
  selectedCount : number = 0;
  harvestingType : Number = 1;

  filterData = [];
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";

  showFormRow : Boolean = false;
  projectToolLength : Number = 0;
  infoStatus = false;
  initialValues:any = null;
  event : EventEmitter<any> = new EventEmitter();

  settings = {
    type:'table',
    id:'harvestingPlanID',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.matureFiledService.getAllTreesByMatureField(this.estateCode, this.divisionCode, this.fieldCode, this.specieCode, "distinct-values", filterData, params)},
    columns:[
      {
        title:'★',
        attribute:'addToHarvestingPlan',
        type:'checkbox',
        class:'chkTree',
        filter: {
          enabled:false,
          isNumericField:false,
        },
        actionFunction: (event, row)=>{this.addToHarvest(event, row)},
        width:100,
        hideFunction: (row)=>{return this.harvestedTrees.includes(row.treeID)}
      },
      {
        title:'Tree Number',
        attribute:'treeNo',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Estate Code',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Field Code',
        attribute:'fieldCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Specie Code',
        attribute:'specieCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'GBH Height',
        attribute:'girthBrestHeight',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Height (m)',
        attribute:'height',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      }
    ]
  };

  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private matureFiledService : MatureFieldsService,
      private harvestingPlanService : HarvestingPlanService
  ) { }

  ngOnInit(): void {

    this.addToHarvestList = [];
    
    this.estateCode = this.param.estateCode;
    this.divisionCode = this.param.divisionCode;
    this.fieldCode = this.param.fieldCode;
    this.specieCode = this.param.specieCode;
    // console.log("Params data:",this.estateCode,",",this.divisionCode,",",this.fieldCode);
    
    this.getAlTreesByMatureFiled();
  }

  @ViewChild('dataTree') chkTreeDiv: ElementRef;

  checkAll() {
    const checkboxes = this.chkTreeDiv.nativeElement.querySelectorAll('.chkTree input[type="checkbox"]'); 
    checkboxes.forEach(checkbox => {
      checkbox.checked = true;
    });
    
    this.selectedCount = this.rowCount;

  }

  unCheckAll() {
    const checkboxes = this.chkTreeDiv.nativeElement.querySelectorAll('.chkTree input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    this.selectedCount = 0;

  }

  inverseCheckAll() {
    this.selectedCount = 0;
    const checkboxes = this.chkTreeDiv.nativeElement.querySelectorAll('.chkTree input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = !checkbox.checked;
      this.selectedCount += (checkbox.checked?1:0);
    });
  }

  addToHarvest(event: any, row: any) {
    if (event.target.checked) {
      if (!this.addToHarvestList.includes(row)) {
        this.addToHarvestList.push(row);
      }
      this.selectedCount++ ;
    } else {
      const index = this.addToHarvestList.indexOf(row);
      if (index > -1) {
        this.addToHarvestList.splice(index, 1);
      }
      this.selectedCount-- ;
      
    }
    console.log('Selected rows:', this.addToHarvestList);
  }
  
  selectAll(event: any) {
    this.addToHarvestList = [];

    const checked = event.target.checked;
    this.rowTreeData.forEach(row => {
      row.checked = checked; // Update each row's checkbox state
      if (checked) {
        if (!this.addToHarvestList.includes(row)) {
          this.addToHarvestList.push(row); // Add if not already selected
        }
      } else {
        // If unchecked, clear the list of selected rows
        const index = this.addToHarvestList.indexOf(row);
        if (index > -1) {
          this.addToHarvestList.splice(index, 1); // Remove row if it exists in the list
          this.addToHarvestList = [];
        }
      }
    });

    if(checked) this.checkAll(); else this.unCheckAll();
    this.compareTreeList();
    console.log('Selected rows:', this.addToHarvestList);
  }

  isAllSelected() {
    return this.rowTreeData.every(row => row.checked);
  }

  loadAddModal(){
    const initialState = {
      param:this.param,
      treeData:this.addToHarvestList
    };
    let modalRef = this.modalService.show(MatureFieldsTreesHarvestComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAlTreesByMatureFiled();
    });
  }

  addedHarvestedTrees(){
    this.harvestingPlanService.getHarvestedTrees(this.estateCode,this.divisionCode,this.fieldCode,this.harvestingType).subscribe(res=>{
      this.harvestedTreesObj = res;
      this.harvestedTrees = this.harvestedTreesObj;
      console.log("Harvested Trees:",this.harvestedTrees);
    })
  }

  compareTreeList(){
    this.addToHarvestList = this.addToHarvestList.filter(
      (tree) => !this.harvestedTrees.includes(tree.treeID)
    );
  
    console.log('Updated addToHarvestList:', this.addToHarvestList);
  }


  // getMatureFiledDataByID(){
  //   this.matureFiledService.getAllMatureFields(this.param.masterPlanID).subscribe(res => {
  //     this.masterPlanData = res;
  //     // console.log("Master Plan Data: ",this.masterPlanData.page['masterPlanID'],",",this.masterPlanData['masterPlanCode']);
  //     // console.log("Master Plan Data: ",this.masterPlanData);

  //     this.infoStatus = true;
      
  //       this.getAlTreesByMatureFiled();
  //   });
  // }

  getAlTreesByMatureFiled(){
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

      // console.log("This is Master Plan ID: ",this.masterPlanID);
      this.matureFiledService.getAllTreesByMatureField(this.estateCode, this.divisionCode, this.fieldCode, this.specieCode, "filtered-data", this.filterData, params).subscribe(res=>{
      this.rows = res;

      this.rowTreeData = this.rows.page.content;
          this.addToHarvestList = [];
          // console.log("Harvest Rows",this.addToHarvestList);
      
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.rowCount = res['page']['numberOfElements'];
        let result = res['page']['content'];
        this.harvestingPlanDetails = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];

        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
    this.addedHarvestedTrees();
  }


  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.harvestingPlanDetails = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAlTreesByMatureFiled();
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAlTreesByMatureFiled();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAlTreesByMatureFiled();
  }

}
