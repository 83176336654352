<div class="container-fluid">
  <div class="row">
    <div class="pageTitle col-sm-6">
      <span>Tree Species </span> &nbsp;<button
        id="btnAddSpecies"
        type="button"
        (click)="loadAddModal()"
        class="btn btn-primary btn-sm"
        appInfoPromptBox
        [page]="'TreeSpecies'"
        [component]="'grid'"
        [field]="'addButton'"
      >
        <fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add
      </button>
    </div>
    <!-- <div class=" pageTitle col-sm-6 text-right">
        <button type="button" [routerLink]="previousUrl" routerLinkActive="router-link-active"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
      </div> -->
  </div>

  <div class="data-table">
    <app-tree-table
      [spinnerVisible]="loading"
      spinnerText="Loading Tree Species..."
      [settings]="settings"
      [data]="treeSpecies"
      [currentPage]="currentPage"
      [pageCount]="pageCount"
      (pageChangeEvent)="changePage($event)"
      (pageSizeChangeEvent)="changePageSize($event)"
      (filterChangeEvent)="filterOrSortChange($event)"
    ></app-tree-table>
  </div>
</div>
<!-- wrapper -->
