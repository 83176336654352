import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingPlanDetailsService } from 'src/app/services/harvesting-plan-details.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-harvesting-list-add',
  templateUrl: './harvesting-list-add.component.html',
  styleUrls: ['./harvesting-list-add.component.scss']
})
export class HarvestingListAddComponent {

  param:any;
  refData : any;
  exportUrl:any;
  showFormRow:Boolean = false;
  harvestingPlanDetails:any;
  projectToolLength:Number=0;

  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  specieCodeList: any[]=[];
  harvestingYearList: any[]=[];
  filterData = [];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  harvestingMasterPlanDetailsAddForm = new UntypedFormGroup({
    masterPlanID: new UntypedFormControl(''),
    harvestingPlanID: new UntypedFormControl(''),
    companyCode: new UntypedFormControl(''),
    estateCode: new UntypedFormControl(''),
    divisionCode: new UntypedFormControl(''),
    fieldCode: new UntypedFormControl(''),
    crop: new UntypedFormControl(''),
    specieCode: new UntypedFormControl(''),
    trees: new UntypedFormControl(''),
    gbHeight: new UntypedFormControl(''),
    volumeCDM: new UntypedFormControl(''),
    mv: new UntypedFormControl(''),
    age: new UntypedFormControl(''),
    extHectare: new UntypedFormControl(''),
    year: new UntypedFormControl(''),
    operation: new UntypedFormControl('')
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private harvestingMasterPlanService : HarvestingPlanDetailsService,
      private refDataService : RefdataService,
      private urlService : UrlService
  ) { 
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  yearList($event) {
  }

  generateHarvestingYearList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.harvestingYearList.push({ id: year, value: year.toString() });
    }
  }


  ngOnInit(): void {
    
    var companyCode = this.urlService.getHostName(window.location);
    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.harvestingMasterPlanDetailsAddForm.controls["companyCode"].patchValue(hostName);
      this.harvestingMasterPlanDetailsAddForm.controls["companyCode"].disable();

      // var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      // console.log(this.refData);

      this.refData.forEach(item => {
        this.specieCodeList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Specie Codes Completed");
    });

    // this.estateCodeChanged(this.param.estateCode);
    //   this.divisionCodeChanged(this.param.divisionCode);
      
    this.generateHarvestingYearList(this.param.masterPlanStart, this.param.masterPlanEnd);

    this.harvestingMasterPlanDetailsAddForm.patchValue({
      masterPlanID: this.param.masterPlanID
    });

    // console.log("Data From the DB: ",this.param);

    // let hostName = this.urlService.getHostName(window.location);
        //   console.log(hostName);
        // this.companyCodeList.push(hostName);
        // console.log("Company Code List: ",this.companyCodeList);


    this.harvestingMasterPlanDetailsAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  createNewHarvestingMasterPlanDetail(){
    let formData = this.harvestingMasterPlanDetailsAddForm.getRawValue();
    // formData.status = 1;
    // formData.workFlowStatus = 1;

    // console.log("Form data :",formData);
    
    this.loadingBtn = true;
    this.harvestingMasterPlanService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Forestry Management Plan Detail has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Forestry Management Plan Detail has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Forestry Management Plan Detail already exist', 'Error');
      }
      else {
        this.notification.error('Forestry Management Plan Detail has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }


}
