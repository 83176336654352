<div class="modal-header">
    <h5 class="modal-title pull-left">Edit Forestry Management Plan</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingMasterPlanEditForm"
    (ngSubmit)="updateHarvestingMasterPlan(event)"
  >
    <div class="modal-body-edit-master-plan">
        <div class="form-group row">
            <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
              >Company Code
            </label>
            <div class="col-sm-8">
              <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="companyCodeList"
                formControlName="companyCode"
                placeholder="Company Code"
                (select)="companyCodeChanged($event)"
                appInfoPromptBox
                [page]="'master-plan'"
                [component]="'edit'"
                [field]="'companyCode'"
              ></ngx-select>
              <div
                *ngIf="
                  harvestingMasterPlanEditForm.controls['companyCode'].invalid &&
                  (harvestingMasterPlanEditForm.controls['companyCode'].dirty ||
                    harvestingMasterPlanEditForm.controls['companyCode'].touched)
                "
                class="ng-validator"
              >
                <div
                  *ngIf="harvestingMasterPlanEditForm.controls['companyCode'].errors.required"
                >
                  This field is required.
                </div>
              </div>
            </div>
          </div>
      
          <div class="form-group row">
            <label
              for="masterPlanCode"
              class="col-sm-4 col-form-label col-form-label-sm"
              >Forestry Management Plan Code</label
            >
            <div class="col-sm-8">
              <ngx-select
                optionValueField="id"
                optionTextField="value"
                [items]="startYearList"
                formControlName="masterPlanCodeStart"
                placeholder="Select Start Year"
                (select)="startYear($event)"
                style="width: 45%; display: inline-block; margin-right: 4%;"
              ></ngx-select>
              <span style="display: inline-block; vertical-align: middle; margin-right: 4%;">- </span>
              <ngx-select
                optionValueField="id"
                optionTextField="value"
                [items]="endYearList"
                formControlName="masterPlanCodeEnd"
                placeholder="Select End Year"
                (select)="endYear($event)"
                style="width: 45%; display: inline-block;"
            ></ngx-select>
              <div
                *ngIf="
                  harvestingMasterPlanEditForm.controls['masterPlanCode'].invalid &&
                  (harvestingMasterPlanEditForm.controls['masterPlanCode'].dirty ||
                    harvestingMasterPlanEditForm.controls['masterPlanCode'].touched)
                "
                class="ng-validator"
              >
                <div *ngIf="harvestingMasterPlanEditForm.controls['masterPlanCode'].errors.required">
                  This field is required.
                </div>
              </div>
            </div>
          </div>
    
          <div class="form-group row">
            <label for="description" class="col-sm-4 col-form-label col-form-label-sm"
              >Description</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                maxlength="100"
                class="form-control"
                formControlName="description"
                placeholder="Funded by"
                appInfoPromptBox
                [page]="'master-plan'"
                [component]="'edit'"
                [field]="'description'"
              />
              <div
                *ngIf="
                  harvestingMasterPlanEditForm.controls['description'].invalid &&
                  (harvestingMasterPlanEditForm.controls['description'].dirty ||
                    harvestingMasterPlanEditForm.controls['description'].touched)
                "
                class="ng-validator"
              >
                <div *ngIf="harvestingMasterPlanEditForm.controls['description'].errors.required">
                  This field is required.
                </div>
              </div>
            </div>
          </div>
    </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
  