import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Toast, ToastrService } from 'ngx-toastr';
import { EstateService } from 'src/app/services/estate.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { ReportService } from 'src/app/services/report.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-mts-report',
  templateUrl: './mts-report.component.html',
  styleUrls: ['./mts-report.component.scss']
})
export class MtsReportComponent {
  

  param:any;
  data:any;
  divisionList:any[]=[];
  estateCode:any;

  event: EventEmitter<any> = new EventEmitter();
  disableButton:Boolean = false;
  loading:Boolean=false;
  loadingBtn:Boolean=false;

  mtsSelectedDivisionForm = new UntypedFormGroup({
    divisionCode: new UntypedFormControl('')
  }
);

  constructor(
    public bsModalRef: BsModalRef,
    private notificationService: ToastrService,
    private refDataService: RefdataService,
    private urlService: UrlService,
    private reportService: ReportService
  ){

  }
  ngOnInit(): void{
    this.data = this.param;
    this.estateCode = this.data.estateCode;

    this.divisionChanged();
  }

  initFormValues(){
    validateForm(this.mtsSelectedDivisionForm);
  }

  divisionChanged(){
    this.refDataService.getDivisionsByEstateCode(this.estateCode)
      .subscribe(
        (response) => {
          var divisions: any = response;
          this.divisionList = [];
          this.divisionList.push('All');
          divisions.forEach(division => {
            this.divisionList.push(division.divisionCode);
          });
        },
        (error) => {
          console.error('Error:', error);
        }
      );
  }

  downloadMTSReport(){
    const divisionCode = this.mtsSelectedDivisionForm.get('divisionCode').value;
    this.data = {};

    this.reportService.getMatureTimberStockbyEstateReport(this.data).subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'MatureTimberStockReport.pdf'; // Set your file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

}
