<div class="modal-header">
    <h5 class="modal-title pull-left">Mature Stock Report</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
<!-- <form class="p-3" [formGroup]="mtReportsForm" (ngSubmit)="addToHarvestingPlan()"> -->
    <form
    class="p-3"
    [formGroup]="mtReportsForm"
    (ngSubmit)="downloadMTReport()"
  >
    <div class="modal-body-add-">
        <div class="form-group row">
            <label for="selectFormType" class="col-sm-4 col-form-label col-form-label-sm"
                >Select Form Type
            </label>
            <div class="col-sm-8">
                <ngx-select
                [(ngModel)]="selectedReportType"
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="mtFormTypeList"
                formControlName="selectFormType"
                placeholder="Select Form Type"
                (select)="mtFormTypeChanged()"
                appInfoPromptBox
                [page]="'mt-reports'"
                [component]="'edit'"
                [field]="'selectFormType'"
                ></ngx-select>
                <!-- <div
                *ngIf="
                    mtReportsForm.controls['selectFormType'].invalid &&
                    (mtReportsForm.controls['selectFormType'].dirty ||
                    mtReportsForm.controls['selectFormType'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="mtReportsForm.controls['selectFormType'].errors.required"
                >
                    This field is required.
                </div>
                </div> -->
            </div>
        </div>

        <div class="form-group row" *ngIf="selectedReportType === 1 || selectedReportType === 3">
            <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Estate Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="estateList"
                formControlName="estateCode"
                placeholder="Estate Code"
                (select)="estateChanged($event)"
                appInfoPromptBox
                [page]="'mt-reports'"
                [component]="'edit'"
                [field]="'estateCode'"
                ></ngx-select>
                <!-- <div
                *ngIf="
                    mtReportsForm.controls['estateCode'].invalid &&
                    (mtReportsForm.controls['estateCode'].dirty ||
                    mtReportsForm.controls['estateCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="mtReportsForm.controls['estateCode'].errors.required"
                >
                    This field is required.
                </div>
                </div> -->
            </div>
        </div>

        <div class="form-group row" *ngIf="selectedReportType === 1 ">
            <label for="divisionCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Division Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="divisionList"
                formControlName="divisionCode"
                placeholder="Division Code"
                (select)="divisionChanged()"
                appInfoPromptBox
                [page]="'mt-reports'"
                [component]="'edit'"
                [field]="'divisionCode'"
                ></ngx-select>
                <!-- <div
                *ngIf="
                    mtReportsForm.controls['divisionCode'].invalid &&
                    (mtReportsForm.controls['divisionCode'].dirty ||
                    mtReportsForm.controls['divisionCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="mtReportsForm.controls['divisionCode'].errors.required"
                >
                    This field is required.
                </div>
                </div> -->
            </div>
        </div>

        <div class="form-group row" *ngIf="selectedReportType === 2 ">
            <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Specie Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="speciesList"
                formControlName="specieCode"
                placeholder="Specie Code"
                (select)="specieChanged()"
                appInfoPromptBox
                [page]="'mt-reports'"
                [component]="'edit'"
                [field]="'specieCode'"
                ></ngx-select>
                <!-- <div
                *ngIf="
                    mtReportsForm.controls['specieCode'].invalid &&
                    (mtReportsForm.controls['specieCode'].dirty ||
                    mtReportsForm.controls['specieCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="mtReportsForm.controls['specieCode'].errors.required"
                >
                    This field is required.
                </div>
                </div> -->
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <button
          type="submit"
          appButtonLoader
          [isLoading]="loadingBtn"
          [disabled]="disableButton"
          class="btn btn-primary"
        >
          Print
        </button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
          Close
        </button>
      </div>
</form>
  
  
