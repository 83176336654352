
import { MatureFieldsService } from 'src/app/services/mature-fields.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { MatureFieldsAddComponent } from './components/mature-fields-add/mature-fields-add.component';
import { MatureFieldsEditComponent } from './components/mature-fields-edit/mature-fields-edit.component';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingAddModalComponent } from 'src/app/shared/components/harvesting-modal/harvesting-add-modal/harvesting-add-modal.component';
import { MatureFieldsTreesComponent } from './components/mature-fields-trees/mature-fields-trees.component';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-mature-fields',
  templateUrl: './mature-fields.component.html',
  styleUrls: ['./mature-fields.component.scss']
})
export class MatureFieldsComponent {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  matureFields:any = [];
  rowData:any;
  
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'matureFieldCode',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:4,
      right:3
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.matureFieldsService.getAllMatureFields("distinct-values", filterData, params)},
    columns:[
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Estate Code',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Field Code',
        attribute:'fieldCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Specie Code',
        attribute:'specieCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Block No',
        attribute:'blockNo',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Previous Field Code',
        attribute:'immatureFieldCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Field Extent',
        attribute:'fieldExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Year of Planting',
        attribute:'yearofPlanting',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Initial Plants',
        attribute:'initialPlants',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'No of plants at present',
        attribute:'noPresentPlants',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Terrain',
        attribute:'terrain',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Distance from Main road',
        attribute:'distanceFromMainRoad',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Slope',
        attribute:'slope',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Soil Type',
        attribute:'soilType',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Soil Depth',
        attribute:'soilDepth',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Elevation In Meters',
        attribute:'elevationInMeters',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Survey Plan Number',
        attribute:'surveyPlanNo',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Date Of Survey',
        attribute:'dateOfSurvey',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Funded By',
        attribute:'fundedBy',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planted By Name 1',
        attribute:'plantedByName1',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planted By Name 2',
        attribute:'plantedByName2',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planted By Name 3',
        attribute:'plantedByName3',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planting Distance',
        attribute:'plantingDistance',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Date Of Planting',
        attribute:'dateOfPlanting',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'comments Or Remarks',
        attribute:'comments',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'No of plants confirmed',
        attribute:'noPlantsConfirmed',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Confirmation Of Censes',
        attribute:'confirmationOfCenses',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm btn-tools btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/tools.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.getTreesByMatureField(row)},
        width:32
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editMatureFields(row)},
        width:32
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteMatureFields(row)},
        width:32,
      }
    ]
  };

  constructor(
      private matureFieldsService : MatureFieldsService,
      private activatedRoute : ActivatedRoute,
      private modalService : BsModalService,
      private notificationService : ToastrService,
      private urlService: UrlService

  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.getAllMatureFields();
  }

  /**
   * Load student add modal
   */
  loadAddModal(){
    const initialState = {};
    let modalRef = this.modalService.show(MatureFieldsAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllMatureFields();
    });
  }

  /**
   * Get all matureFields
   */
  getAllMatureFields(){
    const companyName = this.urlService.getHostName(window.location);
    console.log(companyName);
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.matureFieldsService.getAllMatureFields(companyName,'filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.matureFields  = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.matureFields = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllMatureFields();
  }

  /**
   * Load edit matureFields modal
   */
  editMatureFields(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(MatureFieldsEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllMatureFields();
    });
  }

  getTreesByMatureField(data){
    const initialState = {
      param : data
    };
    let modalRef = this.modalService.show(MatureFieldsTreesComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllMatureFields();
    });
  }

  deleteMatureFields(data){
    
    const initialState = {
      title: 'Are you sure that you want to Delete the Selected Mature Fields?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: false,
      titleBackground : "bg-warning-emphasized",
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        this.loading = true;
        this.matureFieldsService.delete(data.matureFieldCode).subscribe(res=>{
          console.log(res);
          this.loading = false;
          this.notificationService.success('Mature Fields has been Deleted successfully!', 'Success');
          this.getAllMatureFields();
        },error=>{
          console.log(error);
          if(error.status == 200){
            this.notificationService.success('Mature Fields has been Deleted successfully!', 'Success');
          }else if(error.status == 412){
            this.notificationService.error('Mature Fields Deletion failed due to it\'s being used!', 'Error');
          }else{
            this.notificationService.error('Mature Fields Deletion failed!', 'Error');
          }
          
          this.loading = false;
          this.getAllMatureFields();
        },()=>{
          this.loading = false;
          
        });
        

      }
      else {
        this.loading = false;
        // this.currentSpinnerText = "Loading Project Systems...";
      }; // do nothing
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllMatureFields();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllMatureFields();
  }
}
