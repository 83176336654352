import { RefdataService } from 'src/app/services/refdata.service';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';
import { validateForm } from 'src/app/shared/helper';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent {

  roleData:any[] = [];
  userGroupData:any[] = [];
  initialState:any;
  param:any;
  refData:any;

  event: EventEmitter<BsModalRef> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;

  companyEditForm:any;
  parentCompanyList: any[] = [];
  countryList: any[]=[];
  estateCodeList: any[]=[];
  
  sqldate: string;
  sqldate2: string;
  
  dtNationalizationObj:Date;
  dtIncorporationObj:Date;



  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private companyService: CompanyService,
      private urlService : UrlService,
      private refDataService : RefdataService
  ) { 
    

  }

  // parseSqlDateFormat(sqlDate: string): { year: number, month: number, day: number } {
  //   const dateParts = sqlDate.split('-');
  //   const year = parseInt(dateParts[0], 10);
  //   const month = parseInt(dateParts[1], 10);
  //   const day = parseInt(dateParts[2], 10);
  //   return { year, month, day };
  // }


 initFormValues(){
    this.sqldate = this.param.dateOfNationalization;
    this.sqldate2 = this.param.dateOfIncorporation;

    this.dtNationalizationObj = new Date(this.param.dateOfNationalization);
    this.dtIncorporationObj = new Date(this.param.dateOfIncorporation);

    this.companyEditForm = new UntypedFormGroup(
        {
          parentCompanyCode: new UntypedFormControl(this.param.parentCompanyCode,[ Validators.required, Validators.maxLength(15)]),
          companyCode: new UntypedFormControl(this.param.companyCode,[ Validators.required, Validators.maxLength(15)]),
          companyName: new UntypedFormControl(this.param.companyName,[ Validators.maxLength(50)]),
          address: new UntypedFormControl(this.param.address,[ Validators.maxLength(100)]),
          city: new UntypedFormControl(this.param.city,[ Validators.maxLength(100)]),
          country: new UntypedFormControl(this.param.country,[ Validators.maxLength(100)]),
          telephoneNumber: new UntypedFormControl(this.param.telephoneNumber,[ Validators.maxLength(50)]),
          faxNumber: new UntypedFormControl(this.param.faxNumber,[ Validators.maxLength(50)]),
          emailAddress: new UntypedFormControl(this.param.emailAddress,[ Validators.maxLength(50), Validators.email]),
          webAddress: new UntypedFormControl(this.param.webAddress,[ Validators.max(50)]),
          dateOfNationalization: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate)),
          dateOfIncorporation: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate2)),
          businessRegistrationNumber: new UntypedFormControl(this.param.businessRegistrationNumber,[Validators.maxLength(50)]),
        }
      );
      
    validateForm(this.companyEditForm);

  }


  convertToNgbDateStruct(dateString: string): NgbDateStruct | null {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('-');
    return {
      year: parseInt(parts[0], 10),
      month: parseInt(parts[1], 10),
      day: parseInt(parts[2], 10)
    };
  }

  countryChanged(event) {
  }

  parentCompanyCodeChanged(event) {
  }


  ngOnInit(): void {
    this.initFormValues();
    
    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
    });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    this.refDataService.getCompanyRefData(companyCode).subscribe((data)=>{
      this.refData = data;
      this.countryList = this.refData.countries;
      this.companyEditForm.controls["country"].patchValue(this.refData.countries[0].id);
      this.companyEditForm.controls["country"].disable();
      // console.log(this.refData.countries[0].id);

      this.parentCompanyList.push(this.refData.parentCompany);
      this.companyEditForm.controls["parentCompanyCode"].patchValue(this.refData.parentCompany.parentCompanyCode);
      this.companyEditForm.controls["parentCompanyCode"].disable();
      // console.log(this.refData.parentCompany);
    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }

  updateCompany(event) {
    // if (this.companyEditForm.valid) {

      this.loadingBtn = true;
      let companyCode = this.param.companyCode;
      let submitData = this.companyEditForm.getRawValue();
      submitData.dateOfIncorporation = this.myformatDate( this.companyEditForm.controls["dateOfIncorporation"].value);
      submitData.dateOfNationalization = this.myformatDate( this.companyEditForm.controls["dateOfNationalization"].value);

      console.log(submitData);
      
      this.companyService.update(companyCode, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Company has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Company has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Company has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    // } else {
    //   validateForm(this.companyEditForm);
    // }
  }


}
