import { formatDate } from '@angular/common';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RefdataService } from 'src/app/services/refdata.service';
import { TreeDetailsService } from 'src/app/services/tree-details.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-tree-details-add',
  templateUrl: './tree-details-add.component.html',
  styleUrls: ['./tree-details-add.component.scss']
})
export class TreeDetailsAddComponent {

  param:any;
  refData : any;
  model1: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  specieCodeList: any[]=[];
  remarksList: any[]=[];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  treeDetailsAddForm = new UntypedFormGroup({
    treeID: new UntypedFormControl(''),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    divisionCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    fieldCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    specieCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    treeNo: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    remarks: new UntypedFormControl('',[Validators.maxLength(50)]),
    girthBrestHeight: new UntypedFormControl('',[Validators.maxLength(10)]),
    height: new UntypedFormControl('',[Validators.maxLength(10)]),
    dateOfMeasure: new UntypedFormControl(''),
    gpsLocationLati: new UntypedFormControl(''),
    gpsLocationLong: new UntypedFormControl(''),
    gpsLocation: new UntypedFormControl('',[Validators.maxLength(100)])
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private treeDetailsService : TreeDetailsService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) { 
    

  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

specieCodeChanged($event) {
}

remarksChanged($event) {
}

  ngOnInit(): void {

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Estates Completed");
    });

    this.refDataService.getRemarks().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.remarksList.push({
          id: item.remarkId, 
          value: item.remark 
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Remarks Completed");
    });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.specieCodeList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Specie Codes Completed");
    });

    this.treeDetailsAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }

  createNewTreeDetail(){
    let formData = this.treeDetailsAddForm.getRawValue();
    console.log(formData);

    formData.dateOfMeasure = this.myformatDate( this.treeDetailsAddForm.controls["dateOfMeasure"].value);

    // console.log(formData.gpsLocationLong);
    // console.log(formData.gpsLocationLati);
    if (formData.gpsLocationLong && formData.gpsLocationLati) {
      formData.gpsLocation = `${formData.gpsLocationLong},${formData.gpsLocationLati}`;
    } else if (!formData.gpsLocationLong && !formData.gpsLocationLati) {
      formData.gpsLocation = "";
    } else {
      this.loadingBtn = false;
      this.notification.error('Both Longitude and Latitude are required', 'Error');
      return;
    }
    console.log(formData.gpsLocation);

    this.loadingBtn = true;
    this.treeDetailsService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Tree Detail has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Tree Detail has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Tree Detail already exist', 'Error');
      }
      else {
        this.notification.error('Tree Detail has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }

}
