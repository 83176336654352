import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-harvesting-plan-add',
  templateUrl: './harvesting-plan-add.component.html',
  styleUrls: ['./harvesting-plan-add.component.scss']
})
export class HarvestingPlanAddComponent {

  refData : any;
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  divisionByCompanyCodeList: any[]=[];
  fieldCodeList: any[]=[];
  planYearList: any[]=[];
  harvestingMonthsList: any[]=[];
  harvestigType: any[]=[
    { id: 1, value: 'Harvest' },
    { id: 2, value: 'Thinning-Out' }
  ];
  filterData = [];
  planMonthList: any[] = [
    { id: 1, value: 'January' },
    { id: 2, value: 'February' },
    { id: 3, value: 'March' },
    { id: 4, value: 'April' },
    { id: 5, value: 'May' },
    { id: 6, value: 'June' },
    { id: 7, value: 'July' },
    { id: 8, value: 'August' },
    { id: 9, value: 'September' },
    { id: 10, value: 'October' },
    { id: 11, value: 'November' },
    { id: 12, value: 'December' }
  ];

  loadingBtn:boolean = false;
  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  disableButton:Boolean = false;

  harvestingPlanAddForm = new UntypedFormGroup({
      companyCode: new UntypedFormControl(''),
      divisionCode: new UntypedFormControl(''),
      hpShortCode: new UntypedFormControl(''),
      planYear: new UntypedFormControl(''),
      planMonth: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      status: new UntypedFormControl(''),
      workFlowStatus: new UntypedFormControl(''),
      harvestingType: new UntypedFormControl('')
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private harvestingPlanService: HarvestingPlanService,
      private harvestingMasterPlanService: HarvestingMasterPlanService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) { 
    

  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }


  planYearsList($event) {
  }

  planMonthsList($event) {
  }

  harvestigTypeChanged($event) {
  }

  generatePlanYearList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.planYearList.push({ id: year, value: year.toString() });
    }
  }

  updateDescription() {
    const year = this.harvestingPlanAddForm.get('planYear').value;
    const monthId = this.harvestingPlanAddForm.get('planMonth').value;

    const selectedMonth = this.planMonthList.find(month => month.id === monthId);

    if (year && selectedMonth) {
      const description = `${year} - ${selectedMonth.value} Plan `;
      this.harvestingPlanAddForm.get('description').setValue(description);
    }
  }

  generateHpShortCode(){
    const year = this.harvestingPlanAddForm.get('planYear').value;
    const monthId = this.harvestingPlanAddForm.get('planMonth').value;

    const selectedMonth = this.planMonthList.find(month => month.id === monthId);

    if (year && selectedMonth) {
      const monthShortName = selectedMonth.value.slice(0, 3);
      const hpShortCode = `${year}-${monthShortName}`;
      this.harvestingPlanAddForm.controls['hpShortCode'].setValue(hpShortCode);
    }
  }

  // onChange(){
  //   console.log(this.harvestingPlanAddForm.getRawValue());
  // }

  ngOnInit(): void {

    var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

      this.refDataService.getDivisionsByCompanyCode(companyCode).subscribe((data)=>{
        this.refData = data;
        console.log("Div Codes ",this.refData);
  
        this.refData.forEach(item => {
          this.divisionByCompanyCodeList.push(item.divisionCode);
        });
  
      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Div Codes Completed");
      });

    // this.estateCodeChanged(this.param.estateCode);
    // this.divisionCodeChanged(this.param.divisionCode);
      
    this.generatePlanYearList(1980, 2050);

    let hostName = this.urlService.getHostName(window.location);
          console.log(hostName);
        this.companyCodeList.push(hostName);
        console.log("Company Code List: ",this.companyCodeList);
        
        this.harvestingPlanAddForm.controls["companyCode"].patchValue(hostName);
        this.harvestingPlanAddForm.controls["companyCode"].disable();
    

        this.harvestingPlanAddForm.get('planYear').valueChanges.subscribe(() => {
          this.updateDescription();
          this.generateHpShortCode();
        });
        this.harvestingPlanAddForm.get('planMonth').valueChanges.subscribe(() => {
          this.updateDescription();
          this.generateHpShortCode();
        });
    
    // this.exportUrl = environment.apiUrl + "/projects/"+this.projectNumber+"/tools/export";
  

    this.harvestingPlanAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }


  createNewHarvestingPlan(){
    let formData = this.harvestingPlanAddForm.getRawValue();
    formData.status = 1;
    formData.workFlowStatus = 1;
    console.log("This is form Data:",formData);
    
    this.loadingBtn = true;
    this.harvestingPlanService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Harvesting Plan has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Harvesting Plan has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Harvesting Plan already exist', 'Error');
      }
      else {
        this.notification.error('Harvesting Plan has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }


}
