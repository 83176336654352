import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ImmatureFieldsService } from 'src/app/services/immature-fields.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { ImmatureFieldsEditComponent } from './components/immature-fields-edit/immature-fields-edit.component';
import { ImmatureFieldsAddComponent } from './components/immature-fields-add/immature-fields-add.component';
import { HarvestingAddModalComponent } from 'src/app/shared/components/harvesting-modal/harvesting-add-modal/harvesting-add-modal.component';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-immature-fields',
  templateUrl: './immature-fields.component.html',
  styleUrls: ['./immature-fields.component.scss']
})
export class ImmatureFieldsComponent {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  immatureFields:any = [];
  rowData:any;
  harvestedTrees: any = [];
  harvestedTreesObj : any;
  harvestingType : Number = 2;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'matureFieldCode',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:4,
      right:3
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.immatureFieldsService.getAllImmatureFields("distinct-values", filterData, params)},
    columns:[
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Estate Code',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Field Code',
        attribute:'fieldCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Specie Code',
        attribute:'specieCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Field Extent',
        attribute:'fieldExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Year Of Planting',
        attribute:'yearofPlanting',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Initial Plants',
        attribute:'initialPlants',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'No Present Seeding Extent',
        attribute:'noPresentSeedingExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Terrain',
        attribute:'terrain',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Distance From MainRoad',
        attribute:'distanceFromMainRoad',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Slope',
        attribute:'slope',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'SoilType',
        attribute:'soilType',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Soil Depth',
        attribute:'soilDepth',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Elevation In Meters',
        attribute:'elevationInMeters',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Survey PlanNo',
        attribute:'surveyPlanNo',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Date Of Survey',
        attribute:'dateOfSurvey',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Survey Plan JPG Tag',
        attribute:'surveyPlanJPGTag',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Funded By',
        attribute:'fundedBy',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Planted By Name 1',
        attribute:'plantedByName1',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planted By Name 2',
        attribute:'plantedByName2',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planted By Name 3',
        attribute:'plantedByName3',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Planting Distance',
        attribute:'plantingDistance',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Date Of Planting',
        attribute:'dateOfPlanting',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Comments',
        attribute:'comments',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'No Plants Confirmed',
        attribute:'noPlantsConfirmed',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Confirmation Of Censes',
        attribute:'confirmationOfCenses',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'1st Thining Out Date',
        attribute:'thiningOutDate1st',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'2nd Thining Out Date',
        attribute:'thiningOutDate2nd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'3rd Thining Out Date',
        attribute:'thiningOutDate3rd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'1st Thining Cut',
        attribute:'thiningCut1st',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'2nd Thining Cut',
        attribute:'thiningCut2nd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'3rd Thining Cut',
        attribute:'thiningCut3rd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'1st Thining Remaining',
        attribute:'thiningRemaining1st',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'2nd Thining Remaining',
        attribute:'thiningRemaining2nd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'3rd Thining Remaining',
        attribute:'thiningRemaining3rd',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        renderComponent: '<a class="btn btn-info btn-sm btn-tools btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/tools.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.addToHarvestingPlan(row)},
        width:32,
        hideFunction: (row)=>{return this.harvestedTrees.includes(row.immatureFieldCode)}
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editImmatureFields(row)},
        width:32
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteImmatureFields(row)},
        width:32
      }
    ]
  };

  constructor(
      private immatureFieldsService : ImmatureFieldsService,
      private activatedRoute : ActivatedRoute,
      private modalService : BsModalService,
      private notificationService : ToastrService,
      private harvestingPlanService : HarvestingPlanService,
      private urlService : UrlService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.getAllImmatureFields();
  }

  /**
   * Load student add modal
   */
  loadAddModal(){
    const initialState = {};
    let modalRef = this.modalService.show(ImmatureFieldsAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllImmatureFields();
    });
  }

  /**
   * Get all matureFields
   */
  getAllImmatureFields(){
    const companyCode = this.urlService.getHostName(window.location);
    this.loading = true;
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.immatureFieldsService.getAllImmatureFields(companyCode,'filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.immatureFields  = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
    this.addedThininngOutFields();
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.immatureFields = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllImmatureFields();
  }

  /**
   * Load edit matureFields modal
   */
  editImmatureFields(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(ImmatureFieldsEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllImmatureFields();
    });
  }

  addToHarvestingPlan(data){
    const initialState = {
      param : data
    };
    let modalRef = this.modalService.show(HarvestingAddModalComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllImmatureFields();
    });
  }

  addedThininngOutFields(){
    this.harvestingPlanService.getHarvestedTreesByHarvestingType(this.harvestingType).subscribe(res=>{
      this.harvestedTreesObj = res;
      this.harvestedTrees = this.harvestedTreesObj;
      console.log("Harvested Trees:",this.harvestedTrees);
    })
  }

  deleteImmatureFields(data){
    
    const initialState = {
      title: 'Are you sure that you want to Delete the Selected Immature Fields?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: false,
      titleBackground : "bg-warning-emphasized",
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        this.loading = true;
        this.immatureFieldsService.delete(data.immatureFieldCode).subscribe(res=>{
          console.log(res);
          this.loading = false;
          this.notificationService.success('Immature Fields has been Deleted successfully!', 'Success');
          this.getAllImmatureFields();
        },error=>{
          console.log(error);
          if(error.status == 200){
            this.notificationService.success('Immature Fields has been Deleted successfully!', 'Success');
          }else if(error.status == 412){
            this.notificationService.error('Immature Fields Deletion failed due to it\'s being used!', 'Error');
          }else{
            this.notificationService.error('Immature Fields Deletion failed!', 'Error');
          }
          
          this.loading = false;
          this.getAllImmatureFields();
        },()=>{
          this.loading = false;
          
        });
        

      }
      else {
        this.loading = false;
        // this.currentSpinnerText = "Loading Project Systems...";
      }; // do nothing
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllImmatureFields();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllImmatureFields();
  }
}
