import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RefdataService } from 'src/app/services/refdata.service';
import { TreeDetailsService } from 'src/app/services/tree-details.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-tree-details-edit',
  templateUrl: './tree-details-edit.component.html',
  styleUrls: ['./tree-details-edit.component.scss']
})
export class TreeDetailsEditComponent {

    roleData:any[] = [];
    userGroupData:any[] = [];
    initialState:any;
    param:any;
    refData : any;
  
    event: EventEmitter<any> = new EventEmitter();
    ismatch:Boolean = true;
    loadingBtn : Boolean = false;
    disableButton:Boolean = false;
    treeDetailsEditForm:any;

    estateCodeList: any[]=[];
    divisionCodeList: any[]=[];
    fieldCodeList: any[]=[];
    specieCodeList: any[]=[];
    remarksList: any[]=[];

    sqldate: string;  
    dtMeasureObj:Date;

    splitLocation:String;
  
    constructor(
        public bsModalRef : BsModalRef,
        private notificationService : ToastrService,
        private treeDetailssService: TreeDetailsService,
        private refDataService: RefdataService,
      private urlService: UrlService
    ) { 
      
  
    }
  
    ngOnInit(): void {
        this.initFormValues();

        var companyCode = this.urlService.getHostName(window.location);
        this.refDataService.getEstateList(companyCode).subscribe((data)=>{
          this.refData = data;
          this.refData.forEach(estate => {
            this.estateCodeList.push(estate.estateCode);
          });

        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Estates Completed");
        });

        this.estateCodeChanged(this.param.estateCode);
        this.divisionCodeChanged(this.param.divisionCode);


        this.refDataService.getRemarks().subscribe((data)=>{
          this.refData = data;
          console.log(this.refData);
    
          this.refData.forEach(item => {
            this.remarksList.push({
              id: item.remarkId, 
              value: item.remark 
            });
          });
    
        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Remarks Completed");
        });

        this.refDataService.getSpecieCodeList().subscribe((data)=>{
          this.refData = data;
          console.log(this.refData);
  
          this.refData.forEach(item => {
            this.specieCodeList.push({
              id: item.speciesCode, 
              value: `${item.speciesCode} - ${item.description}`
            });
          });
  
        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Specie Codes Completed");
        });

    }
  
  
   initFormValues(){
      this.sqldate = this.param.dateOfMeasure;
      this.dtMeasureObj = new Date(this.param.dateOfMeasure);

      this.splitLocation = this.param.gpsLocation;
      // console.log(this.splitLocation);
      
      let longitude = '';
      let latitude = '';

      if (this.splitLocation) {
          let locationParts = this.splitLocation.split(',');
          if (locationParts.length === 2) {
              longitude = locationParts[0].trim();
              latitude = locationParts[1].trim();
          }
      }

      this.treeDetailsEditForm = new UntypedFormGroup(
          {
            treeID: new UntypedFormControl(this.param.treeID),
            estateCode: new UntypedFormControl(this.param.estateCode,[Validators.required, Validators.maxLength(15)]),
            divisionCode: new UntypedFormControl(this.param.divisionCode,[Validators.required, Validators.maxLength(15)]),
            fieldCode: new UntypedFormControl(this.param.fieldCode,[Validators.required, Validators.maxLength(15)]),
            specieCode: new UntypedFormControl(this.param.specieCode,[Validators.required, Validators.maxLength(15)]),
            treeNo: new UntypedFormControl(this.param.treeNo,[Validators.required, Validators.maxLength(15)]),
            remarks: new UntypedFormControl(this.param.remarks,[Validators.maxLength(100)]),
            girthBrestHeight: new UntypedFormControl(this.param.girthBrestHeight,[Validators.maxLength(10)]),
            height: new UntypedFormControl(this.param.height,[Validators.maxLength(10)]),
            dateOfMeasure: new UntypedFormControl(this.convertToNgbDateStruct(this.sqldate)),
            gpsLocationLati: new UntypedFormControl(latitude),
            gpsLocationLong: new UntypedFormControl(longitude),
            gpsLocation: new UntypedFormControl(this.param.gpsLocation,[Validators.maxLength(100)])
          }
        );
          
        validateForm(this.treeDetailsEditForm);
    
    }

  convertToNgbDateStruct(dateString: string): NgbDateStruct | null {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('-');
    return {
      year: parseInt(parts[0], 10),
      month: parseInt(parts[1], 10),
      day: parseInt(parts[2], 10)
    };
  }
  
  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  remarksChanged($event) {
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }
  
  updateTreeDetail(event) {
    if (this.treeDetailsEditForm.valid) {

      this.loadingBtn = true;
      let treeID = this.param.treeID;
      let submitData = this.treeDetailsEditForm.getRawValue();

      submitData.dateOfMeasure = this.myformatDate( this.treeDetailsEditForm.controls["dateOfMeasure"].value);
      
      // submitData.gpsLocation = `${submitData.gpsLocationLong},${submitData.gpsLocationLati}`;
      if (submitData.gpsLocationLong && submitData.gpsLocationLati) {
        submitData.gpsLocation = `${submitData.gpsLocationLong},${submitData.gpsLocationLati}`;
      } else if (!submitData.gpsLocationLong && !submitData.gpsLocationLati) {
        submitData.gpsLocation = "";
      } else {
        this.loadingBtn = false;
        this.notificationService.error('Both Longitude and Latitude are required', 'Error');
        return;
      }
      console.log(submitData.gpsLocation);
      
      
      this.treeDetailssService.update(treeID, submitData).subscribe(res => {
        if (res) {
          this.loadingBtn = false;
          this.notificationService.success('Tree has been updated', 'Success');
          this.bsModalRef.hide();
            this.event.emit();
        } else {
          this.loadingBtn = false;
          this.notificationService.error('Tree has not been updated', 'Error');
        }
        
      }, (error => {
        this.loadingBtn = false;
        this.notificationService.error('Tree has not been updated', 'Error');
        
      }), () => {
        this.loadingBtn = false;
      });
    } else {
      validateForm(this.treeDetailsEditForm);
    }
  }
  
}
