import { Component } from '@angular/core';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { HarvestingPlanAddComponent } from './components/harvesting-plan-add/harvesting-plan-add.component';
import { HarvestingPlanEditComponent } from './components/harvesting-plan-edit/harvesting-plan-edit.component';
import { HarvestingPlanItemsUrlComponent } from './components/harvesting-plan-items-url/harvesting-plan-items-url.component';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';

@Component({
  selector: 'app-harvesting-plan',
  templateUrl: './harvesting-plan.component.html',
  styleUrls: ['./harvesting-plan.component.scss']
})
export class HarvestingPlanComponent {

  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  estate:any = [];
  rowData:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'harvestingPlanID',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.harvestingPlanService.getAllHarvestingPlan("distinct-values", filterData, params)},
    columns:[
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:150,
      },
      {
        title:'Harvesting Plan Code',
        attribute:'hpShortCode',
        type:'text',
        // format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200
      },
      {
        title:'Description',
        attribute:'description',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Plan Year',
        attribute:'planYear',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Plan Month',
        attribute:'planMonth',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Status',
        attribute:'status',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Work Flow Status',
        attribute:'workFlowStatus',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Harvesting Type',
        attribute:'harvestingType',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editHarvestingPlan(row)},
        width:32
      },{
        renderComponent: HarvestingPlanItemsUrlComponent,
        type:'custom',
        width:30
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteHarvestingPlan(row)},
        width:32,
      }
    ]
  };

  constructor(
    private harvestingPlanService : HarvestingPlanService,
    private activatedRoute : ActivatedRoute,
    private modalService : BsModalService,
    private notificationService : ToastrService,

) {
  activatedRoute.queryParams.subscribe(params => {
    if(params['page']) this.currentPage = +params['page'];
    if(params['column']) this.sortColumn = params['column'];
    if(params['sortOrder']) this.sortOrder = params['sortOrder'];
  });
}

ngOnInit(): void {
  this.getAllHarvestingPlan();
}

loadAddModal() {
  const initialState = {};
    let modalRef = this.modalService.show(HarvestingPlanAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllHarvestingPlan();
    });
}

getAllHarvestingPlan(){
  this.loading = true;
  let params = {};
  if(this.currentPage) params['page'] = this.currentPage-1;
  if(this.pageSize) params['pageSize'] = this.pageSize;
  if(this.sortColumn) params['column'] = this.sortColumn;
  if(this.sortOrder) params['sortOrder'] = this.sortOrder;
  this.harvestingPlanService.getAllHarvestingPlan('filtered-data', this.filterData, params).subscribe(res=>{
    if(res && res['page']){
      this.pageCount = res['page']['totalPages'];
      this.estate  = res['page']['content'];
      this.loading = false;
    }
    if(res && res['tablePropertyMap']){
      let columns = this.settings.columns;
      let needFillColumnAndTable = false;
      for(let column of columns){
        if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
      }
      if(!needFillColumnAndTable) return;

      let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
      for(let i=0; i<columns.length; i++){
        let data = tablePropertyMap[columns[i].attribute];
        if(!data) continue;
        columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
        columns[i].filter['table'] = data['table'];
      }
    }
    this.settings['isNative'] = false;
  });
}

filterOrSortChange(event){
  if(!event){
    this.currentPage = 1;
    this.estate = [];
    return;
  }
  if(!event['sortChanged']) this.currentPage = 1;
  this.sortColumn = event['sortColumn'];
  this.sortOrder = event['sortOrder'];
  this.filterData = event['filterData'];

  this.getAllHarvestingPlan();
}

editHarvestingPlan(data){
  const initialState = {
    param:data
  };
  let modalRef = this.modalService.show(HarvestingPlanEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
  modalRef.content.event.subscribe(res => {
    this.getAllHarvestingPlan();
  });
}

deleteHarvestingPlan(data){
    
  const initialState = {
    title: 'Are you sure that you want to Delete the Selected Harvesting Plan?',
    okBtnName: 'OK',
    closeBtnName: 'Cancel',
    isCommentVisible: false,
    titleBackground : "bg-warning-emphasized",
  };
  let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
  modalRef.content.event.subscribe(res =>
  {
    if(res?.result?.action == 'ok')
    {
      this.loading = true;
      this.harvestingPlanService.delete(data.harvestingPlanId).subscribe(res=>{
        console.log(res);
        this.loading = false;
        this.notificationService.success('Harvesting Plan has been Deleted successfully!', 'Success');
        this.getAllHarvestingPlan();
      },error=>{
        console.log(error);
        if(error.status == 200){
          this.notificationService.success('Harvesting Plan has been Deleted successfully!', 'Success');
        }else if(error.status == 412){
          this.notificationService.error('Harvesting Plan Deletion failed due to it\'s being used!', 'Error');
        }else{
          this.notificationService.error('Harvesting Plan Deletion failed!', 'Error');
        }
        
        this.loading = false;
        this.getAllHarvestingPlan();
      },()=>{
        this.loading = false;
        
      });
      

    }
    else {
      this.loading = false;
      // this.currentSpinnerText = "Loading Project Systems...";
    }; // do nothing
  });
}

changePage(currentPage){
  this.currentPage = currentPage;
  this.getAllHarvestingPlan();
}

changePageSize(pageSize){
  this.pageSize = pageSize;
  this.getAllHarvestingPlan();
}

}
