<div class="modal-header">
    <h5 class="modal-title pull-left">Edit Forestry Management Plan Details</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingMasterPlanDetailsEditForm"
    (ngSubmit)="updateHarvestingPlanDetails()"
  >
    <div class="modal-body-edit-master-plan-detail">
      <div class="form-group row">
        <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Company Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="companyCodeList"
            formControlName="companyCode"
            placeholder="Company Code"
            (select)="companyCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'companyCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['companyCode'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['companyCode'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['companyCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['companyCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Estate Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="estateCodeList"
            placeholder="Estate Code"
            formControlName="estateCode"
            (select)="estateCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'estateCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['estateCode'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['estateCode'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['estateCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['estateCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label
          for="divisionCode"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Division Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="divisionCodeList"
            placeholder="Division Code"
            formControlName="divisionCode"
            (select)="divisionCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'divisionCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['divisionCode'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['divisionCode'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['divisionCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['divisionCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Field Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="fieldCodeList"
            placeholder="Field Code"
            formControlName="fieldCode"
            (select)="fieldCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'fieldCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['fieldCode'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['fieldCode'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['fieldCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['fieldCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="crop" class="col-sm-4 col-form-label col-form-label-sm"
          >Crop</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="100"
            class="form-control"
            formControlName="crop"
            placeholder="Crop"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'crop'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['crop'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['crop'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['crop'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingMasterPlanDetailsEditForm.controls['crop'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="specieCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Specie Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            optionValueField="id"
            optionTextField="value"
            [items]="specieCodeList"
            placeholder="Specie Code"
            formControlName="specieCode"
            (select)="specieCodeChanged($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'specieCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['specieCode'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['specieCode'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['specieCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['specieCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="trees" class="col-sm-4 col-form-label col-form-label-sm"
          >Trees</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="trees"
            placeholder="Trees"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'trees'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['trees'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['trees'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['trees'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['trees'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="gbHeight" class="col-sm-4 col-form-label col-form-label-sm"
          >GB Height</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="gbHeight"
            placeholder="GB Height"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'gbHeight'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['gbHeight'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['gbHeight'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['gbHeight'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['gbHeight'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="volumeCDM" class="col-sm-4 col-form-label col-form-label-sm"
          >Volume CDM</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="volumeCDM"
            placeholder="Volume CDM"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'volumeCDM'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['volumeCDM'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['volumeCDM'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['volumeCDM'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['volumeCDM'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="mv" class="col-sm-4 col-form-label col-form-label-sm"
          >M:V</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="mv"
            placeholder="M:V"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'mv'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['mv'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['mv'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['mv'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['mv'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="age" class="col-sm-4 col-form-label col-form-label-sm"
          >Age</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="age"
            placeholder="Age"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'age'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['age'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['age'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['age'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['age'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label for="extHectare" class="col-sm-4 col-form-label col-form-label-sm"
          >Exact Hectare</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="10"
            appDecimal="2"
            class="form-control"
            formControlName="extHectare"
            placeholder="Exact Hectare"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'extHectare'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['extHectare'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['extHectare'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['extHectare'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingMasterPlanDetailsEditForm.controls['extHectare'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label
          for="year"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Year
        </label>
        <div class="col-sm-8">
          <ngx-select
            optionValueField="id"
            optionTextField="value"
            [items]="harvestingYearList"
            placeholder="Year"
            formControlName="year"
            (select)="yearList($event)"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'year'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['year'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['year'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['year'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingMasterPlanDetailsEditForm.controls['year'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="operation" class="col-sm-4 col-form-label col-form-label-sm"
          >Operation</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="15"
            class="form-control"
            formControlName="operation"
            placeholder="Operation"
            appInfoPromptBox
            [page]="'master-plan-detail'"
            [component]="'edit'"
            [field]="'operation'"
          />
          <div
            *ngIf="
              harvestingMasterPlanDetailsEditForm.controls['operation'].invalid &&
              (harvestingMasterPlanDetailsEditForm.controls['operation'].dirty ||
                harvestingMasterPlanDetailsEditForm.controls['operation'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingMasterPlanDetailsEditForm.controls['operation'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
  