import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { initialState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';
import { ToastrService } from 'ngx-toastr';
import { MtReportsComponent } from './components/mt-reports/mt-reports.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

  constructor(
    private activatedRoute : ActivatedRoute,
    private modalService : BsModalService,
  ) { }

  ngonInit() {
    console.log("Reports");
    
  }

  mtReports() {
    const initialState = {};
    let modalRef = this.modalService.show(MtReportsComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
      modalRef.content.event.subscribe(res => {
      });
  }

}
