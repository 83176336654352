<div class="modal-header">
    <h5 class="modal-title pull-left">Add to the Harvesting Plan</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
<!-- <form class="p-3" [formGroup]="addToHarvestingPlanForm" (ngSubmit)="addToHarvestingPlan()"> -->
    <form
    class="p-3"
    [formGroup]="addToHarvestingPlanForm"
    (ngSubmit)="addToHarvestingPlan()"
  >
    <div class="modal-body-add-">
        <div class="form-group row">
            <label for="harvestingPlanId" class="col-sm-4 col-form-label col-form-label-sm"
                >Harvesting Plan 
            </label>
        <div class="col-sm-8">
            <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="harvestingPlanList"
            formControlName="harvestingPlanId"
            placeholder="Harvesting Plan"
            (select)="harvestingPlanChanged($event)"
            appInfoPromptBox
            [page]="'add-harvesting-plan'"
            [component]="'add'"
            [field]="'harvestingPlanId'"
            ></ngx-select>
            <div
            *ngIf="
                addToHarvestingPlanForm.controls['harvestingPlanId'].invalid &&
                (addToHarvestingPlanForm.controls['harvestingPlanId'].dirty ||
                addToHarvestingPlanForm.controls['harvestingPlanId'].touched)
            "
            class="ng-validator"
            >
            <div
                *ngIf="addToHarvestingPlanForm.controls['harvestingPlanId'].errors.required"
            >
                This field is required.
            </div>
            </div>
        </div>
    </div>

    </div>
    <div class="modal-footer">
        <button
          type="submit"
          appButtonLoader
          [isLoading]="loadingBtn"
          [disabled]="disableButton"
          class="btn btn-primary"
        >
          Add
        </button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
          Close
        </button>
      </div>
</form>
  
  
