import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DivisionService } from 'src/app/services/division.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { DivisionAddComponent } from './components/division-add/division-add.component';
import { DivisionEditComponent } from './components/division-edit/division-edit.component';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss']
})
export class DivisionComponent {
  
  faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  division:any = [];
  rowData:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'divisioneCode',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    freezeColumns:{
      enabled:true,
      left:4,
      right:2
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.divisionService.getAllDivisions("distinct-values", filterData, params)},
    columns:[
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Estate Code',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Name of Division',
        attribute: 'divisionName',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'Seeding Extent',
        attribute: 'seedingExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'VP Extent',
        attribute:'vpExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Revenue Extent',
        attribute:'revenueExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Immature - Tea Extent',
        attribute:'immatureTeaExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Immature - Rubber Extent',
        attribute:'immatureRubberExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Fuelwood Extent',
        attribute:'fuelwoodExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Forestry Extent',
        attribute:'forestryExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Total Extent',
        attribute:'totalExtent',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Manager Stakeholder ID',
        attribute:'managerStakeholderID',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editDivision(row)},
        width:32
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteDivision(row)},
        width:32,
      }
    ]
  };

  constructor(
    private divisionService : DivisionService,
    private activatedRoute : ActivatedRoute,
    private modalService : BsModalService,
    private notificationService : ToastrService,
    private urlService : UrlService

) {
  activatedRoute.queryParams.subscribe(params => {
    if(params['page']) this.currentPage = +params['page'];
    if(params['column']) this.sortColumn = params['column'];
    if(params['sortOrder']) this.sortOrder = params['sortOrder'];
  });
}

ngOnInit(): void {
  this.getAllDivisions();
}


loadAddModal() {
  const initialState = {};
    let modalRef = this.modalService.show(DivisionAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllDivisions();
    });
}

getAllDivisions(){
  const companyCode = this.urlService.getHostName(window.location);
  this.loading = true;
  let params = {};
  if(this.currentPage) params['page'] = this.currentPage-1;
  if(this.pageSize) params['pageSize'] = this.pageSize;
  if(this.sortColumn) params['column'] = this.sortColumn;
  if(this.sortOrder) params['sortOrder'] = this.sortOrder;
  this.divisionService.getAllDivisions(companyCode,'filtered-data', this.filterData, params).subscribe(res=>{
    if(res && res['page']){
      this.pageCount = res['page']['totalPages'];
      this.division  = res['page']['content'];
      this.loading = false;
    }
    if(res && res['tablePropertyMap']){
      let columns = this.settings.columns;
      let needFillColumnAndTable = false;
      for(let column of columns){
        if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
      }
      if(!needFillColumnAndTable) return;

      let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
      for(let i=0; i<columns.length; i++){
        let data = tablePropertyMap[columns[i].attribute];
        if(!data) continue;
        columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
        columns[i].filter['table'] = data['table'];
      }
    }
    this.settings['isNative'] = false;
  });
}

filterOrSortChange(event){
  if(!event){
    this.currentPage = 1;
    this.division = [];
    return;
  }
  if(!event['sortChanged']) this.currentPage = 1;
  this.sortColumn = event['sortColumn'];
  this.sortOrder = event['sortOrder'];
  this.filterData = event['filterData'];

  this.getAllDivisions();
}

changePage(currentPage){
  this.currentPage = currentPage;
  this.getAllDivisions();
}

changePageSize(pageSize){
  this.pageSize = pageSize;
  this.getAllDivisions();
}

editDivision(data){
  const initialState = {
    param:data
  };
  let modalRef = this.modalService.show(DivisionEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
  modalRef.content.event.subscribe(res => {
    this.getAllDivisions();
  });
}

deleteDivision(data){
    
  const initialState = {
    title: 'Are you sure that you want to Delete the Selected Division?',
    okBtnName: 'OK',
    closeBtnName: 'Cancel',
    isCommentVisible: false,
    titleBackground : "bg-warning-emphasized",
  };
  let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
  modalRef.content.event.subscribe(res =>
  {
    if(res?.result?.action == 'ok')
    {
      this.loading = true;
      this.divisionService.delete(data.divisionCode).subscribe(res=>{
        console.log(res);
        this.loading = false;
        this.notificationService.success('Division has been Deleted successfully!', 'Success');
        this.getAllDivisions();
      },error=>{
        console.log(error);
        if(error.status == 200){
          this.notificationService.success('Division has been Deleted successfully!', 'Success');
        }else if(error.status == 412){
          this.notificationService.error('Division Deletion failed due to it\'s being used!', 'Error');
        }else{
          this.notificationService.error('Division Deletion failed!', 'Error');
        }
        
        this.loading = false;
        this.getAllDivisions();
      },()=>{
        this.loading = false;
        
      });
      

    }
    else {
      this.loading = false;
      // this.currentSpinnerText = "Loading Project Systems...";
    }; // do nothing
  });
}


}
