<div class="modal-header">
  <h5 class="modal-title pull-left">Add Estate</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form class="p-3" [formGroup]="estateAddForm" (ngSubmit)="createNewEstate()">
  <div class="modal-body-add-estate">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="companyID"
          optionTextField="companyCode"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['companyCode'].invalid &&
            (estateAddForm.controls['companyCode'].dirty ||
              estateAddForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['companyCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="50"
          class="form-control"
          formControlName="estateCode"
          placeholder="Estate Code"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'estateCode'"
        />
        <div
          *ngIf="
            estateAddForm.controls['estateCode'].invalid &&
            (estateAddForm.controls['estateCode'].dirty ||
              estateAddForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['estateCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateName" class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Estate</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="50"
          class="form-control"
          formControlName="estateName"
          placeholder="Name of Estate"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'estateName'"
        />
        <div
          *ngIf="
            estateAddForm.controls['estateName'].invalid &&
            (estateAddForm.controls['estateName'].dirty ||
              estateAddForm.controls['estateName'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['estateName'].errors.required">
            This field is required.
          </div>
          <div *ngIf="estateAddForm.controls['estateName'].errors.maxlength">
            Maximum length would be 200 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="address" class="col-sm-4 col-form-label col-form-label-sm"
        >Address</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="address"
          placeholder="Address"
          appInfoPromptBox
          [page]="'company'"
          [component]="'add'"
          [field]="'address'"
        />
        <div
          *ngIf="
            estateAddForm.controls['address'].invalid &&
            (estateAddForm.controls['address'].dirty ||
              estateAddForm.controls['address'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['address'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="city" class="col-sm-4 col-form-label col-form-label-sm"
        >City</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="city"
          placeholder="City"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'city'"
        />
        <div
          *ngIf="
            estateAddForm.controls['city'].invalid &&
            (estateAddForm.controls['city'].dirty ||
              estateAddForm.controls['city'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['city'].errors.required">
            Pradeshiya Saba Area
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="telephoneNumber"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Telephone Number</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="25"
          class="form-control"
          formControlName="telephoneNumber"
          placeholder="Telephone Number"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'telephoneNumber'"
        />
        <div
          *ngIf="
            estateAddForm.controls['telephoneNumber'].invalid &&
            (estateAddForm.controls['telephoneNumber'].dirty ||
              estateAddForm.controls['telephoneNumber'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="estateAddForm.controls['telephoneNumber'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="emailAddress"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Email Address</label
      >
      <div class="col-sm-8">
        <input
          type="email"
          maxlength="50"
          class="form-control"
          formControlName="emailAddress"
          placeholder="Email Address"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'emailAddress'"
        />
        <div
          *ngIf="
            estateAddForm.controls['emailAddress'].invalid &&
            (estateAddForm.controls['emailAddress'].dirty ||
              estateAddForm.controls['emailAddress'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['emailAddress'].errors.required">
            This field is required.
          </div>
          <div *ngIf="estateAddForm.controls['emailAddress'].errors.email">
            This field must be an Email Address.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="postOffice" class="col-sm-4 col-form-label col-form-label-sm"
        >Post Office
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="postOfficeList"
          formControlName="postOffice"
          placeholder="Post Office"
          (select)="postOfficeChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'postOffice'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['postOffice'].invalid &&
            (estateAddForm.controls['postOffice'].dirty ||
              estateAddForm.controls['postOffice'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['postOffice'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="nameOfMainTown"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Name of Main town</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="nameOfMainTown"
          placeholder="Name of Main town"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'nameOfMainTown'"
        />
        <div
          *ngIf="
            estateAddForm.controls['nameOfMainTown'].invalid &&
            (estateAddForm.controls['nameOfMainTown'].dirty ||
              estateAddForm.controls['nameOfMainTown'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['nameOfMainTown'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="distanceFromMainTown"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Distance from Main town</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appDecimal="2"
          maxlength="10"
          class="form-control"
          formControlName="distanceFromMainTown"
          placeholder="Distance from Main town"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'distanceFromMainTown'"
        />
        <div
          *ngIf="
            estateAddForm.controls['distanceFromMainTown'].invalid &&
            (estateAddForm.controls['distanceFromMainTown'].dirty ||
              estateAddForm.controls['distanceFromMainTown'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              estateAddForm.controls['distanceFromMainTown'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="minimumElevation"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Minimum Elevation</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="4"
          class="form-control"
          formControlName="minimumElevation"
          placeholder="Minimum Elevation"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'minimumElevation'"
        />
        <div
          *ngIf="
            estateAddForm.controls['minimumElevation'].invalid &&
            (estateAddForm.controls['minimumElevation'].dirty ||
              estateAddForm.controls['minimumElevation'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="estateAddForm.controls['minimumElevation'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="maximumElevation"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Maximum Elevation</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="4"
          class="form-control"
          formControlName="maximumElevation"
          placeholder="Maximum Elevation"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'maximumElevation'"
        />
        <div
          *ngIf="
            estateAddForm.controls['maximumElevation'].invalid &&
            (estateAddForm.controls['maximumElevation'].dirty ||
              estateAddForm.controls['maximumElevation'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="estateAddForm.controls['maximumElevation'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="nameOfRevenueDistrict"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Revenue District
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="nameOfRevenueDistrictList"
          formControlName="nameOfRevenueDistrict"
          placeholder="Name of Revenue District"
          (select)="nameOfRevenueDistrictChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'nameOfRevenueDistrict'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['nameOfRevenueDistrict'].invalid &&
            (estateAddForm.controls['nameOfRevenueDistrict'].dirty ||
              estateAddForm.controls['nameOfRevenueDistrict'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              estateAddForm.controls['nameOfRevenueDistrict'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionalSecretary"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Divisional Secretary
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="divisionalSecretaryList"
          formControlName="divisionalSecretary"
          placeholder="Divisional Secretary"
          (select)="divisionalSecretaryChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'divisionalSecretary'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['divisionalSecretary'].invalid &&
            (estateAddForm.controls['divisionalSecretary'].dirty ||
              estateAddForm.controls['divisionalSecretary'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              estateAddForm.controls['divisionalSecretary'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="gramaNiladhariArea"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Grama Niladhari Area
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="gramaNiladhariAreaList"
          formControlName="gramaNiladhariArea"
          placeholder="Grama Niladhari Area"
          (select)="gramaNiladhariAreaChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'gramaNiladhariArea'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['gramaNiladhariArea'].invalid &&
            (estateAddForm.controls['gramaNiladhariArea'].dirty ||
              estateAddForm.controls['gramaNiladhariArea'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="estateAddForm.controls['gramaNiladhariArea'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="policeDivision"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Police Division
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="policeDivisionList"
          formControlName="policeDivision"
          placeholder="Police Division"
          (select)="policeDivisionChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'policeDivision'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['policeDivision'].invalid &&
            (estateAddForm.controls['policeDivision'].dirty ||
              estateAddForm.controls['policeDivision'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['policeDivision'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="policeArea" class="col-sm-4 col-form-label col-form-label-sm"
        >Police Area
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="policeAreaList"
          formControlName="policeArea"
          placeholder="Police Area"
          (select)="policeAreaChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'policeArea'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['policeArea'].invalid &&
            (estateAddForm.controls['policeArea'].dirty ||
              estateAddForm.controls['policeArea'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="estateAddForm.controls['policeArea'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="pradeshiyaSabaArea"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Pradeshiya Saba Area
      </label>
      <div class="col-sm-8">
        <ngx-select
          optionValueField="id"
          optionTextField="value"
          [items]="pradeshiyaSabaAreaList"
          placeholder="Pradeshiya Saba Area"
          formControlName="pradeshiyaSabaArea"
          (select)="pradeshiyaSabaAreaChanged($event)"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'pradeshiyaSabaArea'"
        ></ngx-select>
        <div
          *ngIf="
            estateAddForm.controls['pradeshiyaSabaArea'].invalid &&
            (estateAddForm.controls['pradeshiyaSabaArea'].dirty ||
              estateAddForm.controls['pradeshiyaSabaArea'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="estateAddForm.controls['pradeshiyaSabaArea'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="nameOfForestOfficer1"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Forest Officer 1</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="nameOfForestOfficer1"
          placeholder="Name of Forest Officer 1"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'nameOfForestOfficer1'"
        />
        <div
          *ngIf="
            estateAddForm.controls['nameOfForestOfficer1'].invalid &&
            (estateAddForm.controls['nameOfForestOfficer1'].dirty ||
              estateAddForm.controls['nameOfForestOfficer1'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              estateAddForm.controls['nameOfForestOfficer1'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="nameOfForestOfficer2"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Forest Officer 2</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="50"
          class="form-control"
          formControlName="nameOfForestOfficer2"
          placeholder="Name of Forest Officer 2"
          appInfoPromptBox
          [page]="'estate'"
          [component]="'add'"
          [field]="'nameOfForestOfficer2'"
        />
        <div
          *ngIf="
            estateAddForm.controls['nameOfForestOfficer2'].invalid &&
            (estateAddForm.controls['nameOfForestOfficer2'].dirty ||
              estateAddForm.controls['nameOfForestOfficer2'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              estateAddForm.controls['nameOfForestOfficer2'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfNationalization"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Nationalization</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d1"
            formControlName="dateOfNationalization"
            ngbDatepicker
            #d1="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="dateOfPrivatization"
        class="col-sm-4 col-form-label col-form-label-sm"
      >
        Date of Incorporation</label
      >
      <div class="col-sm-8">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="d2"
            formControlName="dateOfPrivatization"
            ngbDatepicker
            #d2="ngbDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="d2.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
