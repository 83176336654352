<div class="modal-header">
  <h5 class="modal-title pull-left">Add Division</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  class="p-3"
  [formGroup]="divisionAddForm"
  (ngSubmit)="createNewDivision()"
>
  <div class="modal-body-add-division">
    <div class="form-group row">
      <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Company Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="id"
          optionTextField="value"
          [items]="companyCodeList"
          formControlName="companyCode"
          placeholder="Company Code"
          (select)="companyCodeChanged($event)"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'companyCode'"
        ></ngx-select>
        <div
          *ngIf="
            divisionAddForm.controls['companyCode'].invalid &&
            (divisionAddForm.controls['companyCode'].dirty ||
              divisionAddForm.controls['companyCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['companyCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
        >Estate Code
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="estateCode"
          optionTextField="estateCode"
          [items]="estateCodeList"
          placeholder="Estate Code"
          formControlName="estateCode"
          (select)="estateCodeChanged($event)"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'estateCode'"
        ></ngx-select>
        <div
          *ngIf="
            divisionAddForm.controls['estateCode'].invalid &&
            (divisionAddForm.controls['estateCode'].dirty ||
              divisionAddForm.controls['estateCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['estateCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionCode"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Division Code</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          appMandatoryField
          maxlength="10"
          class="form-control"
          formControlName="divisionCode"
          placeholder="Division Code"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'divisionCode'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['divisionCode'].invalid &&
            (divisionAddForm.controls['divisionCode'].dirty ||
              divisionAddForm.controls['divisionCode'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['divisionCode'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="divisionName"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Name of Division</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="100"
          class="form-control"
          formControlName="divisionName"
          placeholder="Name of Division"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'divisionName'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['divisionName'].invalid &&
            (divisionAddForm.controls['divisionName'].dirty ||
              divisionAddForm.controls['divisionName'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['divisionName'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="seedingExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Seeding Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="seedingExtent"
          placeholder="Seeding Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'seedingExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['seedingExtent'].invalid &&
            (divisionAddForm.controls['seedingExtent'].dirty ||
              divisionAddForm.controls['seedingExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="divisionAddForm.controls['seedingExtent'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="vpExtent" class="col-sm-4 col-form-label col-form-label-sm"
        >VP Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="vpExtent"
          placeholder="VP Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'vpExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['vpExtent'].invalid &&
            (divisionAddForm.controls['vpExtent'].dirty ||
              divisionAddForm.controls['vpExtent'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['vpExtent'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="revenueExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Revenue Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="revenueExtent"
          placeholder="Revenue Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'revenueExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['revenueExtent'].invalid &&
            (divisionAddForm.controls['revenueExtent'].dirty ||
              divisionAddForm.controls['revenueExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="divisionAddForm.controls['revenueExtent'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="immatureTeaExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Immature - Tea Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="immatureTeaExtent"
          placeholder="Immature - Tea Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'immatureTeaExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['immatureTeaExtent'].invalid &&
            (divisionAddForm.controls['immatureTeaExtent'].dirty ||
              divisionAddForm.controls['immatureTeaExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              divisionAddForm.controls['immatureTeaExtent'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="immatureRubberExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Immature - Rubber Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="immatureRubberExtent"
          placeholder="Immature - Rubber Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'immatureRubberExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['immatureRubberExtent'].invalid &&
            (divisionAddForm.controls['immatureRubberExtent'].dirty ||
              divisionAddForm.controls['immatureRubberExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="
              divisionAddForm.controls['immatureRubberExtent'].errors.required
            "
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="fuelwoodExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Fuelwood Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="fuelwoodExtent"
          placeholder="Fuelwood Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'fuelwoodExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['fuelwoodExtent'].invalid &&
            (divisionAddForm.controls['fuelwoodExtent'].dirty ||
              divisionAddForm.controls['fuelwoodExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="divisionAddForm.controls['fuelwoodExtent'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label
        for="forestryExtent"
        class="col-sm-4 col-form-label col-form-label-sm"
        >Forestry Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="forestryExtent"
          placeholder="Forestry Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'forestryExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['forestryExtent'].invalid &&
            (divisionAddForm.controls['forestryExtent'].dirty ||
              divisionAddForm.controls['forestryExtent'].touched)
          "
          class="ng-validator"
        >
          <div
            *ngIf="divisionAddForm.controls['forestryExtent'].errors.required"
          >
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="totalExtent" class="col-sm-4 col-form-label col-form-label-sm"
        >Total Extent</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          maxlength="10"
          appDecimal="2"
          class="form-control"
          formControlName="totalExtent"
          placeholder="Total Extent"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'totalExtent'"
        />
        <div
          *ngIf="
            divisionAddForm.controls['totalExtent'].invalid &&
            (divisionAddForm.controls['totalExtent'].dirty ||
              divisionAddForm.controls['totalExtent'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['totalExtent'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="managerStakeholderID" class="col-sm-4 col-form-label col-form-label-sm"
        >Manage ID
      </label>
      <div class="col-sm-8">
        <ngx-select
          appMandatoryField
          optionValueField="managerStakeholderID"
          optionTextField="managerStakeholderID"
          [items]="managersList"
          placeholder="Manager ID"
          formControlName="managerStakeholderID"
          (select)="managersChanged($event)"
          appInfoPromptBox
          [page]="'division'"
          [component]="'add'"
          [field]="'managerStakeholderID'"
        ></ngx-select>
        <div
          *ngIf="
            divisionAddForm.controls['managerStakeholderID'].invalid &&
            (divisionAddForm.controls['managerStakeholderID'].dirty ||
              divisionAddForm.controls['managerStakeholderID'].touched)
          "
          class="ng-validator"
        >
          <div *ngIf="divisionAddForm.controls['managerStakeholderID'].errors.required">
            This field is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      appButtonLoader
      [isLoading]="loadingBtn"
      [disabled]="disableButton"
      class="btn btn-primary"
    >
      Save
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
      Close
    </button>
  </div>
</form>
