import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HarvestingRoutingModule } from './harvesting-routing.module';
import { NgxSelectComponent, NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { ReferenceDataRoutingModule } from '../reference-data/reference-data-routing.module';
import { NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { HarvestingComponent } from './harvesting-master-plan/harvesting.component';
import { HarvestingAddComponent } from './harvesting-master-plan/components/harvesting-add/harvesting-add.component';
import { HarvestingEditComponent } from './harvesting-master-plan/components/harvesting-edit/harvesting-edit.component';
import { HarvestingListUrlComponent } from './harvesting-master-plan/components/harvesting-list-url/harvesting-list-url.component';
import { HarvestingPlanComponent } from './harvesting-plan/harvesting-plan.component';
import { HarvestingPlanAddComponent } from './harvesting-plan/components/harvesting-plan-add/harvesting-plan-add.component';
import { HarvestingPlanEditComponent } from './harvesting-plan/components/harvesting-plan-edit/harvesting-plan-edit.component';
import { HarvestingPlanItemsComponent } from './harvesting-plan/components/harvesting-plan-items/harvesting-plan-items.component';
import { HarvestingPlanItemsUrlComponent } from './harvesting-plan/components/harvesting-plan-items-url/harvesting-plan-items-url.component';
import { HarvestingPlanItemEditComponent } from './harvesting-plan/components/harvesting-plan-items/components/harvesting-plan-item-edit/harvesting-plan-item-edit.component';
import { HarvestingListEditComponent } from './harvesting-master-plan/components/harvesting-list/components/harvesting-list-edit/harvesting-list-edit.component';
import { HarvestingListComponent } from './harvesting-master-plan/components/harvesting-list/harvesting-list.component';
import { HarvestingListAddComponent } from './harvesting-master-plan/components/harvesting-list/components/harvesting-list-add/harvesting-list-add.component';


@NgModule({
  declarations: [
    HarvestingComponent,
    HarvestingAddComponent,
    HarvestingEditComponent,
    HarvestingListComponent,
    HarvestingListUrlComponent,
    HarvestingListEditComponent,
    HarvestingPlanComponent,
    HarvestingPlanAddComponent,
    HarvestingPlanEditComponent,
    HarvestingPlanItemsComponent,
    HarvestingPlanItemsUrlComponent,
    HarvestingPlanItemEditComponent,
    HarvestingListAddComponent
  ],
  imports: [
    CommonModule,
    NgxSelectModule,
    NgbDatepicker,
    NgbDatepickerModule,
    ReferenceDataRoutingModule,
    NgxPaginationModule,
    ModalModule,
    TreeTableModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HarvestingRoutingModule
  ]
})
export class HarvestingModule { }
