<div class="container-fluid">
    <div class="row">
      <div class="pageTitle col-sm-6">
        <span> Harvesting Plan </span> &nbsp;<button
          id="btnAddEstate"
          type="button"
          (click)="loadAddModal()"
          class="btn btn-primary btn-sm"
          appInfoPromptBox
          [page]="'HarvestingPlan'"
          [component]="'grid'"
          [field]="'addButton'"
        >
          <fa-icon [icon]="faPlus"></fa-icon> &nbsp;&nbsp;Add
        </button>
      </div>
      <!-- <div class=" pageTitle col-sm-6 text-right">
            <button type="button" [routerLink]="previousUrl" routerLinkActive="router-link-active"  class="btn btn-primary btn-sm"><fa-icon [icon]="faBackward"></fa-icon> &nbsp;&nbsp;Back</button>
          </div> -->
    </div>
  
    <div class="data-table">
      <app-tree-table
        [spinnerVisible]="loading"
        spinnerText="Loading Harvesting Plan Details..."
        [settings]="settings"
        [data]="estate"
        [currentPage]="currentPage"
        [pageCount]="pageCount"
        (pageChangeEvent)="changePage($event)"
        (pageSizeChangeEvent)="changePage($event)"
        (filterChangeEvent)="filterOrSortChange($event)"
      ></app-tree-table>
    </div>
  </div>
  <!-- wrapper -->