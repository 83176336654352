import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RefdataService {

  constructor(private http: HttpClient) { }

  getCompanyRefData(companyCode)
  {
    return this.http.get(environment.apiUrl + "/company/companyrefdata/" + companyCode + "/");
  }

  getEstateRefData()
  {
    return this.http.get(environment.apiUrl + "/estate/estaterefdata");
  }

  getDivisionRefData()
  {
    return this.http.get(environment.apiUrl + "/division/divisionrefdata");
  }

  getFieldRefData()
  {
    return this.http.get(environment.apiUrl + "/field/fieldrefdata");
  }

  getMatureFieldRefData()
  {
    return this.http.get(environment.apiUrl + "/maturefields/maturefieldsrefdata");
  }

  getEstateList(companyCode: String)
  {
    return this.http.get(environment.apiUrl + "/estate/" + companyCode + "/listall");
  }

  getDivisionsByEstateCode(estateCode: any)
  {
    return this.http.get(environment.apiUrl + "/estate/" + estateCode + "/divisions");
  }

  getFieldsByDivisionCode(divisionCode: any)
  {
    return this.http.get(environment.apiUrl + "/division/" + divisionCode + "/fields");
  }

  getSpecieCodeList()
  {
    return this.http.get(environment.apiUrl + "/tree-species/listall");
  }

  getImmatureFieldRefData()
  {
    return this.http.get(environment.apiUrl + "/immaturefields/immaturefieldsrefdata");
  }

  getCropTypes()
  {
    return this.http.get(environment.apiUrl + "/field/croplist");
  }

  getAllImmatureCodes()
  {
    return this.http.get(environment.apiUrl + "/immaturefields/listall");
  }

  getDistance()
  {
    return this.http.get(environment.apiUrl + "/immaturefields/distance");
  }

  getRemarks()
  {
    return this.http.get(environment.apiUrl + "/tree/remarkslist");
  }

  getDivisionsByCompanyCode(companyCode : any)
  {
    return this.http.get(environment.apiUrl + "/harvesting-plan/getDivisionCodes/" + companyCode);
  }

  getHarvestingPlanCodeList()
  {
    return this.http.get(environment.apiUrl + "/harvesting-plan/harvesting-plan-list");
  }

  getAllHarvestingPlanByHarvestingType(type : any)
  {
    return this.http.get(environment.apiUrl + "/harvesting-plan/harvesting-plan-list/" + type);
  }

  getAllWorkFlowStatus()
  {
    return this.http.get(environment.apiUrl + "/harvesting-plan/work-flow");
  }
  
  getManagerByEstate(companyCode,estateCode)
  {
    return this.http.get(environment.apiUrl + "/division/manager/" + companyCode + "/" + estateCode + "/" );
  }

}
