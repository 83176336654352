import { Component, EventEmitter, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { validateForm } from 'src/app/shared/helper';
import { ToastrService } from 'ngx-toastr';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';

@Component({
  selector: 'app-harvesting-add-modal',
  templateUrl: './harvesting-add-modal.component.html',
  styleUrls: ['./harvesting-add-modal.component.scss']
})
export class HarvestingAddModalComponent {

  event: EventEmitter<any> = new EventEmitter();
  param:any;
  disableButton:Boolean = false;
  loading:Boolean=false;
  loadingBtn:Boolean=false;
  thinningOut: Boolean = false;

  companyCode:any;
  uniqueCode: any;
  treeNo: any;
  data: any;
  treeStatus:Number;
  availableQuantity:Number;
  harvestingQuantity:Number;
  harvestingType:Number;
  treeData:any;

  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  fieldCodeList: any[]=[];
  divisionCodeList: any[]=[];
  harvestingPlanList: any[]=[];
  remarksList: any[]=[];
  harvestingTypeList: any[]=[
    { id: 1, value: 'Harvest'},
    { id: 2, value: 'Thinnig-Out'}
  ];
  treeList: any[]=[];
  apiURL:any;
  rowData:any;
  refData:any;
  hpCodes:any=[];
  treeStatusList: any[]=[
    { id: 1, value: 'Mature'},
    { id: 2, value: 'Immature'}
  ];
  immatureField:any[]=[];

  addToHarvestingPlanForm = new UntypedFormGroup({
    harvestingPlanId : new UntypedFormControl(''),
    companyCode : new UntypedFormControl(''),
    estateCode : new UntypedFormControl(''),
    divisionCode : new UntypedFormControl(''),
    fieldCode : new UntypedFormControl(''),
    treeStatus : new UntypedFormControl(''),
    availableQuantity : new UntypedFormControl(''),
    harvestingQuantity : new UntypedFormControl(''),
    harvestingType : new UntypedFormControl('')
  });

  constructor(
      public bsModalRef:BsModalRef,
      public modalService: BsModalService,
      public harvestingPlanService: HarvestingPlanService,
      private notificationService: ToastrService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) {
  }

  ngOnInit(): void {
    this.rowData = this.param;
    console.log("Parameters:",this.rowData);

    if ('immatureFieldCode' in this.rowData) {
      this.uniqueCode = this.rowData.immatureFieldCode;
      this.thinningOut = true;
      this.treeStatus = 2;
      this.availableQuantity = this.rowData.initialPlants;
      this.harvestingType = 2;

    } else if ('treeNo' in this.rowData) {
      this.uniqueCode = this.rowData.treeID;
      this.treeStatus = 1;
      this.availableQuantity = 1;
      this.harvestingQuantity = 1;
      this.harvestingType = 1;

    } else {
      this.notificationService.error('Tree Status Identification Failed', 'Error');
    }

      this.companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(this.companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

      this.refDataService.getAllHarvestingPlanByHarvestingType(this.harvestingType).subscribe((data)=>{
        this.refData = data;
        this.refData.forEach(hpCodes => {
          this.harvestingPlanList.push({
            id: hpCodes.harvestingPlanId,
            value: hpCodes.hpShortCode
          });
        });
  
        },error=>{
          console.log("Error Occurred");
          console.log(error)
        },()=>{
          console.log("Harvesting Plan ID Completed");
        });

      this.estateCodeChanged(this.rowData.estateCode);
      this.divisionCodeChanged(this.rowData.divisionCode);

      let hostName = this.urlService.getHostName(window.location);
          // console.log(hostName);
        this.companyCodeList.push(hostName);
        this.addToHarvestingPlanForm.controls["companyCode"].patchValue(hostName);
        this.addToHarvestingPlanForm.controls["companyCode"].disable();

    this.addToHarvestingPlanForm.patchValue({
      companyCode: this.rowData.companyCode,
      estateCode: this.rowData.estateCode,
      divisionCode: this.rowData.divisionCode,
      fieldCode: this.rowData.fieldCode,
      treeStatus: this.treeStatus,
      availableQuantity:this.availableQuantity,
      harvestingQuantity:this.harvestingQuantity,
      harvestingType:this.harvestingType
    });
    ["estateCode", "divisionCode","fieldCode","treeStatus","availableQuantity","harvestingType"].forEach(control => this.addToHarvestingPlanForm.controls[control].disable());


    this.initFormValues();
   
  }

  initFormValues(){
    validateForm(this.addToHarvestingPlanForm);
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  treeStatusChanged($event) {
  }

  harvestingPlanChanged($event){
  }

  remarksChanged($event){
  }

  harvestingTypeChanged($event){
  }

  addToHarvestingPlan(){

    this.loadingBtn = true;
    this.loading=true;
    let formData = this.addToHarvestingPlanForm.getRawValue();
    let harvestingPlanID = formData.harvestingPlanId;

    const addRecord = [
    {
      uniqueCode : this.uniqueCode,
      estateCode: formData.estateCode,
      divisionCode : formData.divisionCode,
      fieldCode : formData.fieldCode,
      treeStatus : this.treeStatus,
      availableQuantity : formData.availableQuantity,
      harvestingQuantity : formData.harvestingQuantity,
      harvestingType : this.harvestingType
    }
  ]

  console.log("Add record",addRecord);

    this.harvestingPlanService.addImmatureFiledsToHarvestingPlan(this.companyCode,harvestingPlanID,this.harvestingType,addRecord).subscribe(res=>{
      if(res){
        this.notificationService.success('Field Successfully Added to the Harvesting Plan','Success');
        this.event.emit()
        this.bsModalRef.hide();
      }else{
        this.notificationService.error('Field has not been Added','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notificationService.error('This Field already exist', 'Error');
      }
      else {
        this.notificationService.error('Field has NOT been Added','Error');
      }
      this.loadingBtn = false;
    });
  }
}
