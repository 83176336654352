import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HarvestingComponent } from './harvesting-master-plan/harvesting.component';
import { HarvestingPlanComponent } from './harvesting-plan/harvesting-plan.component';

const routes: Routes = [
  { path: 'harvesting', component: HarvestingComponent },
  { path: 'harvesting-plan', component: HarvestingPlanComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HarvestingRoutingModule { }
