<div class="modal-header">
    <h5 class="modal-title pull-left">Edit Harvesting Plan Record</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form
    class="p-3"
    [formGroup]="harvestingPlanEditForm"
    (ngSubmit)="updateHarvestingPlanRecord($event)"
  >
    <div class="modal-body-edit-mature-field">
      <div class="form-group row">
        <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Company Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="companyCodeList"
            formControlName="companyCode"
            placeholder="Company Code"
            (select)="companyCodeChanged($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'companyCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['companyCode'].invalid &&
              (harvestingPlanEditForm.controls['companyCode'].dirty ||
                harvestingPlanEditForm.controls['companyCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingPlanEditForm.controls['companyCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="divisionCode" class="col-sm-4 col-form-label col-form-label-sm"
          >Division Code
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="divisionByCompanyCodeList"
            formControlName="divisionCode"
            placeholder="Division Code"
            (select)="divisionCodeChanged($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'divisionCode'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['divisionCode'].invalid &&
              (harvestingPlanEditForm.controls['divisionCode'].dirty ||
                harvestingPlanEditForm.controls['divisionCode'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="harvestingPlanEditForm.controls['divisionCode'].errors.required"
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>
  
      <div class="form-group row">
        <label
          for="planYear"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Plan Year
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="planYearList"
            placeholder="Plan Year"
            formControlName="planYear"
            (select)="planYearsList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'planYear'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['planYear'].invalid &&
              (harvestingPlanEditForm.controls['planYear'].dirty ||
                harvestingPlanEditForm.controls['planYear'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanEditForm.controls['planYear'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="planMonth"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Plan Month
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="planMonthList"
            placeholder="Plan Month"
            formControlName="planMonth"
            (select)="planMonthsList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'planMonth'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['planMonth'].invalid &&
              (harvestingPlanEditForm.controls['planMonth'].dirty ||
                harvestingPlanEditForm.controls['planMonth'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanEditForm.controls['planMonth'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label col-form-label-sm"
          >Description</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            maxlength="100"
            class="form-control"
            formControlName="description"
            placeholder="Description"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'description'"
          />
          <div
            *ngIf="
              harvestingPlanEditForm.controls['description'].invalid &&
              (harvestingPlanEditForm.controls['description'].dirty ||
                harvestingPlanEditForm.controls['description'].touched)
            "
            class="ng-validator"
          >
            <div *ngIf="harvestingPlanEditForm.controls['description'].errors.required">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="status"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Status
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="statusList"
            placeholder="Status"
            formControlName="status"
            (select)="selectStatusList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'status'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['status'].invalid &&
              (harvestingPlanEditForm.controls['status'].dirty ||
                harvestingPlanEditForm.controls['status'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanEditForm.controls['status'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="workFlowStatus"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Work Flow Status
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="workFlowStatusList"
            placeholder="Work Flow Status"
            formControlName="workFlowStatus"
            (select)="selectWorkFlowStatusList($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'workFlowStatus'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['workFlowStatus'].invalid &&
              (harvestingPlanEditForm.controls['workFlowStatus'].dirty ||
                harvestingPlanEditForm.controls['workFlowStatus'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanEditForm.controls['workFlowStatus'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label
          for="harvestingType"
          class="col-sm-4 col-form-label col-form-label-sm"
          >Harvesting Type
        </label>
        <div class="col-sm-8">
          <ngx-select
            appMandatoryField
            optionValueField="id"
            optionTextField="value"
            [items]="harvestigType"
            placeholder="Harvesting Type"
            formControlName="harvestingType"
            (select)="harvestigTypeChanged($event)"
            appInfoPromptBox
            [page]="'harvesting-plan'"
            [component]="'add'"
            [field]="'harvestingType'"
          ></ngx-select>
          <div
            *ngIf="
              harvestingPlanEditForm.controls['harvestingType'].invalid &&
              (harvestingPlanEditForm.controls['harvestingType'].dirty ||
                harvestingPlanEditForm.controls['harvestingType'].touched)
            "
            class="ng-validator"
          >
            <div
              *ngIf="
                harvestingPlanEditForm.controls['harvestingType'].errors.required
              "
            >
              This field is required.
            </div>
          </div>
        </div>
      </div>

    </div>
  
    <div class="modal-footer">
      <button
        type="submit"
        appButtonLoader
        [isLoading]="loadingBtn"
        [disabled]="disableButton"
        class="btn btn-primary"
      >
        Save
      </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
        Close
      </button>
    </div>
  </form>
  