import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HarvestingMasterPlanService {

  constructor(private http: HttpClient) { }

    getAllHarvestingMasterPlan(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/harvesting-master-plan/" +"kvpl/"+ apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/harvesting-master-plan/", data);
    }

    update(masterPlanID,data)
    {
        return this.http.put(environment.apiUrl + "/harvesting-master-plan/" + masterPlanID, data);
    }

    delete(masterPlanID)
    {
        if (!masterPlanID) return;
        return this.http.delete(environment.apiUrl + "/harvesting-master-plan/" + masterPlanID);
    }

    getMasterPlanDataByMasterPlanID(masterPlanID)
    {
        return this.http.get(environment.apiUrl + "/harvesting-master-plan/" + masterPlanID  )
    }

    getAllHarvestingMasterPlanCodeList()
    {
        return this.http.get(environment.apiUrl + "/harvesting-master-plan/mpc-list");
    }

    checkMasterPlanCode(masterPlanID, masterPlanCodeStart, masterPlanCodeEnd)
    {
        return this.http.get(environment.apiUrl + "/harvesting-master-plan/validate-master-plan-details/" + masterPlanID + "/" + masterPlanCodeStart + "/" + masterPlanCodeEnd + "/");
    }

    checkMasterPlanYears(masterPlanID,masterPlanCodeStart, masterPlanCodeEnd)
    {
        return this.http.get(environment.apiUrl + "/harvesting-master-plan/validate-years/" + masterPlanID + "/" + masterPlanCodeStart + "/" + masterPlanCodeEnd + "/");
    }

}