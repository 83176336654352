import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';
import { UrlService } from 'src/app/services/url.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-harvesting-edit',
  templateUrl: './harvesting-edit.component.html',
  styleUrls: ['./harvesting-edit.component.scss']
})
export class HarvestingEditComponent {
  
  roleData:any[] = [];
  refData : any;
  userGroupData:any[] = [];
  initialState:any;
  param:any;
  tartYearList: any[]=[];
  startYearList: any[]=[];
  endYearList: any[]=[];
  selectedYear : any;
  autoSetEndYear:number;
  splitMasterPlanCode:any;
  harvestingMasterPlanEditForm:any;
  companyCodeList: any[]=[];

  startedYear : number;
  endedYear : number;

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;


  constructor(
      public bsModalRef : BsModalRef,
      private notificationService : ToastrService,
      private harvestingMasterPlanService: HarvestingMasterPlanService,
      private urlService: UrlService,
      private modalService : BsModalService
  ) { 
    
  }


  ngOnInit(): void {
    this.initFormValues();

    this.generateYearOfPlantingList(2000, 2035);

    var companyCode = this.urlService.getHostName(window.location);
    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.harvestingMasterPlanEditForm.controls["companyCode"].patchValue(hostName);
      this.harvestingMasterPlanEditForm.controls["companyCode"].disable();
  }


 initFormValues(){

  this.splitMasterPlanCode = this.param.masterPlanCode;
  console.log("Forestry Management Plan Code: ",this.splitMasterPlanCode);

 

  if (this.splitMasterPlanCode) {
    let masterPlanCodeParts = this.splitMasterPlanCode.split('-');
    console.log("Forestry Management Plan Code Parts:",masterPlanCodeParts);
    
    if (masterPlanCodeParts.length === 2) {
      this.startedYear = parseInt(masterPlanCodeParts[0].trim(), 10);
      this.endedYear = parseInt(masterPlanCodeParts[1].trim(), 10);
      console.log("Started:",this.startedYear," ","Ended: ",this.endedYear);
    }
    }


    this.harvestingMasterPlanEditForm = new UntypedFormGroup(
      {
        masterPlanID: new UntypedFormControl(this.param.masterPlanID),
        companyCode: new UntypedFormControl(this.param.companyCode,[Validators.required, Validators.maxLength(15)]),
        masterPlanCode: new UntypedFormControl(this.param.masterPlanCode,[Validators.required, Validators.maxLength(15)]),
        masterPlanCodeStart: new UntypedFormControl(this.startedYear),
        masterPlanCodeEnd: new UntypedFormControl(this.endedYear),
        description: new UntypedFormControl(this.param.description,[Validators.required, Validators.maxLength(15)]),
        harvestingPlan: new UntypedFormControl(''),
        status: new UntypedFormControl('')
      }
    );
      
    validateForm(this.harvestingMasterPlanEditForm);

  }

  companyCodeChanged(event){
  }

  startYear(event): void{

    const selectedStartYear = parseInt(event, 10);

    const selectedEndYear = selectedStartYear + 4;
    this.autoSetEndYear = selectedEndYear;
    
    const isValidEndYear = this.endYearList.some(year => year.value === selectedEndYear.toString());
    if (isValidEndYear) {
      this.harvestingMasterPlanEditForm.controls['masterPlanCodeEnd'].patchValue(selectedEndYear);
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, selectedEndYear);
    } else {
      this.notificationService.error('Selected End Year Is Not In The List Of Available End Years.','Error');
      this.harvestingMasterPlanEditForm.controls['masterPlanCodeStart'].patchValue('');

    }
    
  }

  endYear(event): void{
    const selectedEndYear = parseInt(event, 10);
    const selectedStartYear = parseInt(this.harvestingMasterPlanEditForm.controls['masterPlanCodeStart'].value, 10);

    if (selectedEndYear >= selectedStartYear) {
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, selectedEndYear);
    } else {
      this.notificationService.error('End Year Cannot Be Lower Than Start Year.','Error');
      this.harvestingMasterPlanEditForm.controls['masterPlanCodeEnd'].setValue(this.autoSetEndYear);
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, this.autoSetEndYear);
    }
  }

  updateDescription(startYear: number, endYear: number): void{
    const description = `Forestry Management Plan For ${startYear} - ${endYear}`;
    this.harvestingMasterPlanEditForm.controls['description'].patchValue(description);
  }

  generateYearOfPlantingList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.startYearList.push({ id: year , value: year.toString() });
      this.endYearList.push({ id: year , value: year.toString() });
    }
  }

  onCheckYearRange(startYear:number,endYear:number) {

    this.harvestingMasterPlanService.checkMasterPlanYears(this.param.masterPlanID,startYear,endYear)
        .subscribe(
          (response) => {
            var yearConflict: any = response;
            if (yearConflict.status == false) {
              this.disableButton = true;
              console.log(yearConflict.message);
              this.notificationService.error(yearConflict.message,'Error');
            } else {
              this.disableButton = false;
            }
          },
          (error) => {
            // Handle error if needed
            console.error('Year Conflict Checking Error Appeared', error);
          }
        );
    
  }

  updateHarvestingMasterPlan(event) {
    // if (this.harvestingMasterPlanEditForm.valid) {

    

      this.loadingBtn = true;
      let masterPlanID = this.param.masterPlanID;
      let submitData = this.harvestingMasterPlanEditForm.getRawValue();

      if (submitData.masterPlanCodeStart && submitData.masterPlanCodeEnd) {
        submitData.masterPlanCode = `${submitData.masterPlanCodeStart}-${submitData.masterPlanCodeEnd}`;
      } else {
        this.loadingBtn = false;
        this.notificationService.error('Forestry Management Plan Code error occurred', 'Error');
        return;
      }
      console.log(submitData.masterPlanCode);

      //<<-------------------------------------------------------------------------------------------->>

      this.harvestingMasterPlanService.checkMasterPlanCode(masterPlanID, submitData.masterPlanCodeStart, submitData.masterPlanCodeEnd).subscribe((response) => {
      if (response) {
        // console.log(response==true);
        
        const initialState = {
          title: 'There Are Forestry Management Plan Records Which Do Not Belong To The Specified Year Range. Do You Want To Remove Them From The Forestry Management Plan?',
          okBtnName: 'OK',
          closeBtnName: 'Cancel',
          isCommentVisible: false,
          titleBackground: "bg-warning-emphasized",
        };
        let modalRef = this.modalService.show(ConfirmationBoxComponent, { class: 'modal-md', initialState, ignoreBackdropClick: true });
        modalRef.content.event.subscribe(res => {
          if (res?.result?.action == 'ok') {

            const modalResponse = "ok";
            // console.log(modalResponse);

            const uploadData = new FormData();
            uploadData.append('companyCode', submitData.companyCode);
            uploadData.append('masterPlanCode', submitData.masterPlanCode);
            uploadData.append('masterPlanCodeStart', submitData.masterPlanCodeStart);
            uploadData.append('masterPlanCodeEnd', submitData.masterPlanCodeEnd);
            uploadData.append('description', submitData.description);
            uploadData.append('status', modalResponse);
            
            this.harvestingMasterPlanService.update(masterPlanID, uploadData).subscribe(res => {
              if (res) {
                this.loadingBtn = false;
                this.notificationService.success('Forestry Management Plan has been updated', 'Success');
                console.log(res);
                
                this.bsModalRef.hide();
                  this.event.emit();
              } else {
                this.loadingBtn = false;
                this.notificationService.error('Forestry Management Plan has not been updated', 'Error');
                console.log(res);
                
              }
              
            },error => {
              if (error) {
                const errorMessage = error.error;
                this.notificationService.error(errorMessage, 'Error');
                console.log(errorMessage);
              }
              // else {
              //   this.notificationService.error('aster Plan has not been updated', 'Error');
              // }
              console.log(error);
              
              
            }, () => {
              this.loadingBtn = false;
            });
          } else {
            this.loadingBtn = false;
          }
        })
          
      } else if (response==false) {
        // console.log(response);

        const modalResponse = "ok";

        const uploadData = new FormData();
        uploadData.append('companyCode', submitData.companyCode);
        uploadData.append('masterPlanCode', submitData.masterPlanCode);
        uploadData.append('masterPlanCodeStart', submitData.masterPlanCodeStart);
        uploadData.append('masterPlanCodeEnd', submitData.masterPlanCodeEnd);
        uploadData.append('description', submitData.description);
        uploadData.append('status', modalResponse);
        
        this.harvestingMasterPlanService.update(masterPlanID, uploadData).subscribe(res => {
          if (res) {
            this.loadingBtn = false;
            this.notificationService.success('Forestry Management Plan has been updated', 'Success');
            console.log(res);
            
            this.bsModalRef.hide();
              this.event.emit();
          } else {
            this.loadingBtn = false;
            this.notificationService.error('Forestry Management Plan has not been updated', 'Error');
            console.log(res);
            
          }
          
        },error => {
          if (error) {
            const errorMessage = error.error;
            this.notificationService.error(errorMessage, 'Error');
            console.log(errorMessage);
          }
          // else {
          //   this.notificationService.error('aster Plan has not been updated', 'Error');
          // }
          console.log(error);
          
          
        }, () => {
          this.loadingBtn = false;
        });
      }
    });

  }

}
