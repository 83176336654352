import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';
import { UrlService } from 'src/app/services/url.service';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-harvesting-add',
  templateUrl: './harvesting-add.component.html',
  styleUrls: ['./harvesting-add.component.scss']
})
export class HarvestingAddComponent {

  param:any;
  validateStartYear : any;
  validateEndYear : any;
  refData : any;
  yearData: number[] = [];
  companyCodeList: any[]=[];
  startYearList: any[]=[];
  endYearList: any[]=[];
  selectedFile: File | null = null;
  selectedYear : any;
  autoSetEndYear:number;

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  isYearMatching:Boolean ;
  yearMatchingCount : number = 0;
  yearNotMatchingCount : number = 0;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  harvestingMasterPlanAddForm = new UntypedFormGroup({
    masterPlanID: new UntypedFormControl(''),
    companyCode: new UntypedFormControl(''),
    masterPlanCode: new UntypedFormControl(''),
    masterPlanCodeStart: new UntypedFormControl(''),
    masterPlanCodeEnd: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    harvestingPlan: new UntypedFormControl('')
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private harvestingMasterPlanService : HarvestingMasterPlanService,
      private urlService: UrlService
  ) { 
  }

  companyCodeChanged(event){
  }

  startYear(event): void{

    const selectedStartYear = parseInt(event, 10);

    const selectedEndYear = selectedStartYear + 4;
    this.autoSetEndYear = selectedEndYear;
    
    const isValidEndYear = this.endYearList.some(year => year.value === selectedEndYear.toString());
    if (isValidEndYear) {
      this.harvestingMasterPlanAddForm.controls['masterPlanCodeEnd'].patchValue(selectedEndYear);
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, selectedEndYear);
    } else {
      this.notification.error('Selected End Year Is Not In The List Of Available End Years.','Error');
      this.harvestingMasterPlanAddForm.controls['masterPlanCodeStart'].patchValue('');

    }

    
    
  }

  endYear(event): void{
    const selectedEndYear = parseInt(event, 10);
    const selectedStartYear = parseInt(this.harvestingMasterPlanAddForm.controls['masterPlanCodeStart'].value, 10);

    if (selectedEndYear >= selectedStartYear) {
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, selectedEndYear);
    } else {
      this.notification.error('End Year Cannot Be Lower Than Start Year.','Error');
      this.harvestingMasterPlanAddForm.controls['masterPlanCodeEnd'].setValue(this.autoSetEndYear);
      this.onCheckYearRange(selectedStartYear, selectedEndYear);
      this.updateDescription(selectedStartYear, this.autoSetEndYear);
    }

    
  }

  updateDescription(startYear: number, endYear: number): void{
    const description = `Forestry Management Plan For ${startYear} - ${endYear}`;
    this.harvestingMasterPlanAddForm.controls['description'].patchValue(description);
    this.validateStartYear = startYear;
    this.validateEndYear = endYear;
  }

  generateYearOfPlantingList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.startYearList.push({ id: year , value: year.toString() });
      this.endYearList.push({ id: year , value: year.toString() });
    }
  }


  onFileSelected(event: any):void {
    this.selectedFile = event.target.files[0] as File;

    console.log("In the fileSelected",this.selectedFile);

    this.onXmlYearValidation(event,this.validateStartYear,this.validateEndYear);
    
  }

  onXmlYearValidation(event:any,startYear:number,endYear:number){
    const target: DataTransfer = <DataTransfer>(event.target);

    if(target.files.length != 1){
      console.error('Cannot upload multiple files');
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (event:any)=>{
      const binaryStr: string = event.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName: string = workbook.SheetNames[0];
      const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

      const data: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const yearIndex = data[0].indexOf('Year');
      this.yearData = data.slice(1).map((row: any) => row[yearIndex])

      console.log("XML Year Data",this.yearData);

      this.yearData.forEach(year => {
        if(year>=startYear && year<=endYear){
          this.yearMatchingCount++;
          console.log("year matching count:",this.yearMatchingCount);
        }else{
          this.yearNotMatchingCount++;
          console.log("year not matching count:",this.yearNotMatchingCount);
        }
      })
      if(this.yearNotMatchingCount>0){
        this.notification.error("Years are not matching",'Error');
        
      }else{
        this.notification.success("Years are matching",'Success');
      }
    };
    reader.readAsBinaryString(target.files[0]);

   

    

    
  }

  yearValidation(startYear:number,endYear:number){
    

  }

  onCheckYearRange(startYear:number,endYear:number) {

    const masterPlanID = 0;

    this.harvestingMasterPlanService.checkMasterPlanYears(masterPlanID,startYear,endYear)
        .subscribe(
          (response) => {
            var yearConflict: any = response;
            if (yearConflict.status == false) {
              this.disableButton = true;
              console.log(yearConflict.message);
              this.notification.error(yearConflict.message,'Error');
            } else {
              this.disableButton = false;
            }
          },
          (error) => {
            // Handle error if needed
            console.error('Year Conflict Checking Error Appeared', error);
          }
        );
    
  }


  ngOnInit(): void {


    var companyCode = this.urlService.getHostName(window.location);
    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.harvestingMasterPlanAddForm.controls["companyCode"].patchValue(hostName);
      this.harvestingMasterPlanAddForm.controls["companyCode"].disable();

      this.generateYearOfPlantingList(2000, 2035);

    this.harvestingMasterPlanAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  createNewHarvestingMasterPlan(){

    // if (!this.selectedFile) {
    //   console.log('No file selected');
    //   return;
    // }

    // console.log('Selected file:', this.selectedFile);

    let formData = this.harvestingMasterPlanAddForm.getRawValue();

    if (formData.masterPlanCodeStart && formData.masterPlanCodeEnd) {
      formData.masterPlanCode = `${formData.masterPlanCodeStart}-${formData.masterPlanCodeEnd}`;
    } else {
      this.loadingBtn = false;
      this.notification.error('Forestry Management Plan Code error occurred', 'Error');
      return;
    }

    formData.masterPlanID = 0;
    // console.log("Form Company Code:",formData.companyCode);

    const uploadData = new FormData();
    uploadData.append('companyCode', formData.companyCode);
    uploadData.append('masterPlanID', formData.masterPlanID);
    uploadData.append('masterPlanCode', formData.masterPlanCode);
    uploadData.append('masterPlanCodeStart', formData.masterPlanCodeStart);
    uploadData.append('masterPlanCodeEnd', formData.masterPlanCodeEnd);
    uploadData.append('description', formData.description);

    if (this.selectedFile) {
      uploadData.append('harvestingPlan', this.selectedFile);
    }

    this.loadingBtn = true;
    this.harvestingMasterPlanService.create(uploadData).subscribe(res=>{
      if(res){
        this.notification.success('Forestry Management Plan has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Forestry Management Plan has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if (error) {
        const errorMessage = error.error
        this.notification.error(errorMessage,'Error')
      }
      // if(error.status==409){
      //   this.notification.error('Selected Start Year And End Year Overlap With An Existing Master Plan. Please Choose A Different Range', 'Error');
      // }
      // else if(error.status==400){
      //   this.notification.error('Excel File Required', 'Error');
      // }
      // else if(error.status==422){
      //   this.notification.error('Conflicting Year Range Detected, Please Check the Excel File Records Against the Specified Year Ranged', 'Error');
      // } 
      // else {
      //   this.notification.error('Forestry Management Plan has NOT been created','Error');
      // }
      this.loadingBtn = false;
    });
    this.loadingBtn = false;
  }

}