import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private http: HttpClient) { }

  getHostName(location:any){
    const hostname = location.hostname.split('.')[0];
    // console.log('Hostname:', hostname);
    return hostname;
  }

}