import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HarvestingPlanItemsComponent } from '../harvesting-plan-items/harvesting-plan-items.component';

@Component({
  selector: 'app-harvesting-plan-items-url',
  templateUrl: './harvesting-plan-items-url.component.html',
  styleUrls: ['./harvesting-plan-items-url.component.scss']
})
export class HarvestingPlanItemsUrlComponent {
  rowData: any;
  data: any;

  constructor(
    private modalService:BsModalService
  ) { }

  ngOnInit(): void {
    
  }

  loadHarvestingPlanItems(){

    this.data = this.rowData;
    const initialState = {
      param : {
        data : this.data,
      }
    };
    this.modalService.show(HarvestingPlanItemsComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
  }
}
