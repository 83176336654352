
<div class="modal-header">
    <h5 class="modal-title pull-left">Harvesting Plan Items</h5>
    <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
  <div class="data-table">
    <app-tree-table [settings]="settings" [data]="harvestingPlanItems" [currentPage]="currentPage" [pageCount]="pageCount" (pageChangeEvent)="changePage($event)" (pageSizeChangeEvent)="changePageSize($event);" (filterChangeEvent)="filterOrSortChange($event)"></app-tree-table>
  </div>
</div>
