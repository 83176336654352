import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { HarvestingAddComponent } from './components/harvesting-add/harvesting-add.component';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { HarvestingEditComponent } from './components/harvesting-edit/harvesting-edit.component';
import { HarvestingListComponent } from './components/harvesting-list/harvesting-list.component';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';

@Component({
  selector: 'app-harvesting',
  templateUrl: './harvesting.component.html',
  styleUrls: ['./harvesting.component.scss']
})
export class HarvestingComponent {

  // faAngleRight = faAngleRight;
  faPlus = faPlus;
  loading:Boolean = true;
  // harvestingMasterPlans:any = [];
  harvestingMasterPlans:any = [];
  rowData:any;

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  settings = {
    type:'table',
    id:'masterPlanID',
    columnManagerEnable:true,
    
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.harvestingMasterPlanService.getAllHarvestingMasterPlan("distinct-values", filterData, params)},
    columns:[
      {
        title:'Master Plan ID',
        attribute:'masterPlanID',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:100
      },
      {
        title:'Master Plan Code',
        attribute:'masterPlanCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:100,
      },
      {
        title:'Description',
        attribute:'description',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:50,
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editHarvestingMasterPlan(row)},
        width:32
      },
      {
        renderComponent: '<a class="btn btn-info btn-url btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/tools.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.loadHarvestingPlanDetails(row)},
        width:32
      },
      // {
      //   renderComponent: HarvestingListUrlComponent,
      //   type:'custom',
      //   width:30
      // },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteHarvestingMasterPlan(row)},
        width:32,
      }
    ]
  };

  constructor(
      private harvestingMasterPlanService : HarvestingMasterPlanService,
      private activatedRoute : ActivatedRoute,
      private modalService : BsModalService,
      private notificationService : ToastrService,

  ) {
    activatedRoute.queryParams.subscribe(params => {
      if(params['page']) this.currentPage = +params['page'];
      if(params['column']) this.sortColumn = params['column'];
      if(params['sortOrder']) this.sortOrder = params['sortOrder'];
    });
  }

  ngOnInit(): void {
    this.getAllHarvestingMasterPlan();
  }

  /**
   * Load student add modal
   */
  loadAddModal(){
    const initialState = {};
    let modalRef = this.modalService.show(HarvestingAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllHarvestingMasterPlan();
    });
  }

  loadHarvestingPlanDetails(data){
    const initialState = {
      param : data
    };
    let modalRef = this.modalService.show(HarvestingListComponent, {class:'modal-xl', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllHarvestingMasterPlan();
    });
  }

  /**
   * Get all matureFields
   */
  getAllHarvestingMasterPlan(){
    this.loading = true;                                           //Need to Change the value to true.
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;
    this.harvestingMasterPlanService.getAllHarvestingMasterPlan('filtered-data', this.filterData, params).subscribe(res=>{
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        this.harvestingMasterPlans  = res['page']['content'];
        this.loading = false;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let needFillColumnAndTable = false;
        for(let column of columns){
          if(column.filter && column.filter['column'] == null) needFillColumnAndTable = true;
        }
        if(!needFillColumnAndTable) return;

        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];
        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = res['isNative'] ? data['table'] + "." + data['column'] : data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.harvestingMasterPlans = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getAllHarvestingMasterPlan();
  }

  /**
   * Load edit matureFields modal
   */
  editHarvestingMasterPlan(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(HarvestingEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllHarvestingMasterPlan();
    });
  }

  editHarvestingPlan(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(HarvestingListComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getAllHarvestingMasterPlan();
    });
  }

  deleteHarvestingMasterPlan(data){
    
    const initialState = {
      title: 'Are you sure that you want to Delete the Selected Forestry Management Plan?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: false,
      titleBackground : "bg-warning-emphasized",
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        this.loading = true;
        this.harvestingMasterPlanService.delete(data.masterPlanID).subscribe(res=>{
          console.log(res);
          this.loading = false;
          this.notificationService.success('Forestry Management Plan has been Deleted successfully!', 'Success');
          this.getAllHarvestingMasterPlan();
        },error=>{
          console.log(error);
          if(error.status == 200){
            this.notificationService.success('Forestry Management Plan has been Deleted successfully!', 'Success');
          }else if(error.status == 412){
            this.notificationService.error('Forestry Management Plan Deletion failed due to it\'s being used!', 'Error');
          }else{
            this.notificationService.error('Forestry Management Plan Deletion failed!', 'Error');
          }
          
          this.loading = false;
          this.getAllHarvestingMasterPlan();
        },()=>{
          this.loading = false;
          
        });
        

      }
      else {
        this.loading = false;
        // this.currentSpinnerText = "Loading Project Systems...";
      }; // do nothing
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getAllHarvestingMasterPlan();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getAllHarvestingMasterPlan();
  }
}