import { formatDate } from '@angular/common';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ImmatureFieldsService } from 'src/app/services/immature-fields.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-immature-fields-add',
  templateUrl: './immature-fields-add.component.html',
  styleUrls: ['./immature-fields-add.component.scss']
})
export class ImmatureFieldsAddComponent {

  param:any;
  model1: any;
  refData : any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  divisionCodeList: any[]=[];
  fieldCodeList: any[]=[];
  specieCodeList: any[]=[];
  plantingDistanceList: any[]=[];
  immatureFieldCodeList: any[]=[];
  yearOfPlantingList: any[]=[];
  terrainList: any[]=[];
  soilTypeList: any[]=[];
  soilDepthList: any[]=[];
  slopeList: any[]=[];
  confirmationOfCensesList: any[] =[
    {"id":1,"value":"Yes"},
    {"id":2,"value":"No"}
  ];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  immatureFieldsAddForm = new UntypedFormGroup({
    immatureFieldCode: new UntypedFormControl(''),
    companyCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    divisionCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    fieldCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    specieCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(15)]),
    fieldExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    yearofPlanting: new UntypedFormControl('',[Validators.maxLength(10)]),
    initialPlants: new UntypedFormControl('',[Validators.maxLength(10)]),
    noPresentPlants: new UntypedFormControl('',[Validators.maxLength(10)]),
    terrain: new UntypedFormControl('',[Validators.maxLength(10)]),
    distanceFromMainRoad: new UntypedFormControl('',[Validators.maxLength(10)]),
    slope: new UntypedFormControl('',[Validators.maxLength(10)]),
    soilType: new UntypedFormControl('',[Validators.maxLength(10)]),
    soilDepth: new UntypedFormControl('',[Validators.maxLength(10)]),
    elevationInMeters: new UntypedFormControl('',[Validators.maxLength(8)]),
    surveyPlanNo: new UntypedFormControl('',[Validators.maxLength(10)]),
    dateOfSurvey: new UntypedFormControl(''),
    surveyPlanJPGTag: new UntypedFormControl('',[Validators.maxLength(10)]),
    fundedBy: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName1: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName2: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantedByName3: new UntypedFormControl('',[Validators.maxLength(10)]),
    plantingDistance: new UntypedFormControl('',[Validators.maxLength(10)]),
    dateOfPlanting: new UntypedFormControl(''),
    comments: new UntypedFormControl('',[Validators.maxLength(10)]),
    noPlantsConfirmed: new UntypedFormControl('',[Validators.maxLength(10)]),
    confirmationOfCenses: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningOutDate1st: new UntypedFormControl(''),
    thiningOutDate2nd: new UntypedFormControl(''),
    thiningOutDate3rd: new UntypedFormControl(''),
    thiningCut1st: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningCut2nd: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningCut3rd: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningRemaining1st: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningRemaining2nd: new UntypedFormControl('',[Validators.maxLength(10)]),
    thiningRemaining3rd: new UntypedFormControl('',[Validators.maxLength(10)])
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private immatureFieldsService : ImmatureFieldsService,
      private refDataService : RefdataService,
      private urlService: UrlService
  ) { 
    

  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  yearOfPlantingChanged($event) {
  }

  terrainChanged($event) {
  }

  soilTypeChanged($event) {
  }

  soilDepthChanged($event) {
  }

  slopeChanged($event) {
  }

  plantingDistanceChanged($event){
  }

  surveyPlanJPGTagChanged($event) {
  }

  confirmationOfCensesChanged($event) {
  }

  generateYearOfPlantingList(startYear: number, endYear: number): void {
    for (let year = startYear; year <= endYear; year++) {
      this.yearOfPlantingList.push({ id: year - startYear + 1, value: year.toString() });
    }
  }


  ngOnInit(): void {

    this.generateYearOfPlantingList(1900, 2024);

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Estates Completed");
    });

    this.refDataService.getMatureFieldRefData().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.terrainList = this.refData.terrain;
      this.soilTypeList = this.refData.soiltypes;
      this.soilDepthList = this.refData.soildepth;
      this.slopeList = this.refData.slope;

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("RefData Completed");
    });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.specieCodeList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Specie Codes Completed");
    });

    this.refDataService.getDistance().subscribe((data)=>{
      this.refData = data;
      console.log(this.refData);

      this.refData.forEach(item => {
        this.plantingDistanceList.push(item.distance);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Planting Distane Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
        // console.log(hostName);
      this.companyCodeList.push(hostName);
      this.immatureFieldsAddForm.controls["companyCode"].patchValue(hostName);
      this.immatureFieldsAddForm.controls["companyCode"].disable();
      

    this.immatureFieldsAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }

  createNewImmatureField(){
    let formData = this.immatureFieldsAddForm.getRawValue();

    formData.dateOfSurvey = this.myformatDate( this.immatureFieldsAddForm.controls["dateOfSurvey"].value);
    formData.dateOfPlanting = this.myformatDate( this.immatureFieldsAddForm.controls["dateOfPlanting"].value);
    formData.thiningOutDate1st = this.myformatDate( this.immatureFieldsAddForm.controls["thiningOutDate1st"].value);
    formData.thiningOutDate2nd = this.myformatDate( this.immatureFieldsAddForm.controls["thiningOutDate2nd"].value);
    formData.thiningOutDate3rd = this.myformatDate( this.immatureFieldsAddForm.controls["thiningOutDate3rd"].value);

    this.loadingBtn = true;
    this.immatureFieldsService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Immature Field has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Immature Field has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Immature Field already exist', 'Error');
      }
      else {
        this.notification.error('Immature Field has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }

}
