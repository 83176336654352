import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingPlanDetailsService } from 'src/app/services/harvesting-plan-details.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-harvesting-plan-item-edit',
  templateUrl: './harvesting-plan-item-edit.component.html',
  styleUrls: ['./harvesting-plan-item-edit.component.scss']
})
export class HarvestingPlanItemEditComponent {

  harvestingPlanItemsEditForm:any=[];
  event: EventEmitter<any> = new EventEmitter();
  param:any;
  disableButton:Boolean = false;
  loading:Boolean=false;
  showFormRow:Boolean = false;
  loadingBtn:Boolean=false;
  
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  fieldCodeList: any[]=[];
  divisionCodeList: any[]=[];
  harvestingPlanList: any[]=[];
  apiURL:any;
  hpItemId:any;
  harvestingPlanId:any;
  rowData:any;
  refData:any;
  hpCodes:any=[];
  treeStatusList: any[]=[
    { id: 0, value: 'Mature'},
    { id: 1, value: 'Immature'}
  ];


  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private refDataService : RefdataService,
      private urlService : UrlService,
      private harvestingPlanService: HarvestingPlanService
  ) { }

  ngOnInit(): void {

    this.initFormValues();
    this.rowData = this.param;
    console.log("RowData",this.rowData);
    this.hpItemId = this.param.hpItemId;
    this.harvestingPlanId = this.param.harvestingPlanId;
    

    var companyCode = this.urlService.getHostName(window.location);
      this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

      },error=>{
        console.log("Error Occurred");
        console.log(error)
      },()=>{
        console.log("Estates Completed");
      });

    this.estateCodeChanged(this.param.estateCode);
      this.divisionCodeChanged(this.param.divisionCode);
      
    // console.log("Data From the DB: ",this.param);

    let hostName = this.urlService.getHostName(window.location);
          console.log(hostName);
        this.companyCodeList.push(hostName);
        console.log("Company Code List: ",this.companyCodeList);
        
        // this.harvestingPlanItemsEditForm.controls["companyCode"].patchValue(hostName);
        // this.harvestingPlanItemsEditForm.controls["companyCode"].disable();
    

    
    // this.exportUrl = environment.apiUrl + "/projects/"+this.projectNumber+"/tools/export";
  }

  initFormValues(){
    this.harvestingPlanItemsEditForm = new UntypedFormGroup({
      harvestingPlanId : new UntypedFormControl(this.param.harvestingPlanId),
      companyCode : new UntypedFormControl(this.param.companyCode),
      estateCode : new UntypedFormControl(this.param.estateCode),
      divisionCode : new UntypedFormControl(this.param.divisionCode),
      fieldCode : new UntypedFormControl(this.param.fieldCode),
      treeStatus : new UntypedFormControl(this.param.treeStatus),
      availableQuantity : new UntypedFormControl(this.param.availableQuantity),
      harvestingQuantity : new UntypedFormControl(this.param.harvestingQuantity),
      harvestingType : new UntypedFormControl(this.param.harvestingType),
      approvalStatus : new UntypedFormControl(this.param.approvalStatus),
      approvingEntity : new UntypedFormControl(this.param.approvingEntity),
      remarks : new UntypedFormControl(this.param.remarks),
      approvedQuantity : new UntypedFormControl(this.param.approvedQuantity)
    });
  }

  public currentFormValues()
  {
    return JSON.stringify(this.harvestingPlanItemsEditForm.getRawValue());
  }

  companyCodeChanged(event){
  }

  estateCodeChanged(estateCode: any) {
    console.log('Estate Code changed:', estateCode);
  
    this.refDataService.getDivisionsByEstateCode(estateCode)
        .subscribe(
          (response) => {
            var divisions: any = response;
            this.divisionCodeList = [];
            divisions.forEach(division => {
              this.divisionCodeList.push(division.divisionCode);
            });
            console.log('Response:', divisions);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  fieldCodeChanged($event) {
  }

  divisionCodeChanged(divisionCode : any) {
    console.log('Division Code changed:', divisionCode);
  
    this.refDataService.getFieldsByDivisionCode(divisionCode)
        .subscribe(
          (response) => {
            var fields: any = response;
            this.fieldCodeList = [];
            fields.forEach(fields => {
              this.fieldCodeList.push(fields.fieldCode);
            });
            console.log('Response:', fields);
          },
          (error) => {
            // Handle error if needed
            console.error('Error:', error);
          }
        );
  }

  specieCodeChanged($event) {
  }

  yearList($event) {
  }

  treeStatusChanged($event) {
  }


  updateHarvestingPlanDetails() {
    this.loadingBtn = true;
    let submitData = this.harvestingPlanItemsEditForm.getRawValue();
    
    // Edit Record
    this.harvestingPlanService.harvestingPlanItemsUpdate(this.hpItemId, submitData).subscribe(res => {
      if (res) {
        this.notificationService.success('Harvesting Plan Item has been Updated', 'Success');
        this.bsModalRef.hide();
        this.event.emit();
        this.showFormRow = true;
      } else {
        this.notificationService.error('Harvesting Plan Item has not been Updated', 'Error');
      }
      this.loadingBtn = false;
    });
  }
  

}
