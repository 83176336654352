import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImmatureStockRoutingModule } from './immature-stock-routing.module';
import { NurseryComponent } from './nursery/nursery.component';
import { ImmatureFieldsComponent } from './immature-fields/immature-fields.component';
import { ImmatureFieldsAddComponent } from './immature-fields/components/immature-fields-add/immature-fields-add.component';
import { ImmatureFieldsEditComponent } from './immature-fields/components/immature-fields-edit/immature-fields-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSelectModule } from 'ngx-select-ex';
import { TreeTableModule } from 'src/app/shared/components/tree-table/tree-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorporateRoutingModule } from '../corporate/corporate-routing.module';
import { MatureStockRoutingModule } from '../mature-stock/mature-stock-routing.module';
import { ReferenceDataRoutingModule } from '../reference-data/reference-data-routing.module';


@NgModule({
  declarations: [
    NurseryComponent,
    ImmatureFieldsComponent,
    ImmatureFieldsAddComponent,
    ImmatureFieldsEditComponent
  ],
  imports: [
    CommonModule,
    ImmatureStockRoutingModule,
    FontAwesomeModule,
    NgxPaginationModule,
    NgxSelectModule,
    NgbDatepicker,
    NgbDatepickerModule,
    MatureStockRoutingModule,
    SharedModule,
    ReferenceDataRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CorporateRoutingModule,
    TreeTableModule
  ]
})
export class ImmatureStockModule { }
