<div *ngIf="!thinningOut" class="modal-header">
    <h5 class="modal-title pull-left">Add to the Harvesting Plan</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div *ngIf="thinningOut" class="modal-header">
    <h5 class="modal-title pull-left">Add to Thinning-Out</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="bsModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
<!-- <form class="p-3" [formGroup]="addToHarvestingPlanForm" (ngSubmit)="addToHarvestingPlan()"> -->
    <form
    class="p-3"
    [formGroup]="addToHarvestingPlanForm"
    (ngSubmit)="addToHarvestingPlan()"
  >
    <div class="modal-body-add-">
        <div class="form-group row">
            <label for="harvestingPlanId" class="col-sm-4 col-form-label col-form-label-sm"
                >Harvesting Plan
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="harvestingPlanList"
                formControlName="harvestingPlanId"
                placeholder="Harvesting Plan"
                (select)="harvestingPlanChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'harvestingPlanId'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['harvestingPlanId'].invalid &&
                    (addToHarvestingPlanForm.controls['harvestingPlanId'].dirty ||
                    addToHarvestingPlanForm.controls['harvestingPlanId'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['harvestingPlanId'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>
        
        <div class="form-group row">
            <label for="companyCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Company Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="companyCodeList"
                formControlName="companyCode"
                placeholder="Company Code"
                (select)="companyCodeChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'companyCode'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['companyCode'].invalid &&
                    (addToHarvestingPlanForm.controls['companyCode'].dirty ||
                    addToHarvestingPlanForm.controls['companyCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['companyCode'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>
    
        <div class="form-group row">
            <label for="estateCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Estate Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="estateCodeList"
                placeholder="Estate Code"
                formControlName="estateCode"
                (select)="estateCodeChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'estateCode'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['estateCode'].invalid &&
                    (addToHarvestingPlanForm.controls['estateCode'].dirty ||
                    addToHarvestingPlanForm.controls['estateCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['estateCode'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>
    
        <div class="form-group row">
            <label
                for="divisionCode"
                class="col-sm-4 col-form-label col-form-label-sm"
                >Division Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="divisionCodeList"
                placeholder="Division Code"
                formControlName="divisionCode"
                (select)="divisionCodeChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'divisionCode'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['divisionCode'].invalid &&
                    (addToHarvestingPlanForm.controls['divisionCode'].dirty ||
                    addToHarvestingPlanForm.controls['divisionCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['divisionCode'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>
    
        <div class="form-group row">
            <label for="fieldCode" class="col-sm-4 col-form-label col-form-label-sm"
                >Field Code
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="fieldCodeList"
                placeholder="Field Code"
                formControlName="fieldCode"
                (select)="fieldCodeChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'fieldCode'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['fieldCode'].invalid &&
                    (addToHarvestingPlanForm.controls['fieldCode'].dirty ||
                    addToHarvestingPlanForm.controls['fieldCode'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['fieldCode'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>
    
        <div class="form-group row">
            <label for="treeStatus" class="col-sm-4 col-form-label col-form-label-sm"
                >Tree Status
            </label>
            <div class="col-sm-8">
                <ngx-select
                appMandatoryField
                optionValueField="id"
                optionTextField="value"
                [items]="treeStatusList"
                placeholder="Tree Status"
                formControlName="treeStatus"
                (select)="treeStatusChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'treeStatus'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['treeStatus'].invalid &&
                    (addToHarvestingPlanForm.controls['treeStatus'].dirty ||
                    addToHarvestingPlanForm.controls['treeStatus'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['treeStatus'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="availableQuantity" class="col-sm-4 col-form-label col-form-label-sm"
                >Available Quantity
            </label>
            <div class="col-sm-8">
                <input
                type="text"
                maxlength="10"
                appDecimal="2"
                class="form-control"
                formControlName="availableQuantity"
                placeholder="Available Quantity"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'availableQuantity'"
                />
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['availableQuantity'].invalid &&
                    (addToHarvestingPlanForm.controls['availableQuantity'].dirty ||
                    addToHarvestingPlanForm.controls['availableQuantity'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['availableQuantity'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="harvestingQuantity" class="col-sm-4 col-form-label col-form-label-sm"
                >Harvesting Quantity
            </label>
            <div class="col-sm-8">
                <input
                type="text"
                maxlength="10"
                appDecimal="2"
                class="form-control"
                formControlName="harvestingQuantity"
                placeholder="Harvesting Quantity"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'harvestingQuantity'"
                />
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['harvestingQuantity'].invalid &&
                    (addToHarvestingPlanForm.controls['harvestingQuantity'].dirty ||
                    addToHarvestingPlanForm.controls['harvestingQuantity'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['harvestingQuantity'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="harvestingType" class="col-sm-4 col-form-label col-form-label-sm"
                >Harvesting Type
            </label>
            <div class="col-sm-8">
                <ngx-select
                optionValueField="id"
                optionTextField="value"
                [items]="harvestingTypeList"
                placeholder="Harvesting Type"
                formControlName="harvestingType"
                (select)="harvestingTypeChanged($event)"
                appInfoPromptBox
                [page]="'add-harvesting-plan'"
                [component]="'add'"
                [field]="'harvestingType'"
                ></ngx-select>
                <div
                *ngIf="
                    addToHarvestingPlanForm.controls['harvestingType'].invalid &&
                    (addToHarvestingPlanForm.controls['harvestingType'].dirty ||
                    addToHarvestingPlanForm.controls['harvestingType'].touched)
                "
                class="ng-validator"
                >
                <div
                    *ngIf="addToHarvestingPlanForm.controls['remarks'].errors.required"
                >
                    This field is required.
                </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button
          type="submit"
          appButtonLoader
          [isLoading]="loadingBtn"
          [disabled]="disableButton"
          class="btn btn-primary"
        >
          Add
        </button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
          Close
        </button>
      </div>
</form>
  
  