import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faDownload, faEdit, faPlus, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingListEditComponent } from './components/harvesting-list-edit/harvesting-list-edit.component';
import { HarvestingMasterPlanService } from 'src/app/services/harvesting-master-plan.service';
import { HarvestingPlanDetailsService } from 'src/app/services/harvesting-plan-details.service';
import { ConfirmationBoxComponent } from 'src/app/shared/components/confirmation-box/confirmation-box.component';
import { HarvestingListAddComponent } from './components/harvesting-list-add/harvesting-list-add.component';

@Component({
  selector: 'app-harvesting-list',
  templateUrl: './harvesting-list.component.html',
  styleUrls: ['./harvesting-list.component.scss']
})
export class HarvestingListComponent implements OnInit {
  harvestingPlanDetailsEditForm:any=[];
  param:any;
  rowData:any;
  refData : any;
  faPlus = faPlus;
  exportUrl:any;
  faEdit=faEdit;
  faPrint=faPrint;
  faTrashAlt=faTrashAlt;
  faDownload=faDownload;
  masterPlanID:any;
  masterPlanStart:any;
  masterPlanEnd:any;
  masterPlanData:any;
  showFormRow:Boolean = false;
  harvestingPlanDetails:any;
  projectToolLength:Number=0;
  harvestingYearList: any[]=[];
  specieCodeList: any[]=[];
  filterData = [];
  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  infoStatus = false;
  initialValues:any = null;
  event: EventEmitter<any> = new EventEmitter();


  loadingBtn:boolean = false;

  settings = {
    type:'table',
    id:'harvestingPlanID',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.harvestingPlanService.getHarvestingPlanDetailsByMasterPlanID(this.masterPlanID, "distinct-values", filterData, params)},
    columns:[
      {
        title:'Company Code',
        attribute: 'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'EstateCode',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:150,
      },
      {
        title:'Field Code',
        attribute:'fieldCode',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200
      },
      {
        title:'Crop',
        attribute:'crop',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Specie Code',
        attribute:'specieCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Trees',
        attribute:'trees',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'G B Height',
        attribute:'gbHeight',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Volume C D M',
        attribute:'volumeCDM',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'M:V',
        attribute:'mv',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Age',
        attribute:'age',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Exact Hectares',
        attribute:'extHectare',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Year',
        attribute:'year',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Operation',
        attribute:'operation',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editHarvestingPlanDetails(row)},
        width:32
      },
      {
        renderComponent:'<a class="btn btn-danger btn-sm text-light btn-url"><img title="Delete" class="delete-icon" src="/assets/icon/delete.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.deleteHarvestingMasterPlan(row)},
        width:32,
      }
    ]
  };
  loading: boolean;

  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private harvestingMasterPlanService : HarvestingMasterPlanService,
      private harvestingPlanService:HarvestingPlanDetailsService
  ) { }

  ngOnInit(): void {
    
    this.masterPlanID = this.param.masterPlanID;
    this.masterPlanStart = this.param.masterPlanCodeStart;
    this.masterPlanEnd = this.param.masterPlanCodeEnd;
    this.getHarvestingDataById();
  }

  public currentFormValues()
  {
    return JSON.stringify(this.harvestingPlanDetailsEditForm.getRawValue());
  }


  getHarvestingDataById(){
    this.harvestingMasterPlanService.getMasterPlanDataByMasterPlanID(this.param.masterPlanID).subscribe(res => {
      this.masterPlanData = res;
      // console.log("Master Plan Data: ",this.masterPlanData.page['masterPlanID'],",",this.masterPlanData['masterPlanCode']);
      // console.log("Master Plan Data: ",this.masterPlanData);

      this.infoStatus = true;
      
        this.getHarvestingPlans();
    });
  }

  getHarvestingPlans(){
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

      // console.log("This is Master Plan ID: ",this.masterPlanID);
      this.harvestingPlanService.getHarvestingPlanDetailsByMasterPlanID(this.masterPlanID, "filtered-data", this.filterData, params).subscribe(res=>{
      
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];
        this.harvestingPlanDetails = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];

        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  loadAddModal(){
    const initialState = {
      param : {
        masterPlanID : this.masterPlanID,
        masterPlanStart : this.masterPlanStart,
        masterPlanEnd : this.masterPlanEnd
      }
    };
    // console.log("Sending Prameters", this.param);
    
    let modalRef = this.modalService.show(HarvestingListAddComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getHarvestingPlans();
    });
  }

  editHarvestingPlanDetails(data){
    const initialState = {
      param:data,
      rowData:this.param
    };
    let modalRef = this.modalService.show(HarvestingListEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getHarvestingPlans();
    });
  }


  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.harvestingPlanDetails = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getHarvestingPlans();
  }

  deleteHarvestingMasterPlan(data){
    
    const initialState = {
      title: 'Are you sure that you want to Delete the Selected Forestry Management Plan Detail?',
      okBtnName: 'OK',
      closeBtnName: 'Cancel',
      isCommentVisible: false,
      titleBackground : "bg-warning-emphasized",
    };
    let modalRef = this.modalService.show(ConfirmationBoxComponent, {class:'modal-md', initialState, ignoreBackdropClick:true});
    modalRef.content.event.subscribe(res =>
    {
      if(res?.result?.action == 'ok')
      {
        this.loading = true;
        this.harvestingPlanService.delete(data.harvestingPlanID).subscribe(res=>{
          console.log(res);
          this.loading = false;
          this.notificationService.success('Forestry Management Plan has been Deleted successfully!', 'Success');
          this.getHarvestingPlans();
        },error=>{
          console.log(error);
          if(error.status == 200){
            this.notificationService.success('Forestry Management Plan Detail has been Deleted successfully!', 'Success');
          }else if(error.status == 412){
            this.notificationService.error('Forestry Management Plan Detail Deletion failed due to it\'s being used!', 'Error');
          }else{
            this.notificationService.error('Forestry Management Plan Detail Deletion failed!', 'Error');
          }
          
          this.loading = false;
          this.getHarvestingPlans();
        },()=>{
          this.loading = false;
          
        });
        

      }
      else {
        this.loading = false;
        // this.currentSpinnerText = "Loading Project Systems...";
      }; // do nothing
    });
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getHarvestingPlans();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getHarvestingPlans();
  }

}