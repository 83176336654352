import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private http: HttpClient) { }

    getAllDivisions(companyCode,apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/division/" +companyCode+"/"+ apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/division/", data);
    }

    update(divisionCode,data)
    {
        return this.http.put(environment.apiUrl + "/division/" + divisionCode, data);
    }

    delete(divisionCode)
    {
        if (!divisionCode) return;
        return this.http.delete(environment.apiUrl + "/division/" + divisionCode);
    }

}