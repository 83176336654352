import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HarvestingPlanDetailsService } from 'src/app/services/harvesting-plan-details.service';
import { HarvestingPlanService } from 'src/app/services/harvesting-plan.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { HarvestingPlanItemEditComponent } from './components/harvesting-plan-item-edit/harvesting-plan-item-edit.component';

@Component({
  selector: 'app-harvesting-plan-items',
  templateUrl: './harvesting-plan-items.component.html',
  styleUrls: ['./harvesting-plan-items.component.scss']
})
export class HarvestingPlanItemsComponent {

  param:any;
  refData : any;
  faEdit=faEdit;
  harvestingPlanId:any;
  harvestingPlanData:any;
  harvestingPlanItems:any;
  harvestingYearList: any[]=[];
  specieCodeList: any[]=[];

  currentPage:number = 1;
  pageCount:number = 0;
  pageSize:number = 20;
  sortColumn:string = "";
  sortOrder:string = "";
  filterData = [];

  infoStatus = false;
  event: EventEmitter<any> = new EventEmitter();
  showFormRow:Boolean = false;
  loadingBtn:boolean = false;

  settings = {
    type:'table',
    id:'harvestingPlanId',
    columnManagerEnable:true,
    pagination:{
      enabled:true,
      pageSize:this.pageSize
    },
    enableZoom:true,
    filterOptionFunction:(params, filterData)=>{return this.harvestingPlanService.getAllHarvestingPlansByHarvestingPlanID(this.harvestingPlanId, "distinct-values", filterData, params)},
    columns:[
      {
        title:'Harvesting Plan ID',
        attribute:'harvestingPlanId',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Company Code',
        attribute:'companyCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:200,
      },
      {
        title:'EstateCode',
        attribute:'estateCode',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200,
      },
      {
        title:'Division Code',
        attribute:'divisionCode',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:150,
      },
      {
        title:'Field Code',
        attribute:'fieldCode',
        type:'text',
        format:"'###",
        filter: {
          enabled:true,
          isNumericField:true,
        },
        width:200
      },
      {
        title:'Tree Status',
        attribute:'treeStatus',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Available Quantity',
        attribute:'availableQuantity',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Harvesting Quantity',
        attribute:'harvestingQuantity',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Harvesting Type',
        attribute:'harvestingType',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Approval Status',
        attribute:'approvalStatus',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Approving Entity',
        attribute:'approvingEntity',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Remarks',
        attribute:'remarks',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        title:'Approved Quantity',
        attribute:'approvedQuantity',
        type:'text',
        filter: {
          enabled:true,
          isNumericField:false,
        },
        width:150
      },
      {
        renderComponent:'<a class="btn btn-info btn-sm text-light btn-url"><img title="Edit" class="edit-icon" src="/assets/icon/edit.png"/></a>',
        type:'html',
        actionFunction: (row)=>{this.editHarvestingPlanItems(row)},
        width:50
      }
    ]
  };

  constructor(
      public bsModalRef:BsModalRef,
      private modalService : BsModalService,
      private notificationService: ToastrService,
      private refDataService : RefdataService,
      private harvestingPlanService : HarvestingPlanService
  ) { }

  ngOnInit(): void {
    this.harvestingPlanId = this.param.data.harvestingPlanId;
    // console.log("Parameters",this.param);
    // console.log("harvestingPlanId",this.harvestingPlanId);
    
    this.getHarvestingPlans();
  }


  // getHarvestingPlanDataById(){
  //   this.harvestingPlanService.getAllHarvestingPlansByHarvestingPlanID(this.param.harvestingPlanId).subscribe(res => {
  //     this.harvestingPlanData = res;
  //     console.log("Master Plan Data: ",this.harvestingPlanData.page['harvestingPlanId'],",",this.harvestingPlanData['masterPlanCode']);
  //     console.log("Master Plan Data: ",this.harvestingPlanData);

  //     this.infoStatus = true;
      
  //       this.getHarvestingPlans();
  //   });
  // }

  getHarvestingPlans(){
    let params = {};
    if(this.currentPage) params['page'] = this.currentPage-1;
    if(this.pageSize) params['pageSize'] = this.pageSize;
    if(this.sortColumn) params['column'] = this.sortColumn;
    if(this.sortOrder) params['sortOrder'] = this.sortOrder;

      // console.log("This is Harvesting Plan ID: ",this.harvestingPlanId);
      this.harvestingPlanService.getAllHarvestingPlansByHarvestingPlanID(this.harvestingPlanId, "filtered-data", this.filterData, params).subscribe(res=>{
      
      if(res && res['page']){
        this.pageCount = res['page']['totalPages'];
        let result = res['page']['content'];
        this.harvestingPlanItems = result;
      }
      if(res && res['tablePropertyMap']){
        let columns = this.settings.columns;
        let tablePropertyMap:Map<string, {}> = res['tablePropertyMap'];

        for(let i=0; i<columns.length; i++){
          let data = tablePropertyMap[columns[i].attribute];
          if(!data) continue;
          columns[i].filter['column'] = data['column'];
          columns[i].filter['table'] = data['table'];
        }
      }
      this.settings['isNative'] = false;
    });
  }

  editHarvestingPlanItems(data){
    const initialState = {
      param:data
    };
    let modalRef = this.modalService.show(HarvestingPlanItemEditComponent, {class:'modal-lg', initialState,keyboard:false,ignoreBackdropClick: true});
    modalRef.content.event.subscribe(res => {
      this.getHarvestingPlans();
    });
  }
 

  filterOrSortChange(event){
    if(!event){
      this.currentPage = 1;
      this.harvestingPlanItems = [];
      return;
    }
    if(!event['sortChanged']) this.currentPage = 1;
    this.sortColumn = event['sortColumn'];
    this.sortOrder = event['sortOrder'];
    this.filterData = event['filterData'];

    this.getHarvestingPlans();
  }

  changePage(currentPage){
    this.currentPage = currentPage;
    this.getHarvestingPlans();
  }

  changePageSize(pageSize){
    this.pageSize = pageSize;
    this.getHarvestingPlans();
  }

}
