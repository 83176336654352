import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatureStockRoutingModule } from './mature-stock-routing.module';
import { TreeDetailsComponent } from './tree-details/tree-details.component';
import { MatureFieldsComponent } from './mature-fields/mature-fields.component';
import { MatureFieldsAddComponent } from './mature-fields/components/mature-fields-add/mature-fields-add.component';
import { MatureFieldsEditComponent } from './mature-fields/components/mature-fields-edit/mature-fields-edit.component';
import { TreeDetailsAddComponent } from './tree-details/components/tree-details-add/tree-details-add.component';
import { TreeDetailsEditComponent } from './tree-details/components/tree-details-edit/tree-details-edit.component';
import { TreeTableModule } from "../../shared/components/tree-table/tree-table.module";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorporateRoutingModule } from '../corporate/corporate-routing.module';
import { ReferenceDataRoutingModule } from '../reference-data/reference-data-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { MatureFieldsTreesComponent } from './mature-fields/components/mature-fields-trees/mature-fields-trees.component';
import { MatureFieldsTreesHarvestComponent } from './mature-fields/components/mature-fields-trees/components/mature-fields-trees-harvest/mature-fields-trees-harvest.component';


@NgModule({
    declarations: [
        TreeDetailsComponent,
        MatureFieldsComponent,
        MatureFieldsAddComponent,
        MatureFieldsEditComponent,
        TreeDetailsAddComponent,
        TreeDetailsEditComponent,
        MatureFieldsTreesComponent,
        MatureFieldsTreesHarvestComponent
    ],
    imports: [
        FontAwesomeModule,
        CommonModule,
        NgxPaginationModule,
        NgxSelectModule,
        NgbDatepicker,
        NgbDatepickerModule,
        MatureStockRoutingModule,
        SharedModule,
        ReferenceDataRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        CorporateRoutingModule,
        TreeTableModule
        
    ]
})
export class MatureStockModule { }
