import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Toast, ToastrService } from 'ngx-toastr';
import { EstateService } from 'src/app/services/estate.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { ReportService } from 'src/app/services/report.service';
import { UrlService } from 'src/app/services/url.service';
import { validateForm } from 'src/app/shared/helper';

@Component({
  selector: 'app-mt-reports',
  templateUrl: './mt-reports.component.html',
  styleUrls: ['./mt-reports.component.scss']
})
export class MtReportsComponent {
  
  refData:any
  param:any;
  data:any;
  estateCode:any;
  selectedReportType:number;
  estateList:any[]=[];
  divisionList:any[]=[];
  speciesList:any[]=[];
  mtFormTypeList:any[]=[
    {"id":1,"value":"Mature Timber Stock Verification Report"},
    {"id":2,"value":"Timber Stock Species Wise Report"},
    {"id":3,"value":"Summary of Mature Trees Report"}
  ];

  event: EventEmitter<any> = new EventEmitter();
  disableButton:Boolean = false;
  loading:Boolean=false;
  loadingBtn:Boolean=false;

  mtReportsForm = new UntypedFormGroup({
    selectFormType: new UntypedFormControl(''),
    estateCode: new UntypedFormControl(''),
    divisionCode: new UntypedFormControl(''),
    specieCode: new UntypedFormControl('')
  }
);

  constructor(
    public bsModalRef: BsModalRef,
    private notificationService: ToastrService,
    private refDataService: RefdataService,
    private urlService: UrlService,
    private reportService: ReportService
  ){

  }
  ngOnInit(): void{

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateList.push(estate.estateCode);
      });
    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      // console.log("Estates Completed");
    });

    this.refDataService.getSpecieCodeList().subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(item => {
        this.speciesList.push({
          id: item.speciesCode, 
          value: `${item.speciesCode} - ${item.description}`
        });
      });
    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      // console.log("Specie Codes Completed");
    });
  }

  initFormValues(){
    validateForm(this.mtReportsForm);
  }

  mtFormTypeChanged(){
    // console.log("Report Type",this.selectedReportType);
  }
  estateChanged(estateCode : any) {
    this.refDataService.getDivisionsByEstateCode(estateCode)
    .subscribe(
      (response) => {
      var divisions: any = response;
      this.divisionList = [{ id: 'All', value: 'All' }];
      divisions.forEach(division => {
        this.divisionList.push({
        id: division.divisionCode,
        value: division.divisionCode
        });
      });
      // console.log('Response:', divisions);
      },
      (error) => {
      // Handle error if needed
      console.error('Error:', error);
      }
    );
  }
  divisionChanged(){}

  specieChanged(){}

  downloadMTReport(){
    const divisionCode = this.mtReportsForm.get('divisionCode').value;

    const requestData = {
      mtsReport: this.selectedReportType === 1,
      smtReport: this.selectedReportType === 3,
      tswReport: this.selectedReportType === 2,
      estateCode: this.mtReportsForm.get('estateCode').value,
      specieCode: this.mtReportsForm.get('specieCode').value,
      divisionCode: this.mtReportsForm.get('divisionCode').value
    };
    // console.log('Request Data:', requestData);
    

    this.reportService.getMatureTimberStockbyEstateReport(requestData).subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const file = document.createElement('a');
      file.href = url;
      var estateCode = requestData.estateCode;
      var specieCode = requestData.specieCode;
      
      file.download = 'MatureTimberStockReport.pdf';
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
      window.URL.revokeObjectURL(url);
    });
  }

}
