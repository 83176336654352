<div class="container">
    <div class="row">
      <div class="pageTitle col-sm-6">
        <span> Reports </span> 
      </div>
    </div>
  
      <div class="col-sm-6">
        <table class="table table-borderless" style="margin-left: 10px; font-size: medium;">
          <thead>
            <tr>
              <th>Mature Reports</th>
            </tr>
          </thead>
          <tbody>
            <tr (click)="mtReports()"><td>Mature Stock Reports</td></tr>
          </tbody>
        </table>
      </div>
   
  </div>
  <!-- wrapper -->