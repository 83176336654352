import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HarvestingPlanService {

  constructor(private http: HttpClient) { }

    getAllHarvestingPlan(apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan/" +"kvpl/"+ apiType, data, {params: params});
    }

    create(data)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan/", data);
    }

    harvestingPlanDetailsUpdate(harvestingPlanID,data)
    {
      return this.http.put(environment.apiUrl + "/harvesting-plan/" + harvestingPlanID, data);
    }

    delete(harvestingPlanID)
    {
        if (!harvestingPlanID) return;
        return this.http.delete(environment.apiUrl + "/harvesting-plan/" + harvestingPlanID);
    }
    
    //---------------------------------------------------------------------------------------------------------------------------------------------------------


    getAllHarvestingPlansByHarvestingPlanID(harvestingPlanID, apiType = 'filtered-data', data = [], params = null)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan/hpitems/" + harvestingPlanID + "/detail-list/" + apiType, data, {params: params});
    }

    addToHarvestingPlan(data)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan/hpitems/" , data);
    }

    addMatureTreesToHarvestingPlan(companyCode,harvestingPlanID,data)
    {
        return this.http.post(environment.apiUrl + "/harvesting-plan/hpitems/" + companyCode + "/items/" + harvestingPlanID + "/" , data);
    }

    harvestingPlanItemsUpdate(hpItemId,data)
    {
      return this.http.put(environment.apiUrl + "/harvesting-plan/hpitems/" + hpItemId, data);
    }

    //---------------------------------------------------------------------------------------------------------------------------------------------------------

    addImmatureFiledsToHarvestingPlan(companyCode,harvestingPlanID,harvestingType,data)
    {
      return this.http.post(environment.apiUrl + "/harvesting-plan/hpitems/" + companyCode + "/im-items/" + harvestingPlanID + "/" + harvestingType + "/",data);
    }

      getHarvestedTrees(estateCode,divisionCode,fieldCode,harvestingType)
    {
      return this.http.get(environment.apiUrl + "/harvesting-plan/hpitems/" + estateCode + "/" + divisionCode + "/" + fieldCode + "/" + harvestingType + "/");
    }

    getHarvestedTreesByHarvestingType(harvestingType)
    {
      return this.http.get(environment.apiUrl + "/harvesting-plan/hpitems/" + harvestingType + "/");
    }

}