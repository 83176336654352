import { Component, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FieldService } from 'src/app/services/field.service';
import { RefdataService } from 'src/app/services/refdata.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-field-add',
  templateUrl: './field-add.component.html',
  styleUrls: ['./field-add.component.scss']
})
export class FieldAddComponent {

  model1: any;
  refData : any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  companyCodeList: any[]=[];
  estateCodeList: any[]=[];
  maturityStatusList: any[]=[];
  fieldMainCropList: any[]=[];
  fieldTypeList: any[]=[];
  divisionCodeList: any[]=[];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  fieldAddForm = new UntypedFormGroup({
    companyCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    estateCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    divisionCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    fieldCode: new UntypedFormControl('',[Validators.required, Validators.maxLength(10)]),
    fieldExtent: new UntypedFormControl('',[Validators.maxLength(10)]),
    maturityStatus: new UntypedFormControl('',[Validators.maxLength(10)]),
    fieldType: new UntypedFormControl('',[Validators.maxLength(10)]),
    fieldMainCrop: new UntypedFormControl('',[Validators.maxLength(10)]),
    gpsCoordinates: new UntypedFormControl('',[Validators.maxLength(1000)]),
    gpsCoordinatesLong: new UntypedFormControl('',[Validators.maxLength(15)]),
    gpsCoordinatesLati: new UntypedFormControl('',[Validators.maxLength(15)])
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private fieldService: FieldService,
      private refDataService: RefdataService,
      private urlService: UrlService
  ) { 
    

  }

companyCodeChanged(event){
}

estateCodeChanged(estateCode: any) {
  // console.log('Estate Code changed:', estateCode);

  this.refDataService.getDivisionsByEstateCode(estateCode)
      .subscribe(
        (response) => {
          var divisions: any = response;
          this.divisionCodeList = [];
          divisions.forEach(division => {
            this.divisionCodeList.push(division.divisionCode);
          });
          // console.log('Response:', divisions);
        },
        (error) => {
          // Handle error if needed
          console.error('Error:', error);
        }
      );
}

fieldMainCropChanged($event) {
}

fieldTypeChanged(pureCrop) {
  // console.log("Crop Type:",pureCrop);
  if (pureCrop == 1) { // Replace 'pureCropId' with the actual value of the pure crop ID
    this.fieldAddForm.controls['fieldMainCrop'].setValue(6); // Set maturity status to ID 5
    this.fieldAddForm.controls["fieldMainCrop"].disable();

  } else {
    // Optionally reset the maturity status if needed
    this.fieldAddForm.controls['fieldMainCrop'].reset();
    this.fieldAddForm.controls['fieldMainCrop'].enable();
    console.log("Maturity Status reset");
  }
}

maturityStatusChanged($event) {
}

divisionCodeChanged($event) {
}


  onChange(){
    // console.log(this.fieldAddForm.getRawValue());
  }

  ngOnInit(): void {
    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Estates Completed");
    });

    this.refDataService.getFieldRefData().subscribe((data)=>{
      this.refData = data;
      // console.log(this.refData);

      this.maturityStatusList = this.refData.matStatus;
      this.fieldTypeList = this.refData.fdType;

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    this.refDataService.getCropTypes().subscribe((data)=>{
      this.refData = data;
      // console.log(this.refData);

      this.refData.forEach(item => {
        this.fieldMainCropList.push({
          id: item.typeNumber, 
          value: item.cropType 
        });
      });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Crop Types Completed");
    });

    let hostName = this.urlService.getHostName(window.location);
      // console.log(hostName);
    this.companyCodeList.push(hostName);
    this.fieldAddForm.controls["companyCode"].patchValue(hostName);
    this.fieldAddForm.controls["companyCode"].disable();

    this.fieldAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }


  createNewField(){
    let formData = this.fieldAddForm.getRawValue();
    this.loadingBtn = true;
    this.fieldService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Field has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Field has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Field already exist', 'Error');
      }
      else {
        this.notification.error('Field has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }


}
