import { formatDate } from '@angular/common';
import { RefdataService } from './../../../../../services/refdata.service';
import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/services/company.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UrlService } from 'src/app/services/url.service';


@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent {

  model1: any;
  roleData:any[] = [];
  userGroupData:any[] = [];
  refData: any;
  parentCompanyList:any[] = [];
  estateCodeList: any[]=[];
  countryList:any[] = [];

  event: EventEmitter<any> = new EventEmitter();
  ismatch:Boolean = true;
  loadingBtn : Boolean = false;
  disableButton:Boolean = false;
  companyAddForm = new UntypedFormGroup({
    parentCompanyCode: new UntypedFormControl('',[ Validators.required, Validators.maxLength(15)]),
    companyCode: new UntypedFormControl('',[ Validators.required, Validators.maxLength(15)]),
    companyName: new UntypedFormControl('',[ Validators.maxLength(50)]),
    address: new UntypedFormControl('',[ Validators.maxLength(100)]),
    city: new UntypedFormControl('',[ Validators.maxLength(100)]),
    country: new UntypedFormControl('',[ Validators.maxLength(100)]),
    telephoneNumber: new UntypedFormControl('',[ Validators.maxLength(50)]),
    faxNumber: new UntypedFormControl('',[ Validators.maxLength(50)]),
    emailAddress: new UntypedFormControl('',[ Validators.maxLength(50), Validators.email]),
    webAddress: new UntypedFormControl('',[ Validators.max(50)]),
    dateOfNationalization: new UntypedFormControl(''),
    dateOfIncorporation: new UntypedFormControl(''),
    businessRegistrationNumber: new UntypedFormControl('',[Validators.maxLength(50)]),
  });
  
  constructor(
      public bsModalRef : BsModalRef,
      private notification : ToastrService,
      private companyService: CompanyService,
      private urlService : UrlService,
      private refDataService: RefdataService
  ) { 
    

  }

  ngOnInit(): void {

    var companyCode = this.urlService.getHostName(window.location);
    this.refDataService.getEstateList(companyCode).subscribe((data)=>{
      this.refData = data;
      this.refData.forEach(estate => {
        this.estateCodeList.push(estate.estateCode);
    });

    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    this.refDataService.getCompanyRefData(companyCode).subscribe((data)=>{
      this.refData = data;
      this.countryList = this.refData.countries;
      this.companyAddForm.controls["country"].patchValue(this.refData.countries[0].id);
      this.companyAddForm.controls["country"].disable();
      // console.log(this.refData.countries[0].id);

      this.parentCompanyList.push(this.refData.parentCompany);
      this.companyAddForm.controls["parentCompanyCode"].patchValue(this.refData.parentCompany.parentCompanyCode);
      this.companyAddForm.controls["parentCompanyCode"].disable();
    },error=>{
      console.log("Error Occurred");
      console.log(error)
    },()=>{
      console.log("Completed");
    });

    this.companyAddForm.patchValue({
      userEmail : null,
      firstName : null,
      lastName : null,
      password : null,
      confirm : null,
      active : 0
    });
  }

  parentCompanyCodeChanged(event){
  }

  countryChanged(event){
  }

  myformatDate(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;
    return `${year}-${month}-${day}`;
  }


  createNewCompany(){
    let formData = this.companyAddForm.getRawValue();
    console.log(this.companyAddForm.getRawValue());

    formData.dateOfIncorporation = this.myformatDate( this.companyAddForm.controls["dateOfIncorporation"].value);
    formData.dateOfNationalization = this.myformatDate( this.companyAddForm.controls["dateOfNationalization"].value);

    this.loadingBtn = true;
    this.companyService.create(formData).subscribe(res=>{
      if(res){
        this.notification.success('Company has been Created','Success');
        this.bsModalRef.hide();
        this.event.emit()
      }else{
        this.notification.error('Company has not been created','Error');
      }
      this.loadingBtn = false;
    },error=>{
      if(error.status==412){
        this.notification.error('This Company already exist', 'Error');
      }
      else {
        this.notification.error('Company has NOT been created','Error');
      }
      this.loadingBtn = false;
    });
  }

}
